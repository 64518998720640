<div class="dialog-container">
    <label class="addInductionLabel" id="addInductionLabel">
        {{ ((!isEditMode ? 'site_induction_add_header' : 'site_induction_edit_header') | translate) }}
    </label>
    <div class="divider"></div>
    <form *ngIf="!isEditMode" [formGroup]="inductionFormGroup" >       
        <div>            
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label class="color-city-blue">{{'site_induction_add_entercompanyname_field' | translate }}</mat-label>
                <mat-select formControlName="brandName" (selectionChange)="selectedBrand($event.value)">
                    <mat-option *ngFor="let brand of brands" [value]="brand">
                        {{brand.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="hasError(inductionFormGroup, 'brandName')">{{getError(inductionFormGroup, 'brandName')}}</mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label class="color-city-blue">{{'site_induction_add_enterinductionid_field' | translate }}</mat-label>
                <input matInput placeholder="{{'site_induction_add_enterinductionid_field' | translate }}" 
                    formControlName="inductionId" required id="input_inductionid" >
                <mat-error *ngIf="hasError(inductionFormGroup, 'inductionId')">{{getError(inductionFormGroup, 'inductionId')}}</mat-error>
            </mat-form-field>
        </div>
    </form>
    <form *ngIf="isEditMode" [formGroup]="inductionFormGroup" >       
        <div>
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label class="color-city-blue">{{'site_induction_edit_label_company' | translate }}</mat-label>
                <input matInput formControlName="brandName" id="input_brandName">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label class="color-city-blue">{{'site_induction_edit_label_inductionId' | translate }}</mat-label>
                <input matInput placeholder="{{'register_addinduction_enterinductionid_field' | translate }}" 
                    formControlName="inductionId" required id="input_inductionid" >
                <mat-error *ngIf="hasError(inductionFormGroup, 'inductionId')">{{getError(inductionFormGroup, 'inductionId')}}</mat-error>
            </mat-form-field>
        </div>
    </form>

    <div class="divider"></div>
    <div class="divider"></div>

    <button type="submit" id="register2_continue" mat-raised-button (click)="isEditMode ? editInduction() : addInduction()" [disabled]="disableSubmitButton()">
        {{'site_induction_add_submit' | translate }}
    </button>

    <div class="divider"></div>
    <button mat-stroked-button class="color-city-blue btn-border-color" (click)="dismissDialog()">
        {{ 'site_induction_add_cancel' | translate }}
    </button>   
</div>

