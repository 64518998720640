<div class="main-content" #topPosition>
    <div fxLayout="column">
        <div id="openPermitTitle" fxLayout="row">
            <div fxFlex="20">
                <div class="logo">
                    <img src="/assets/CityFM Logo.png">
                </div>
            </div>
            <div fxFlex="80" fxLayoutAlign="end center">
                <div data-testid="open_permit_title">
                    <span class="font-size-h1 color-city-blue">{{'open_permit_permits_lbl' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <mat-divider></mat-divider>
        <div class="divider"></div>
        <mat-label>{{'open_permit_lbl' | translate}}</mat-label>
        <div class="divider"></div>
        <mat-label class="label">{{ 'open_permit_part_A_work_details' | translate }}</mat-label>
        <div id="openPermitHeader" fxLayout="row">
            <div fxFlex="50">
                <div data-testid="permit_name">
                    <span class="header-lbl-font">{{'permit_name_lbl' | translate}}</span>
                    <span class="header-lbl-text-font"> {{currentPermit?.userFullName}}</span>
                </div>
            </div>
            <div fxFlex="50" class="header-marginleft" fxLayoutAlign="end top">
                <div data-testid="permit_number">
                    <span class="header-lbl-font">{{'permit_number_lbl' | translate}}</span>
                    <span class="header-lbl-text-font"> {{currentPermit?.permitNumber}}</span>
                </div>
            </div>
        </div>
        <div id="openPermitHeader_detail1" fxLayout="row">
            <div fxFlex="50">
                <div data-testid="permit_location">
                    <span class="header-lbl-font">{{'permit_location_lbl' | translate}}</span>
                    <span class="header-lbl-text-font"> {{ locationOfWork }}</span>
                </div>
            </div>
            <div fxFlex="50" class="header-marginleft" fxLayoutAlign="end top">
                <div data-testid="permit_company" class="right-aligned">
                    <span class="header-lbl-font">{{'permit_company_lbl' | translate}}</span>
                    <span class="header-lbl-text-font"> {{currentPermit?.userCompany}}</span>
                </div>
            </div>
        </div>
        <div id="openPermitHeader_detail2" fxLayout="row">
            <div data-testid="permit_date">
                <span class="header-lbl-font">{{'permit_date_lbl' | translate}}</span>
                <span class="header-lbl-text-font"> {{currentPermit?.createdAt | date:'dd-MM-yyyy'}}</span>
            </div>
        </div>
        <div id="openPermitHeader_detail3" fxLayout="row">
            <div data-testid="permit_time">
                <span class="header-lbl-font">{{'permit_time_lbl' | translate}}</span>
                <span class="header-lbl-text-font"> {{currentPermit?.createdAt | date:'hh:mm a'}}</span>
            </div>
        </div>

        <!-- <div id="openPermitHeader_detail4" fxLayout="row">
            <div data-testid="permit_location">
                <span class="header-lbl-font">{{'permit_location_lbl' | translate}}</span>
                <span class="header-lbl-text-font"> {{ locationOfWork }}</span>
            </div>
        </div> -->
        <div class="divider"></div>
        <mat-divider></mat-divider>
        <div class="divider"></div>
        <div>
            <form [formGroup]="permitInfoFormGroup" class="prmit-form">
                <mat-form-field appearance="fill" class="input">
                    <mat-label class="color-city-neutral">{{ ('permit_work_order_number_lbl' | translate) }}</mat-label>
                    <input matInput maxlength="250" placeholder="{{'permit_work_order_number_field' | translate }}"
                        formControlName="workOrderNumber" id="input_permit_workorder_number">
                    <mat-hint *ngIf="permitInfoFormGroup.get('workOrderNumber')?.value?.length >= 250"
                        class="color-city-red">
                        {{ 'permit_maximum_characters_error' | translate }}
                    </mat-hint>
                    <mat-error
                        *ngIf="isSubmitPermit && hasError(permitInfoFormGroup, 'workOrderNumber')">{{getError(permitInfoFormGroup,
                        'workOrderNumber')}}</mat-error>
                </mat-form-field>
                <mat-divider></mat-divider>
                <mat-form-field appearance="fill" class="input">
                    <mat-label class="color-city-neutral">{{ ('permit_descriptionOfWork_lbl' | translate)
                        }}</mat-label>
                    <textarea matInput placeholder="{{'permit_descriptionOfWork_field' | translate }}"
                        formControlName="descriptionOfWork" id="input_permit_descriptionOfWork"></textarea>
                    <mat-error
                        *ngIf="isSubmitPermit && hasError(permitInfoFormGroup, 'descriptionOfWork')">{{getError(permitInfoFormGroup,
                        'descriptionOfWork')}}</mat-error>
                </mat-form-field>
                <mat-divider></mat-divider>
                <mat-form-field appearance="fill" class="input">
                    <mat-label class="color-city-neutral">{{ ('permit_additional_work_details_lbl' | translate)
                        }}</mat-label>
                    <input matInput maxlength="500"
                        placeholder="{{'permit_additional_work_details_field' | translate }}"
                        formControlName="additionalPeopleWork" id="input_permit_additional_work_details">
                    <mat-hint *ngIf="permitInfoFormGroup.get('additionalPeopleWork')?.value?.length >= 500"
                        class="color-city-red">
                        {{ 'permit_maximum_characters_error' | translate }}
                    </mat-hint>
                    <mat-error
                        *ngIf="isSubmitPermit && hasError(permitInfoFormGroup, 'additionalPeopleWork')">{{getError(permitInfoFormGroup,
                        'additionalPeopleWork')}}</mat-error>
                </mat-form-field>
                <div fxLayout="column">
                    <div fxLayout="row">
                        <mat-form-field appearance="fill" class="input" fxFlex="50" style="padding-right: 3px;">
                            <mat-label class="color-city-neutral">{{ ('permit_start_time_lbl' | translate)
                                }}</mat-label>
                            <input matInput type="time" placeholder="{{'permit_start_time_field' | translate }}"
                                formControlName="estimateDurationFrom" id="input_permit_start_time">
                            <mat-error
                                *ngIf="isSubmitPermit && hasError(permitInfoFormGroup, 'estimateDurationFrom')">{{getError(permitInfoFormGroup,
                                'estimateDurationFrom')}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input" fxFlex="50" style="padding-left: 3px;">
                            <mat-label class="color-city-neutral">{{ ('permit_end_time_lbl' | translate) }}</mat-label>
                            <input matInput type="time" placeholder="{{'permit_end_time_field' | translate }}"
                                formControlName="estimateDurationTo" id="input_permit_end_time">
                            <mat-error
                                *ngIf="isSubmitPermit && hasError(permitInfoFormGroup, 'estimateDurationTo')">{{getError(permitInfoFormGroup,
                                'estimateDurationTo')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-error *ngIf="timeRangeError">
                        {{ ('permit_time_range_error' | translate) }}
                    </mat-error>
                </div>

            </form>
        </div>
        <div class="divider"></div>
        <mat-divider></mat-divider>
        <div class="divider"></div>
        <div id="openPermitContent">
            <form novalidate name="permit.qnaForm">
                <div class="form-group" *ngFor="let question of openPermitQnAs">
                    <ng-form name="templateFieldForm">
                        <app-permit-question-render [question]="question"
                            [hasContentError]="question.HasContentError"></app-permit-question-render>
                    </ng-form>
                </div>
            </form>
        </div>
        <div class="divider"></div>
        <div fxLayout="row" fxLayoutGap="0.5em">
            <button mat-raised-button (click)="cancelOnClick()" id="btn_permitcancel" data-testid="permit_btn_cancel"
                style="background-color: transparent; color: var(--ciy-blue);">
                {{ ('open_permit_cancel_btn' | translate) }}
            </button>
            <button mat-raised-button (click)="saveAsDraftOnClick()" id="btn_permitsave" data-testid="permit_btn_save"
                style="background-color: transparent; color: var(--ciy-blue);">
                {{ ('open_permit_saveasdraft_btn' | translate) }}
            </button>
            <button mat-raised-button (click)="submitOnClick()" id="btn_permitsubmit" data-testid="permit_btn_submit"
                [disabled]="disableSubmitButton()">
                {{ ('open_permit_submit_btn' | translate) }}
            </button>
        </div>
    </div>
</div>