<mat-toolbar class="bg-white mat-toolbar-override">
    <button mat-icon-button (click)="back()" id="btn_sitesignoutback">
        <mat-icon color="primary">
            keyboard_backspace
        </mat-icon>
    </button>
</mat-toolbar> 

<form [formGroup]="siteSignOutFormGroup">

    <div fxLayout="row" class="row-divider">
        <div fxLayout="column">
            <span class="font-size-h2 color-dark-blue ">{{ ('sitesignout_lbl_signout' | translate)}}</span>
        </div>
    </div>

    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-h2 color-dark-blue ">{{ ('sitesignout_lbl_visit_summary' | translate)}}</span>
        </div>
    </div>

    <div *ngIf="getInductionRequired" fxLayout="row" style="width:100%">
        <app-site-sign-out-visit-summary style="width:100%"></app-site-sign-out-visit-summary>
    </div>

    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-h2 color-dark-blue"> {{ ('sitesignout_lbl_confirmation' | translate) }}</span>
        </div>
    </div>
    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-body-text color-dark-blue">{{ ('sitesignout_lbl_before_you_go_declare' | translate) }}</span>
        </div>
    </div>
    <br>
    <div fxLayout="row">
        <div fxFlex="100">
            <div fxLayout="column">

                <div fxLayout="row">
                    <div fxLayout="column">
                        <span class="font-size-small color-dark-blue">{{('sitesignout_lbl_initial' | translate)}}</span>
                    </div>

                </div>

                <div class="signature" #signatureWrapper>
                   <signature-pad #signature [options]="signaturePadOptions" (window:resize)="resizeSignaturePad()" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)" ></signature-pad>
                </div>
    
                <div class="divider"></div>
                <div class="divider"></div>
                <div>
                    <button type="submit" id="btn_sitesignout" mat-raised-button (click)="submitSiteSignOut()" [disabled]="disableSubmitButton()">
                        {{'sitesignout_signout' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="divider"></div>
</form>
