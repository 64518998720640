import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent {
  constructor(private _activatedRoute: ActivatedRoute, private _router: Router) {
    this._activatedRoute.params.subscribe(params => {
      const path = params['path'];
      if (path) {
        this._router.navigate([path]);
      } else {
        if (this._router.url.includes('#')) {
          const fragment = this._router.url.split('#')[1]; // Get the part after #
          const newUrl = '/#'.concat(fragment);
          this._router.navigateByUrl(newUrl);
        } else {
          this._router.navigate(['']);
        }
      }
    });
  }
}
