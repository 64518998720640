import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Site } from '../../models/site.model';
import { StorageKey } from 'src/app/shared/enums';
import { BaseService } from '../base/base.service';
import { Brand } from 'src/app/models/brand.model';

@Injectable({ providedIn: 'root' })
export class SiteApiService extends BaseService {
    readonly KEY = StorageKey[StorageKey.SITE];
    readonly BASE_URL = `${environment.gatewayAPI_V1}/Sites/`;
    constructor(
        private httpClient: HttpClient) { 
        super();
    }

    public getNearbySites(lat: number, long: number): Observable<Site[] | null> {
        const url = `${this.BASE_URL}Sites/GetNearbySites?latitude=${lat}&longitude=${long}`;
        //const url = `https://localhost:7062/v1/gateway/Sites/GetNearbySites?latitude=${lat}&longitude=${long}`;
        return this.httpClient.get<Site[]>(url).pipe(
        tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getNearbySiteBySiteId(lat: number, long: number, siteId: string): Observable<Site | null> {
        const url = `${this.BASE_URL}Sites/GetNearbySiteBySiteId?latitude=${lat}&longitude=${long}&siteId=${siteId}`;
        return this.httpClient.get<Site>(url).pipe(
        tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getNearbySiteBySiteCode(siteCode : string, lat: number, long: number): Observable<Site | null> {
        const url = `${this.BASE_URL}Sites/GetNearbySiteBySiteCode?siteCode=${siteCode}&latitude=${lat}&longitude=${long}`;
        return this.httpClient.get<Site>(url).pipe(
        tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getSiteBySiteId(siteId: string): Observable<Site | null> {
        const url = `${this.BASE_URL}Sites/GetSiteBySiteId?siteId=${siteId}`;

        return this.httpClient.get<Site>(url).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getPartners(): Observable<Brand[] | null> {
        const url = `${this.BASE_URL}Brands/GetPartners`;        
        return this.httpClient.get<Brand[]>(url).pipe(
        tap(_ => _),
            catchError(this.handleError)
        );
    }   

    public postOrderSiteLocationWithLatAndLong(siteIds: string[], lat: number, long: number): Observable<Site[]> {
        const url = `${this.BASE_URL}Sites/postorderSiteLocationWithLatAndLong?latitude=${lat}&longitude=${long}`;
     
        return this.httpClient.post<Site[]>(url, siteIds).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getSitesAdmin(brandId?: string, includeInactiveSites?: boolean, includeInactiveBrands?: boolean): Observable<Site[] | null> {
        let url = `${this.BASE_URL}Sites/GetSitesAdmin`;

        if(brandId != undefined || includeInactiveSites != undefined || includeInactiveBrands != undefined) {
            url = url.concat(`?`);

            if(brandId != undefined){
                url = url.concat(`&brandId=${brandId}`);
            }
            if(includeInactiveSites != undefined){
                url = url.concat(`&includeInactiveSites=${includeInactiveSites}`);
            }
            if(includeInactiveBrands != undefined){
                url = url.concat(`&includeInactiveBrands=${includeInactiveBrands}`);
            }
        }

        return this.httpClient.get<Site[]>(url).pipe(
            tap(_ => _),
                catchError(this.handleError)
            );
    }

    public getPartnersAdmin(includeInactive?: boolean): Observable<Brand[] | null> {
        let url = `${this.BASE_URL}Brands/GetPartnersAdmin`;

        if(includeInactive != undefined) {
            url = url.concat(`?includeInactive=${includeInactive}`);
        }
        
        return this.httpClient.get<Brand[]>(url).pipe(
        tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getSiteBySiteIdAdmin(siteId: string): Observable<Site | null> {
        const url = `${this.BASE_URL}Sites/GetSiteBySiteIdAdmin?siteId=${siteId}`;

        return this.httpClient.get<Site>(url).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }
}