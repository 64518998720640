import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UpdatedUserCompanies, User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserManagementAddEditComponent } from '../user-management-add-edit/user-management-add-edit.component';
import { environment } from 'src/environments/environment';
import { LocalizationService } from '../internationalization/localization.service';
import { ClosedownService } from 'src/app/services/closedown/closedown.service';
import { ConfigurationService } from '@app/services/configuration/configuration.service';
import { MatTableDataSource } from '@angular/material/table';

export interface ExtendedUser {
  user: User,
  invitationStatus: any,
  resendInvitation: any,
  edit: any
}

@Component({
  selector: 'app-user-management-list',
  templateUrl: './user-management-list.component.html',
  styleUrls: ['./user-management-list.component.scss'],
  animations: [
    trigger('detailUserExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class UserManagementListComponent implements OnInit {

  adminUser: User | undefined;
  users: User[] = [];
  filteredUsers: MatTableDataSource<ExtendedUser> = new MatTableDataSource<ExtendedUser>([]);
  displayedUserColumns: string[] = ['Email', 'RegisterStatus', 'InvitationStatus', 'ResendInvitation', 'Edit'];
  searchControl: UntypedFormControl = new UntypedFormControl(null);
  debounce: number = 1000;
  inductionModuleEnable: boolean = false;
  resendInviteToolTip: string = "";
  editToolTip: string = "";
  public resourceId: string | undefined;

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    public _router: Router,
    private _addDialog: MatDialog,
    private _navigationService: NavigationService,
    private _localizationService: LocalizationService,
    private _closedownService: ClosedownService,
    private _configurationService: ConfigurationService,
    private _activatedRoute: ActivatedRoute
  ) {
    this._authService.getTokenFromStorage().then(bearer => {
        _closedownService.setBearerToken(bearer);
    });
    this.resendInviteToolTip = this._localizationService.translate("user_management_list_resend_invite_tooltip");
    this.editToolTip = this._localizationService.translate("user_management_list_edit_tooltip");
    this.resourceId = this._activatedRoute.snapshot.paramMap.get('resourceId')?.toLocaleLowerCase(); 
    if (this.resourceId != null)
      this._navigationService.onChange(false, false);
    else this._navigationService.onChange(true, false);  
  }

  async ngOnInit(): Promise<void> {
    this.inductionModuleEnable = await this._configurationService.getInductionModuleEnable();

    this.loadUsers();
    this.searchControl.valueChanges
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(query => {
        if (query) {
          if (query.length > 2) {
            let queryLower = query.toLowerCase();
            this.filteredUsers.data = this.users.filter(x =>
              x.emailAddress.toLowerCase().includes(queryLower) ||
              x.companies
              .some(y => y.name.toLowerCase().includes(queryLower)))
              .map(u => { return { 
                user: u,
                invitationStatus: u.inviteHistories?.[0].createdAt
              } as ExtendedUser})
          }
        } else {
          this.filteredUsers.data = this.users
          .map(u => { return { 
            user: u,
            invitationStatus: u.inviteHistories?.[0].createdAt
          } as ExtendedUser})
        }
      });
  }

  openSearch() {
    this.searchControl.setValue('');
  };

  closeSearch() {
    this.searchControl.setValue(null);
  };

  showSearchButton() {
    return this.searchControl.value === null;
  };

  showSearchInput() {
    return this.searchControl.value !== null;
  };

  loadUsers(): void {
    this._authService.getUser()
    .then(user => {
      if (user) {
        this._userService.getInternalTechnicians(user.id).subscribe({
            next: (response => {
              if (response) {
                this.adminUser = user;
                this._closedownService.setCurrentUser(user);
                this.users = response;
                this.filteredUsers.data = response
                .map(u => { return { 
                  user: u,
                  invitationStatus: u.inviteHistories != null ? u.inviteHistories[0].createdAt : null
                } as ExtendedUser})
              }
            })
          });
        }
      },
      _ => console.log("Failed to get users")
    );
  }

  addUser() {
    let addUserDialog: MatDialogRef<UserManagementAddEditComponent>;
    const dialogConfig = {
      data: { adminUser: this.adminUser, user: {} as User, isEditMode: false },
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass: 'details-container'
    };
    addUserDialog = this._addDialog.open(UserManagementAddEditComponent, dialogConfig);
    addUserDialog.addPanelClass('details-container');
    addUserDialog.afterClosed().subscribe(_ => this.loadUsers());
  }

  editUser(user: User | undefined) {
    let editUserDialog: MatDialogRef<UserManagementAddEditComponent>;
    const dialogConfig = {
      data: { adminUser: this.adminUser, user: user, isEditMode: true },
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass: 'details-container'
    };
    editUserDialog = this._addDialog.open(UserManagementAddEditComponent, dialogConfig);
    editUserDialog.addPanelClass('details-container');
    editUserDialog.afterClosed().subscribe((updatedUser) => {
      if (updatedUser) {
        this.loadUsers();
      }
    });
  }

  closeUserManagement(): void {
    if (this.resourceId != null)
      this._closedownService.directToClosedown(true, this.resourceId);
    else 
      this._router.navigate(['signin']);
  }

  resendInvite(user: User | undefined) {
    if (user) {
      if (confirm(this._localizationService.translate('user_management_list_resend_invite')
          .replace('{user_email}', user.emailAddress))) {
        let updatedUserCompanies = {
          SelectedCompanies: [],
          UnselectedCompanies: []
        } as UpdatedUserCompanies;
        if (user.companies) {
          user.companies.forEach(
            company => {
              updatedUserCompanies.SelectedCompanies.push(company);
            }
          )
          if (updatedUserCompanies.SelectedCompanies.length > 0) {
            this._userService.updateCompanies(user.id, user.emailAddress, this.adminUser!.emailAddress, updatedUserCompanies)
            .subscribe({
              next: (_ => {
                alert(this._localizationService.translate("user_management_list_resend_invite_success")
                .replace('{user_email}', user.emailAddress));
                this.loadUsers();
              }),
              error: (_ => alert(this._localizationService.translate("user_management_list_resend_invite_error")
                .replace('{user_email}', user.emailAddress)))
            })            
          }
        }
      }
    }
  }
}