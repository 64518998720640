import { Component, OnInit } from '@angular/core';
import { Location} from '@angular/common';
import { Router } from '@angular/router';
import { Site } from 'src/app/models/site.model';
import { Geolocation } from '@capacitor/geolocation';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { LocalizationService } from '../internationalization/localization.service';
import { UtilsService } from 'src/app/services/util/util-service';
import { SiteService } from 'src/app/services/site/site.service';
import { SiteApiService } from 'src/app/services/site/site.api.service';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { FormService } from 'src/app/services/form/form.service';
import { JobService } from 'src/app/services/job/job.service';
import { NotificationPushService } from 'src/app/services/notification/notification.push.service';
import { ClosedownService } from '@app/services/closedown/closedown.service';
import { Browser } from '@capacitor/browser';
import { GeolocationService } from '@app/services/geolocation/geolocation.service';
import { Job } from '@app/models/job.model';
import { error } from 'console';
import { environment } from '@env/environment';
import { ConfigurationService } from '@app/services/configuration/configuration.service';

@Component({
  selector: 'app-site-sign-in',
  templateUrl: './site-sign-in.component.html',
  styleUrls: ['./site-sign-in.component.scss']
})
export class SiteSignInComponent implements OnInit {
  id = "";
  sites : Site[] | null = [];
  userLat : any = null;
  userLong : any = null;
  currentUser: User | undefined;
  withJob = false;
  withLink = false;
  SUB_CONTRACTOR : string = 'SUBCON';
  locationMessage: string = '';
  jobs: Job[] = [];
  isSiteListJobCountEnable = false;
  
  constructor(private _authService:AuthService,
    private _location: Location, 
    public _router: Router,
    private _siteService: SiteService,
    private _siteApiService: SiteApiService,
    private _loader: LoaderService,
    private _localizationService: LocalizationService,
    private _utilsService: UtilsService,
    private _formService: FormService,
    private _navigationService:NavigationService,
    private _jobService: JobService,
    private _closedownService : ClosedownService,
    private _notificationPushService: NotificationPushService,
    private _geolocationService: GeolocationService,
    private _configurationService: ConfigurationService) {
      this._navigationService.onChange(false, true);
  }

  async ngOnInit(): Promise<void> {
    this.isSiteListJobCountEnable = await this._configurationService.getIsSiteListJobCountEnable();
    this._authService.getUser().then(user => {
      this.currentUser = user;
      this.withJob = user.withJob;
    });
    await this.getGeoLocation();

  }

  private async getGeoLocation(){
    let latestLocation = this._geolocationService.getLatestLocation();
    if (latestLocation === null) {
      this._loader.show();
      latestLocation = await this._geolocationService.setLatestLocation();
      this._loader.hide();
    }

    if (latestLocation) {
      this.userLat = latestLocation.coords.latitude;
      this.userLong = latestLocation.coords.longitude;
      if(this.isSiteListJobCountEnable){
        this.getUserJobsforAllSites();
      }else{
        this.getNearbySite(this.userLat, this.userLong);
      }
    } else {
      this.locationMessage = this._localizationService.translate("sitesignin_device_location_turn_off");
    }
  }

  private getNearbySite(lat: number, long: number){
    this._siteApiService.getNearbySites(lat, long)
      .subscribe(sites => 
      {
        this.sites = sites;
      });
  }

  calculateDistance(lat: number, long: number) : string{
    var distant = "-";
    if(!this._utilsService.isUndefinedOrNull(lat) && 
      !this._utilsService.isUndefinedOrNull(long) &&
      !this._utilsService.isUndefinedOrNull(this.userLat) && 
      !this._utilsService.isUndefinedOrNull(this.userLong)){
        
        var distantInNumber = this._utilsService.getDistanceBetweenTwoPointsinKM(lat, long, this.userLat, this.userLong);
  
        if (distantInNumber < 1) {
          distant = `${Math.round(distantInNumber * 1000)} meter(s)`;
        } else {
          distant = `${distantInNumber.toFixed(2)} km(s)`;
        }
    }
    return this._localizationService.translate('sitesignin_lbl_distance')
    .replace('{distant}', distant);
  }

  back(){
    this._location.back();
  }

  siteSelectionOnclick(site : Site){
    this._siteService.setSignedInSite(site).then(_ => {
      if(site.isInductionRequired){
        this.getSiteInductions(site);
      }else{
        this.getUserJobs(site);
      }
    });
  }

  getUserJobsforAllSites(){
    this._jobService.getJobsByCompanyRefNumber(undefined).subscribe({
      next : jobs => {
        if (jobs && jobs.length > 0 && this.currentUser) {
            this.jobs = jobs;
        }
      },
      error: err => {},
      complete: () => {
        this.getNearbySite(this.userLat, this.userLong);
      }
    });
  }

  getUserJobs(site : Site){
    let userCompanies : string[] = [];
    if (this.currentUser){
      if (this.currentUser.companies){
        this.currentUser.companies.forEach(company => {
          if (company.companyType.toUpperCase() != this.SUB_CONTRACTOR){
            userCompanies.push(company.referenceNumber);
          }
        });
      }

      this._jobService.getJobsByCompanyRefNumber(site.id).subscribe({
        next: jobs => {
          this._closedownService.setUserJobs(jobs);
          if (site.isInductionRequired){
            if (jobs && jobs.length > 0){
              this._router.navigate(['induction'], {
                queryParams :{
                  inductionSequence : 1
              }});
            }else{
              this._router.navigate(['signedinvisitor']);
            }
          }
          else{
            if(jobs && jobs.length > 0){
              this._closedownService.getProcessFlow().then(processFlow =>{
                this._loader.show();
                processFlow.siteCode = site?.code;
                
                this._closedownService.setProcessFlow(processFlow).then(cs =>{
                  this._closedownService.directToClosedown(false, undefined, this._closedownService.getSignInBackUrl());
                  this.reloadWhenBrowserClose();
                }).catch(e =>{  this._loader.hide();});

              }).catch(e =>{  this._loader.hide();});
            }else{
              alert(this._localizationService.translate("sitesignin_nocity_jobs"));
            }
          }
         
        },
        error: e => {
          alert(this._localizationService.translate("job_dialog_failed"));
          this._siteService.removeSignedInSite();
          this._router.navigate(['signin']);
        }
      });
    }
  }

  getSiteInductions(site : Site){
    this._formService.getByBrandOrSiteId(site.brand.id, site.id).subscribe({
      next : (form) => {
        if (form) {
          this._formService.setInductionConfig(form).then(r =>{
            this.getUserJobs(site);
          });
        }
        else {
          alert(this._localizationService.translate("induction_dialog_siteinduction_notexist"));
          this._siteService.removeSignedInSite();
          this._router.navigate(['signin']);
        }
      },
      error :  e => {
        alert(this._localizationService.translate("induction_dialog_siteinduction_notexist"));
        this._siteService.removeSignedInSite();
        this._router.navigate(['signin']);
      }
    });
  }

  reloadWhenBrowserClose(){
    if(this._utilsService.isMobileAndTablet()){
        Browser.addListener('browserFinished',() =>  {
          Browser.removeAllListeners().then(s=>{});
          location.replace('signin');
        });
    }
  }

  getJobCountForSite(siteId:string):number{
    var defaultCount = 0;

    if(siteId && this.jobs && this.jobs.length > 0){
      defaultCount = this.jobs.filter(s => s.siteId == siteId).length;
    }

    return defaultCount;
  }

  isSiteOnClickEnable(site:Site):boolean{
    var isEnable = true;
    if(site && !site.isInductionRequired){
      isEnable = this.getJobCountForSite(site.id) > 0;
    }
    return isEnable;
  }
}