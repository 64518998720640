<div class="main-content">

    <mat-toolbar class="bg-white mat-toolbar-override">
        <button mat-icon-button (click)="back()" id="visitor_type_management_add_edit_back_btn">
            <mat-icon color="primary">
                keyboard_backspace
            </mat-icon>
        </button>
    </mat-toolbar>

    <div class="header">
        <h1>{{ ((!isEditMode ? 'visitor_type_management_add_edit_add_header' :
            'visitor_type_management_add_edit_edit_header') | translate ) }}</h1>
    </div>
    <label class="details">
        {{ ('visitor_type_management_add_edit_details' | translate) }}
    </label>
    <form [formGroup]="formGroup">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="100%">
                <div class="input-field">
                    <mat-form-field appearance="fill" fxFlex="100">
                        <mat-label class="color-city-neutral">
                            {{ 'visitor_type_management_add_edit_form_lbl_description' | translate }}
                        </mat-label>
                        <input matInput
                            placeholder="{{ 'visitor_type_management_add_edit_form_lbl_description' | translate }}"
                            formControlName="description" required id="input_description" />
                        <mat-error *ngIf="hasError(formGroup, 'description')">
                            {{ 'visitor_type_management_add_edit_error_visitor_types_description_validator' | translate
                            }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="input-field">
                    <mat-form-field appearance="fill" fxFlex="100">
                        <mat-label class="color-city-neutral">
                            {{ 'visitor_type_management_add_edit_form_lbl_brand' | translate }}
                        </mat-label>
                        <mat-select placeholder="{{ 'visitor_type_management_add_edit_form_lbl_brand' | translate }}"
                            formControlName="brand" required id="input_brand" [(value)]="selectedBrandName">
                            <mat-option *ngFor="let brand of existingBrands" [value]="brand.name">
                                {{ brand.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="input-field">
                    <mat-form-field appearance="fill" fxFlex="100">
                        <mat-label class="color-city-neutral">
                            {{ 'visitor_type_management_add_edit_form_lbl_domain' | translate }}
                        </mat-label>
                        <mat-select placeholder="{{ 'visitor_type_management_add_edit_form_lbl_domain' | translate }}"
                            formControlName="domain" id="input_domain" [(value)]="selectedDomainName">
                            <mat-option *ngFor="let domain of existingDomains" [value]="domain.name">
                                {{ domain.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="item-table" *ngIf="isEditMode || isVisitorTypeCreated">
                    <div fxLayout="row" fxLayoutAlign="space-between end" fxLayout="row wrap">
                        <h5>{{'visitor_type_management_add_edit_list_header' | translate
                            }}<mat-icon>help_outline</mat-icon></h5>

                        <div fxLayoutAlign="center center" fxLayout="row wrap">

                            <button *ngIf="showSearchButton()" data-testid="visitor_type_management_list_search_icon"
                                mat-fab color="primary" style="margin-right: 1em;" (click)="openSearch()">
                                <mat-icon>search</mat-icon>
                            </button>

                            <div *ngIf="showSearchInput()">
                                <mat-form-field style="margin-bottom: -1.25em; margin-right: 1em;"
                                    fxLayoutAlign="end end">
                                    <mat-label>{{'visitor_type_management_add_edit_search_label' | translate
                                        }}</mat-label>
                                    <input matInput data-testid="visitor_type_management_list_search_input" type="text"
                                        [formControl]="searchControl"
                                        placeholder="{{'visitor_type_management_add_edit_search_label' | translate }}"
                                        (keydown)="onSearchInputKeydown($event)">
                                    <button data-testid="visitor_type_management_list_close_search_icon" matSuffix
                                        mat-icon-button (click)="closeSearch()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>

                            <button id="btn_add_reasonForVisit" data-testid="visitor_type_management_list_add" mat-fab
                                color="primary" type="button" (click)="addNewReasonForVisit()">
                                <mat-icon>add</mat-icon>
                            </button>

                        </div>

                    </div>
                    <div class="divider"></div>
                    <mat-divider class="table-divider-header"></mat-divider>
                    <div>
                        <table mat-table data-testid="visitor_type_management_list_top_table" [dataSource]="dataSource"
                            matSort (matSortChange)="onSortChange($event)">

                            <ng-container matColumnDef="Description">
                                <th mat-header-cell *matHeaderCellDef fxFlex="90" fxLayoutAlign="start center"
                                    mat-sort-header sortActionDescription="Sort by description">
                                    {{'visitor_type_management_add_edit_description_column_header' | translate }}
                                </th>
                                <td mat-cell class="color-dark-blue" *matCellDef="let reason" fxFlex="90"
                                    fxLayoutAlign="start center">
                                    <span class="ellipsis">{{reason.description}} </span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Actions">
                                <th mat-header-cell *matHeaderCellDef fxFlex="10" fxLayoutAlign="center center">
                                    {{'visitor_type_management_add_edit_actions_column_header' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let reason" fxFlex="10" fxLayoutAlign="start center">
                                    <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                                        <mat-icon matTooltip="{{editToolTip}}"
                                            id="visitor_type_management_list_edit_icon"
                                            data-testid="visitor_type_management_list_edit_icon" color="primary"
                                            (click)="editReasonForVisit(reason)">
                                            edit
                                        </mat-icon>
                                        <mat-icon matTooltip="{{deleteToolTip}}"
                                            id="visitor_type_management_list_delete_icon"
                                            data-testid="visitor_type_management_list_delete_icon" color="primary"
                                            (click)="deleteReasonForVisit(reason)">
                                            delete
                                        </mat-icon>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedReasonForVisitColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedReasonForVisitColumns;"
                                class="element-row"></tr>

                        </table>

                        <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50]"
                            [length]="totalItems" showFirstLastButtons></mat-paginator>

                    </div>
                </div>
                <div class="button-grp" fxLayout="column" fxFlex="100%" fxLayoutGap="1em">
                    <button id="btn_save" mat-raised-button (click)="saveOnClick()" type="submit"
                        [disabled]="disableSaveOnClick() || isVisitorTypeCreated">
                        {{ 'visitor_type_management_add_edit_save_btn' | translate }}
                    </button>
                    <button id="btn_cancel" mat-raised-button (click)="closeOnClick()">
                        {{ 'visitor_type_management_add_edit_close_btn' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>