<div class="dialog-container">
    <h1>{{ 'site_management_edit_header' | translate }}</h1>
    <div class="divider"></div>
    <mat-divider class="table-divider-header"></mat-divider>
    <div>
        <mat-table data-testid="site_management_list_top_table" id="site_management_list_top_table"
          [dataSource]="filteredSites" multiTemplateDataRows>  
  
          <ng-container matColumnDef="Name" >
            <mat-header-cell *matHeaderCellDef fxFlex="50" fxLayoutAlign="start center">
              {{'site_management_table_site_name_header' | translate }}
            </mat-header-cell>
            <mat-cell class="color-dark-blue" *matCellDef="let site" fxFlex="50" fxLayoutAlign="start center">  
              <span class="ellipsis">{{site.site.name}} </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Code" >
            <mat-header-cell *matHeaderCellDef fxFlex="10" fxLayoutAlign="start center">
              {{'site_management_table_site_code_header' | translate }}
            </mat-header-cell>
            <mat-cell class="color-dark-blue" *matCellDef="let site" fxFlex="10" fxLayoutAlign="start center">  
              <span class="ellipsis">{{site.site.code}} </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="Id" >
            <mat-header-cell *matHeaderCellDef fxFlex="50" fxLayoutAlign="start center">
              {{'site_management_table_site_id_header' | translate }}
            </mat-header-cell>
            <mat-cell class="color-dark-blue" *matCellDef="let site" fxFlex="50" fxLayoutAlign="start center">  
              <span class="ellipsis">{{site.site.id}} </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="QR" >
            <mat-header-cell *matHeaderCellDef fxFlex="50" fxLayoutAlign="start center">
              {{'site_management_table_site_qr_header' | translate }}
            </mat-header-cell>
            <mat-cell class="color-dark-blue" *matCellDef="let site" fxFlex="50" fxLayoutAlign="start center">  
              <qrcode [qrdata]="site.qrCodeLink" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="QR Code Link" >
            <mat-header-cell *matHeaderCellDef fxFlex="50" fxLayoutAlign="start center">
              {{'site_management_table_site_qr_code_link_header' | translate }}
            </mat-header-cell>
            <mat-cell class="color-dark-blue" *matCellDef="let site" fxFlex="50" fxLayoutAlign="start center">  
              <span class="ellipsis">{{site.qrCodeLink}} </span>
            </mat-cell>
        </ng-container>
  
          <mat-header-row *matHeaderRowDef="displayedSiteColumns"></mat-header-row>
          <mat-row *matRowDef="let element; columns: displayedSiteColumns;" class="element-row"></mat-row>
        </mat-table>
      </div>  

    <div class="divider"></div>

    <button 
        id="site_management_edit_cancel"
        data-testid="site_management_edit_cancel"
        mat-stroked-button class="color-city-blue btn-border-color"    
        (click)="dismissDialog()">
        {{ 'site_management_edit_cancel_button' | translate }}
  </button>   
</div>