<div class="dialog-container">      
    <label class="deleteHeaderLabel" id="deleteHeaderLabel">
        {{ 'user_profile_delete_header'  | translate }}
    </label>
    <div class="divider"></div>
    <div class="deleteContentLabel" fxLayout="column" fxLayoutGap="1em">
        <div fxLayout="row">
            <div fxFlex = "5"></div>
            <div fxFlex = "95">
                <mat-label class="color-dark-blue">
                    {{'user_profile_delete_content' | translate }}
                </mat-label>
            </div> 
        </div>
        <div fxLayout="row">
            <div fxFlex = "15"></div>
            <div fxFlex = "85">
                <mat-label class="color-dark-blue">
                    {{'user_profile_delete_content_detail1' | translate }}
                </mat-label>
            </div> 
        </div>
        <div fxLayout="row">
            <div fxFlex = "15"></div>
            <div fxFlex = "85">
                <mat-label class="color-dark-blue">
                    {{'user_profile_delete_content_detail2' | translate }}
                </mat-label>
            </div> 
        </div>
        <div fxLayout="row">
            <div fxFlex = "15"></div>
            <div fxFlex = "85">
                <mat-label class="color-dark-blue">
                    {{'user_profile_delete_content_detail3' | translate }}
                </mat-label>
            </div> 
        </div>
        <div fxLayout="row">
            <div fxFlex = "15"></div>
            <div fxFlex = "85">
                <mat-label class="color-dark-blue">
                    {{'user_profile_delete_content_detail4' | translate }}
                </mat-label>
            </div> 
        </div>
        <div fxLayout="row">
            <div fxFlex = "15"></div>
            <div fxFlex = "85">
                <mat-label class="color-dark-blue">
                    {{'user_profile_delete_content_detail5' | translate }}
                </mat-label>
            </div> 
        </div>
    </div>
    <div class="divider"></div>
    <div fxLayout="row">
        <mat-label class="color-dark-blue">
            {{'user_profile_delete_input' | translate }}
        </mat-label>        
    </div>
    <form [formGroup]="deleteUserFormGroup">
        <div fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label class="color-city-blue">
                  {{'user_profile_delete_input_placeholder' | translate }}
                </mat-label>
                <input matInput 
                  data-testid="delete_enter_email_address"
                  placeholder="{{'user_profile_delete_input_placeholder' | translate }}" 
                  formControlName="emailAddress" required id="input_email_address">
                <mat-error *ngIf="hasError(deleteUserFormGroup, 'emailAddress')">
                  {{getError(deleteUserFormGroup, 'emailAddress')}}
                </mat-error>
            </mat-form-field>            
        </div>       
    </form>
   
    <div class="divider"></div>
    <button type="submit" 
      id="user_profile_delete_submit"
      data-testid="user_profile_delete_submit"
      mat-raised-button 
      (click)="deactivateUser()" 
      [disabled]="disableSubmitButton()">
      {{'user_profile_delete_submit' | translate }}
    </button>
    <div class="divider"></div>
    <button mat-stroked-button 
      class="color-city-blue btn-border-color" 
      data-testid="user_profile_delete_cancel"
      (click)="dismissDialog()">
      {{'user_profile_delete_cancel' | translate }}
    </button>
  </div>
