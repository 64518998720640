<div class="dialog-container">
  <label class="addFileLabel" id="attachmentAddFileLabel">
    {{ ('user_documents_addfile_lbl_header' | translate).replace("{document}", data.docType) }}
  </label>
  <div class="divider"></div>
  <div class="divider"></div>
  <div fxLayout="column" class="button-container">
    <input #fileUpload type="file" 
    data-testid="user_documents_input" 
    (change)="importFile($event)" class="fileUpload">
    <button mat-stroked-button class="color-city-blue"
    data-testid="user_documents_add_new" 
    (click)="fileUpload.click()">
      <mat-icon>add_photo_alternate</mat-icon>
      <span class="span-text">{{ 'user_documents_addfile_btn_addnew' | translate }}</span>  
    </button>    
  </div>
  <div class="divider"></div>
  <button mat-stroked-button class="color-city-blue btn-border-color" 
    data-testid="user_documents_add_cancel" 
    (click)="dismissDialog()">
    {{ 'user_documents_addextlink_btn_cancel' | translate }}
  </button>   
</div>

