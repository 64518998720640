import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { LocalizationService } from '../internationalization/localization.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/util/util-service';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { AppNavigationModel } from 'src/app/models/appnavigation.model';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { User } from 'src/app/models/user.model';
import { CompanyType, UserType } from 'src/app/shared/enums';
import { ClosedownService } from 'src/app/services/closedown/closedown.service';
import { ConnectionStatus, Network } from '@capacitor/network';
import { LoaderService } from '@app/services/loader/loader.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { filter, map, tap } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { AppInsightsService } from '@app/services/appinsights/app-insights.service';
import { ConfigurationService } from '@app/services/configuration/configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  matSizeNavHeightClass = "mat-sidenav-web-with-mat-toolbar-height";
  currentUser: User | undefined;
  language: string = environment.defaultLang;
  mainClass = "main-content-status-bar";
  deviceInfo: any = null;
  appNavigationModel: AppNavigationModel | undefined;
  appEnvironment = environment.appEnvironment.toLocaleLowerCase();
  workBench: string = 'WORKBENCH';
  isResourceAdmin: boolean = false;
  isMobileAndTablet: boolean = false;
  termsCondition = ``;
  privacyPolicy = ``;
  isNetworkAvailabile = true;
  version = environment.appVersion;
  isSearchIndexingBlocked = environment.blockSearchIndexing;
  isCityAdmin: boolean = false;

  @ViewChild('sidenav') matSidenav: MatSidenav | undefined;;

  constructor(private _utilService: UtilsService,
    private _localizationService: LocalizationService,
    private _zone: NgZone,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _navigationService: NavigationService,
    private _location: Location,
    private _authServices: AuthService,
    private _closedownService: ClosedownService,
    private _loader: LoaderService,
    private _swUpdate: SwUpdate,
    private _meta: Meta,
    private _appInsightsService: AppInsightsService,
    private titleService: Title,
    private _configurationService: ConfigurationService) {
    this._configurationService.getTermsConditionLink().then(t => {
      this.termsCondition = t;
    });

    this._configurationService.getPrivacyPolicyLink().then(t => {
      this.privacyPolicy = t;
    });


    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this._router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
    ).subscribe((data: any) => {
      if (data) {
        this.titleService.setTitle('City Onsite - ' + data);
      }
    });

    this.updateClient();
    this.onLangSelect(this.language);
    this.initConnection();
    this.initializeApp();
    this.isMobileAndTablet = this._utilService.isMobileDevice();
  }

  initializeApp() {
    this._authServices.isAuthenticated().then(auth => {
      if (auth) {
        this._authServices.getUser()
          .then(user => {
            this._appInsightsService.setUserId(user.emailAddress);
            this.currentUser = user;
          });
      } else {
        this._appInsightsService.clearUserId();
      }
    });

    this._navigationService.messageSubject.subscribe({
      next: model => {
        this.appNavigationModel = model;
        if (!this.isMobileAndTablet) {
          if (!this.appNavigationModel.enableMenu && !this.appNavigationModel.enableBackButton) {
            if (this.appNavigationModel.remainHeaderPadding) {
              this.mainClass = "main-content-status-bar-web-with-mat-toolbar";
              this.matSizeNavHeightClass = "mat-sidenav-web-with-mat-toolbar-height";
            } else {
              this.mainClass = "main-content-status-bar-web-no-mat-toolbar";
              this.matSizeNavHeightClass = "mat-sidenav-web-no-mat-toolbar-height";
            }
          } else {
            this.mainClass = "main-content-status-bar-web";
            this.matSizeNavHeightClass = "mat-sidenav-web-with-mat-toolbar-height";
          }
        } else {
          this.mainClass = "main-content-status-bar";
          this.matSizeNavHeightClass = "";
        }
      }
    });
    this.initAppListerner();
  }

  initAppListerner() {
    Network.addListener('networkStatusChange', (status) => {
      this._zone.run(() => {
        this.setNetworkIfAvailable(status);
      });
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this._zone.run(() => {
        const domain = environment.appDomain;
        if (event.url.indexOf(`${environment.appName}://auth`) > -1) {
          if (this._loader.isLoading) {
            this._loader.hide();
          }
          this._router.navigate(['login']);
        } else if (event.url.indexOf(`${environment.appName}://userprofile`) > -1) {
          this._router.navigate(['userprofile']);
        } else {
          const pathArray = event.url.split(domain);
          const appPath = pathArray.pop();
          if (appPath) {
            this._router.navigateByUrl(appPath);
          }
        }
      });
    });

    App.addListener('backButton', ({ canGoBack }) => {
      if (confirm(this._localizationService.translate('appcomponent_dialog_exit'))) {
        if (this.isMobileAndTablet) {
          App.exitApp();
        } else {
          this._authServices.logout().then(r => {
            this._router.navigate(['login']);
          });
        }
      }
    });

  }

  ngOnInit(): void {
    if (this.isSearchIndexingBlocked) {
      this._meta.addTag(
        { name: 'robots', content: 'noindex' }
      );
    }
    this.getResourceAdminState();
    this.getCityAdminState();
  }

  getResourceAdminState() {
    this._authServices.getResourceAdminState().subscribe(isAdmin => {
      this.isResourceAdmin = isAdmin;
    });
  }

  async getCityAdminState() {
    this._authServices.isAuthenticated().then(async auth => {
      if (auth) {
        const roles = await this._authServices.getUserRoles();
        if (roles && roles.length > 0 && roles.includes(UserType.CITY_ADMIN)) {
          this.isCityAdmin = true;
        }
      }
    });

  }

  onLangSelect(lang: string): void {
    localStorage.setItem('language', lang);
    this._localizationService.initService();
  }

  logOutOnClick() {
    if (confirm(this._localizationService.translate("appcomponent_dialog_logout"))) {
      this.appNavigationModel = undefined;
      this._router.navigate(['logout']);
    }
  }

  userProfileOnClick() {
    this._router.navigate(['userprofile']);
    this.matSidenav?.close();
  }

  userDocumentsOnClick() {
    this._router.navigate(['userdocuments']);
    this.matSidenav?.close();
  }

  termsConditionOnClick() {
    window.open(this.termsCondition, '_blank');
    this.matSidenav?.close();
  }

  privacyPolicyOnClick() {
    window.open(this.privacyPolicy, '_blank');
    this.matSidenav?.close();
  }

  needHelpOnClick() {
    this._configurationService.getNeedHelpDocUrl().then(t => {
      window.open(t, '_blank');
      this.matSidenav?.close();
    });
  }

  workBenchOnClick() {
    if (this.currentUser && this.isResourceAdmin) {
      this._closedownService.directToClosedown(false);
    }
  }

  userManagementOnClick() {
    this._router.navigate(['usermanagementlist']);
    this.matSidenav?.close();
  }

  siteManagementOnClick(){
    this._router.navigate(['sitemanagementlist']);
    this.matSidenav?.close();
  }

  back() {
    this._location.back();
  }

  async initConnection(): Promise<void> {
    const status = await Network.getStatus();
    this.setNetworkIfAvailable(status);
  }

  setNetworkIfAvailable(status: ConnectionStatus) {
    if (status.connectionType === 'none')
      this.isNetworkAvailabile = false;
    else
      this.isNetworkAvailabile = true;
  }

  updateClient() {
    if (this._swUpdate.isEnabled) {
      this._swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          tap(() => {
            this._swUpdate.activateUpdate().then(() => window.location.reload());
          })
        )
        .subscribe();
    }
  }

  adminManagementOnClick() {
    this._router.navigate(['adminmanagementlist']);
    this.matSidenav?.close();
  }

  visitorTypeManagementOnClick() {
    this._router.navigate(['visitortypemanagement']);
    this.matSidenav?.close();
  }
}