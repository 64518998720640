<div class="main-content">
  <form [formGroup]="siteComplianceCheckFormGroup" class="profile-form">
    <div fxLayout="row">
      <div fxLayout="column" fxLayoutAlign="center" fxFlex="65">
        <div class="color-city-neutral font-size-small-text-bold">{{ ('site_compliance_lbl_name' | translate) }}</div>
        <div class="color-dark-blue font-size-body-text display-label-detail">{{fullname}}</div>
        <div class="color-city-neutral font-size-small-text-bold">{{ ('userprofile_phone' | translate) }}</div>
        <div class="color-dark-blue font-size-body-text display-label-detail">{{phoneNumber}}</div>
        <mat-form-field *ngIf="rapidGlobalInfoList.length > 1" appearance="fill">
          <mat-label class="color-city-blue font-size-small-text-bold">{{ ('site_compliance_lbl_company' | translate)
            }}</mat-label>
          <mat-select class="color-dark-blue font-size-body-text display-label-detail" [(value)]="companyName"
            (selectionChange)="selectCompany($event.value)" [disabled]="rapidGlobalInfoList.length <= 1">
            <mat-option *ngIf="rapidGlobalInfoList.length <= 1" [value]="defaultCompanyName"> {{ defaultCompanyName }} </mat-option>
            <mat-option *ngFor="let rapidGlobalInfo of rapidGlobalInfoList" [value]="rapidGlobalInfo.companyName">
              {{rapidGlobalInfo.companyName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="rapidGlobalInfoList.length <= 1" appearance="fill">
          <div class="color-city-neutral font-size-small-text-bold">{{ ('site_compliance_lbl_company' | translate) }}
          </div>
          <div class="color-dark-blue font-size-body-text display-label-detail">{{companyName}}</div>
        </div>
      </div>
      <div fxLayoutAlign="end start" fxFlex="1 2 auto">
        <img *ngIf="profileImgLink" src={{profileImgLink}} (error)="profileImgLink=''" class="profile-image">
        <mat-icon *ngIf="!profileImgLink" class="profile-image-empty-icon">account_circle</mat-icon>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="divider"></div>
    <div fxLayout="row"
      *ngIf="profileInductionRequired && selectedSiteInductionInfo?.inductionId  != undefined && selectedSiteInductionInfo?.inductionId  != null">
      <div fxLayout="column" fxFlex="100%">
        <div fxLayout="row">
          <button mat-stroked-button color="basic" disabled>
            <div fxLayout="column" fxFlex="30%">
              <div fxFlex="0 100 100%" fxLayoutAlign="start center">
                <img class="site-image" [src]="currentSite?.brand?.imageUrl" />
              </div>
            </div>
            <div fxLayout="column" fxFlex="60%">
              <div fxLayout="row">
                <span class="display-bold color-dark-blue display-button-detail">{{ ('site_compliance_lbl_induction_no'
                  |
                  translate).replace("{induction_no}", selectedSiteInductionInfo?.inductionId) }}</span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="divider"></div>
    <div fxLayout="row" *ngIf="profileRapidGlobalRequired">
      <div fxLayout="column" fxFlex="100%">
        <div fxLayout="row">
          <button mat-stroked-button color="basic" disabled>
            <div fxLayout="row" fxFlex="100">
              <div fxLayoutAlign="start center" class="compliance-button-img">
                <img class="site-image" src="/assets/RapidGlobalLogo.png" />
              </div>
              <div fxLayout="column" class="compliance-button-msg"
                [fxLayoutAlign]="complianceErrorMessage ? 'center start' : 'center center'">
                <div class="compliance-status" *ngIf="selectedRapidGlobalInfo?.status">{{ selectedRapidGlobalInfo?.status }}</div>
                <div class="compliance-status" *ngIf="checkIfComplianceShouldDisplayNotEnable()">{{ statusNotApplicable }}</div>
                <div class="compliance-status" *ngIf="companyName == this.defaultCityFMCompanyName">{{ this.statusCompliant }}</div>
                <div *ngIf="complianceErrorMessage" class="compliance-message"
                  style="width: inherit; overflow: hidden; text-overflow: ellipsis; text-align: left;">{{ complianceErrorMessage }}</div>
              </div>
              <div class="compliance-button-icon" fxLayoutAlign="end center">
                <mat-icon class="color-city-green"
                  *ngIf="selectedRapidGlobalInfo?.status === statusCompliant || companyName == defaultCityFMCompanyName">task_alt</mat-icon>
                <mat-icon class="color-city-red"
                  *ngIf="selectedRapidGlobalInfo?.status === statusNotCompliant">error</mat-icon>
                <div class="na-icon" *ngIf="selectedRapidGlobalInfo?.status === statusNotApplicable || checkIfComplianceShouldDisplayNotEnable()">
                  {{ notComplianceIcon }}</div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>