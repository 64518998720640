import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { InductionConfigDetail } from 'src/app/models/inductionconfigdetail.model';
import { Job } from 'src/app/models/job.model';
import { Site } from 'src/app/models/site.model';
import { User } from 'src/app/models/user.model';
import { VisitReason } from 'src/app/models/visitreason.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { JobService } from 'src/app/services/job/job.service';
import { SiteService } from 'src/app/services/site/site.service';
import { LocalizationService } from '../internationalization/localization.service';
import { ReferenceService } from 'src/app/services/reference/reference.service';
import { FormService } from 'src/app/services/form/form.service';
import { InductionConfig } from 'src/app/models/inductionconfig.model';
import { VisitSummaryJob } from 'src/app/models/visitsummaryjob.model';
import { ClosedownService } from 'src/app/services/closedown/closedown.service';
import { SiteVisitReason } from '../../models/sitevisitreason.model';
import { CompanyType, FastLinkType, JobType } from '../../shared/enums';
import { UserService } from '@app/services/user/user.service';

@Component({
    selector: 'app-site-sign-out-visit-summary',
    templateUrl: './site-sign-out-visit-summary.component.html',
    styleUrls: ['./site-sign-out-visit-summary.component.scss']
})
export class SiteSignOutVisitSummaryComponent implements OnInit {
    jobsAllocated: VisitSummaryJob[] = [];
    jobsParentUser: Job[] = [];
    currentUser: User | undefined;
    selectedSite: Site | undefined;
    reasonsForVisit: VisitReason[] = [];
    nonCityFMWorkChecked: boolean = false;
    cityFMWorkChecked: boolean = false;
    nonCityFMWork: string = "noncifyfmwork";
    nonCityFMWorkLabel: string | undefined;
    inductionConfig: InductionConfig | undefined;
    SUB_CONTRACTOR: string = 'SUBCON';
    outOfRange: boolean = false;
    siteVisitReasons: SiteVisitReason[] = [];

    constructor(private _localizationService: LocalizationService,
        private _authService: AuthService,
        private _jobService: JobService,
        private _siteService: SiteService,
        private _router: Router,
        private _userService: UserService,
        private _closedownService: ClosedownService) { }

    async ngOnInit(): Promise<void> {
        this._authService.getUser().then(user => {
            if (user) {
                this.currentUser = user;
                this.getSiteData();
            }
        })
        this.outOfRange = (await this._userService.getOutOfRange()) == "true";
        this.nonCityFMWorkLabel = this._localizationService.translate("reasonforvisit_lbl_noncityfmwork");
    }

    private getSiteData() {
        this._siteService.getSignedInSite().then(site => {
            if (site) {
                this.selectedSite = site;
                this.getUserJobsSelectedAsVisitReasons();
            } else {
                this._router.navigate(['signin']);
            }
        },
            error => {
                alert("Failed to get site info.");
                this._router.navigate(['signin']);
            });
    }

    private async getUserJobsSelectedAsVisitReasons() {

        this.siteVisitReasons = await this._siteService.getVisitReasons();//['6520177', '6520179', '5315098'];
        let userCompanies: string[] = [];
        if (this.currentUser) {
            if (this.currentUser.companies) {
                this.currentUser.companies.forEach(company => {
                    if (company.companyType.toUpperCase() != this.SUB_CONTRACTOR) {
                        userCompanies.push(company.referenceNumber);
                    }
                });
            }
            if (this.selectedSite && this.siteVisitReasons && this.siteVisitReasons.length > 0) {
                if (this.siteVisitReasons[0].id != "") {
                    this._jobService.getJobsBySelectedReasons(this.siteVisitReasons.map((s) => s.id)).subscribe(
                        jobs => {
                            if (jobs) {
                                this._closedownService.setUserJobs(jobs);
    
                                jobs.forEach(job => {
                                    var siteVisitReason = this.siteVisitReasons.find(x => x.id === job.referenceNumber);
    
                                    var imageUrl = "warning";
                                    var status = "";
    
                                    if (siteVisitReason?.id == job.referenceNumber) {
                                        job.reasonForVisitCheck = true;
                                        if ((siteVisitReason.calloutReferenceNumber != "") && 
                                            (siteVisitReason.version != job.version && job.jobStatusName?.toLowerCase() != "callout")) {                                                
                                            imageUrl = "done";
                                            status = job.jobStatusName;
                                        }
                                        else {
                                            imageUrl = "warning";
                                            status = "Not Updated";
                                        }
    
                                        if ((job.allocatedUserEmail) || (userCompanies?.some(x => x == job.companyReferenceNumber))) {
                                            var vsj: VisitSummaryJob = {
                                                job: job,
                                                imageUrl: imageUrl,
                                                description: "",
                                                resourceId: job.companyReferenceNumber,
                                                jobStatus: status,
                                                updated: true,
                                                fastLinkEnabled: (userCompanies?.some(x => x == job.companyReferenceNumber)) || (job.allocatedUserEmail?.toLowerCase() == this.currentUser?.emailAddress.toLowerCase())
                                            }
    
                                            this.jobsAllocated.push(vsj);
                                        }
                                    }
                                    else {
                                        this.jobsParentUser.push(job);
                                    }
                                })
                            }
                        })
                }
              
            }
        }
    }

    isReactiveJob(job: Job) {
        return (job.jobType == JobType.REACTIVE_JOB) ? true : false;
    }

    isPPMJob(job: Job) {
        return (job.jobType == JobType.PPM_JOB) ? true : false;
    }

    isJobReason() : boolean {
        if (this.siteVisitReasons && this.siteVisitReasons.length > 0) {
            if (this.siteVisitReasons[0].id !== '') {
                return true;              
            }
            else {
                return false;
            }
        }
        return false;
    }

    async fastlinkOnClick(resourceId: string, jobId: string, subcontractorResourceId: string, calloutReferenceId: string, jobType: string) {
        if (this.currentUser) {
            var processFlow = await this._closedownService.getProcessFlow();
            var allocatedToUser = this.jobsAllocated.some(s=>s.job.allocatedUserEmail.toLowerCase() == this.currentUser?.emailAddress.toLowerCase())
            processFlow.resource = resourceId;
            processFlow.jobId = jobId;
            var company = this.currentUser.companies.find(c => c.referenceNumber.toLowerCase() == resourceId.toLowerCase());
            if (company || allocatedToUser) {
                if (jobType == JobType.REACTIVE_JOB) {
                    if (company?.companyType == CompanyType.SELF) {
                        processFlow.url = btoa(`/#/Job/New/${jobId}`);
                    } else {
                        processFlow.linkType = FastLinkType.Closedown;
                        processFlow.url = btoa(`/#/FastClosedown/${jobId}/${calloutReferenceId}/${resourceId}/${subcontractorResourceId}`);
                    }

                } else if (jobType == JobType.PPM_JOB) {
                    if (company?.companyType == CompanyType.SELF) {
                        processFlow.url = btoa(`/#/Job/PPM/${jobId}`);
                    } else {
                        processFlow.linkType = FastLinkType.Closedown;
                        processFlow.url = btoa(`/#/PPMJob/${jobId}/${calloutReferenceId}/0/0/${subcontractorResourceId}`);
                    }
                }

                this._closedownService.setProcessFlow(processFlow);
                this._closedownService.directToClosedown(false, resourceId);
            }

        }
    }
}