import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Configuration } from 'src/app/models/configuration.model';
import { VisitorType } from 'src/app/models/visitortype.model';
import { VisitReason } from 'src/app/models/visitreason.model';
import { StorageKey } from 'src/app/shared/enums';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base/base.service';
import { Domain } from 'src/app//models/domain.model';
import { Brand } from '@app/models/brand.model';

@Injectable({
  providedIn: 'root'
})
export class ReferenceService extends BaseService {
  readonly KEY = StorageKey[StorageKey.CONFIGURATION];
  readonly BASE_URL = `${environment.gatewayAPI_V1}/references/`;
  // readonly BASE_URL = `http://localhost:21902/api/v1/`;
  readonly CONTROLLER_NAME_VISITOR_TYPES = `VisitorTypes/`;
  readonly CONTROLLER_NAME_VISIT_REASONS = `VisitReasons/`;
  readonly CONTROLLER_NAME_BRANDS = `Brands/`;
  readonly CONTROLLER_NAME_CONFIGURATIONS = `Configurations/`;
  readonly CONTROLLER_NAME_DOMAINS = `Domains/`;
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getVisitorTypes(brandId: string, isActive: boolean): Observable<VisitorType[] | null> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISITOR_TYPES}GetVisitorTypes?brandId=${brandId}&isActive=${isActive}`;

    return this.httpClient.get<VisitorType[]>(url).pipe(
      tap(_ => _),
      catchError(this.handleError)
    );
  }

  public getAllVisitorTypes(isActive: boolean = true): Observable<VisitorType[] | null> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISITOR_TYPES}GetAllVisitorTypes?isActive=${isActive}`;
    //const url = `https://localhost:7062/v1/gateway/references/visitortypes/getvisitorTypes?isActive=${isActive}`;

    return this.httpClient.get<VisitorType[]>(url).pipe(
      tap(_ => _),
      catchError(this.handleError)
    );
  }

  public getVisitorTypeById(id: string | null): Observable<VisitorType> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISITOR_TYPES}GetVisitorTypeById?Id=${id}`;
    // http://localhost:21902/api/v1/VisitorTypes/GetVisitorTypeById?Id=865AFA05-1034-4F56-B035-03BA12240761

    return this.httpClient.get<VisitorType>(url).pipe(
      tap(_ => _),
      catchError(this.handleError)
    )
  }

  public addVisitorType(visitorType: VisitorType): Observable<VisitorType> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISITOR_TYPES}Create`;
    // http://localhost:21902/api/v1/gateway/references/VisitorTypes/Create

    return this.httpClient.post<VisitorType>(url, visitorType).pipe(
      tap(_ => _),
      catchError(this.handleError)
    );
  }

  public updateVisitorType(visitorType: VisitorType): Observable<VisitorType> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISITOR_TYPES}Update`;
    // http://localhost:21902/api/v1/gateway/references/VisitorTypes/Update

    return this.httpClient.put<VisitorType>(url, visitorType).pipe(
      tap(_ => _),
      catchError(this.handleError)
    );
  }

  public deleteVisitorType(id: string): Observable<boolean> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISITOR_TYPES}Delete?id=${id}`;
    // http://localhost:21902/api/v1/gateway/references/VisitorTypes/Delete?id=DA08FDD1-2B2F-4644-82E0-BAF7D3FF504D

    return this.httpClient.delete<boolean>(url).pipe(
      tap(_ => _),
      catchError(this.handleError));
  }

  public getVisitReasons(visitorTypeId: string, isActive: boolean): Observable<VisitReason[] | null> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISIT_REASONS}GetVisitReasons?visitortypeid=${visitorTypeId}&isActive=${isActive}`;
    //const url = `https://localhost:7062/v1/gateway/references/visitreasons/getvisitreasons?visitortypeid=${visitorTypeId}&isActive=${isActive}`;

    return this.httpClient.get<VisitReason[]>(url).pipe(
      tap(_ => _),
      catchError(this.handleError)
    );
  }

  public addVisitReason(visitReason: VisitReason): Observable<VisitReason> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISIT_REASONS}Create`;

    return this.httpClient.post<VisitReason>(url, visitReason).pipe(
      tap(_ => _),
      catchError(this.handleError)
    );
  }

  public updateVisitReason(visitReason: VisitReason): Observable<VisitReason> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISIT_REASONS}Update`;

    return this.httpClient.put<VisitReason>(url, visitReason).pipe(
      tap(_ => _),
      catchError(this.handleError)
    );
  }

  public deleteVisitReason(id: string): Observable<boolean> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_VISIT_REASONS}Delete?id=${id}`;

    return this.httpClient.delete<boolean>(url).pipe(
      tap(_ => _),
      catchError(this.handleError));
  }

  public getBrands(isActive: boolean = true): Observable<Brand[] | null> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_BRANDS}GetBrands?isActive=${isActive}`;
    //const url = `http://localhost:21902/api/vi/gateway/references/Brands/GetBrands?isActive=true`;

    return this.httpClient.get<Brand[]>(url).pipe(
      tap(_ => _),
      catchError(this.handleError)
    )
  }

  public getConfigurations(isActive: boolean, requiredLoading: boolean): Observable<Configuration[] | null> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_CONFIGURATIONS}GetConfigurations?isActive=${isActive}`;

    if (requiredLoading) {
      return this.httpClient.get<Configuration[]>(url, { headers: { skip: "true" } }).pipe(
        tap(_ => _),
        catchError(this.handleError)
      );
    } else {
      return this.httpClient.get<Configuration[]>(url).pipe(
        tap(_ => _),
        catchError(this.handleError)
      );
    }
  }

  async getLocalConfigurations(): Promise<Configuration[]> {
    const item = await Preferences.get({ key: this.KEY });
    var obj;
    if (item.value != null)
      obj = JSON.parse(item.value);

    return obj;
  }

  async setLocalConfigurations(config: Configuration[]) {
    await Preferences.set({
      key: this.KEY,
      value: JSON.stringify(config),
    });
  }

  async removeConfiguration() {
    await Preferences.remove({ key: StorageKey[this.KEY] });
  }

  async getLocalConfiguration(keyToSearch: string): Promise<Configuration> {
    const item = await Preferences.get({ key: keyToSearch });
    var obj;
    if (item.value != null)
      obj = JSON.parse(item.value);

    return obj;
  }

  public async getLocalConfigurationKeys() {
    return (await Preferences.keys()).keys;
  }

  public getUserEmailDomain(): Observable<Domain> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_DOMAINS}GetDomainInfo`; 
    return this.httpClient.get<Domain>(url).pipe(
      tap(_ => _),
      catchError(this.handleError));
  }

  public getAllDomains(): Observable<Domain[] | null> {
    const url = `${this.BASE_URL}${this.CONTROLLER_NAME_DOMAINS}GetAllDomains`;
    return this.httpClient.get<Domain[]>(url).pipe(
      tap(_ => _),
      catchError(this.handleError));
  }

}
