import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomValidators {
    
    public static isRequired = (required: boolean) => {
        return (control: AbstractControl) => {
            const value = control.value;
            if (required && (value == null || value === undefined || value === ''))
                return { 'isRequired': true }
            return null;
        };
    }

    public static isEmailValid(): ValidatorFn {
        return (control: AbstractControl) => {
            const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!emailRegEx.test(control.value))
                return { 'invalidEmail': true };
            return null;
        }
    }

    public static isFileUrlValid(): ValidatorFn {
        return (control: AbstractControl) => {
            const urlRegEx = /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
            if (!urlRegEx.test(control.value))
                return { 'invalidFileUrl': true };
            return null;
        }
    }

}