<label class="font-size-headline color-city-blue" id="{{question.uniqueHtmlIdentifier}}">{{question.question.caption}}</label>
<div *ngFor="let option of question.question.additionalPropertiesParsed.Options; let indexOfelement=index;">
  <div fxLayout="row">
    <div fxLayout="column" style="padding-right: 0.5em;">
      <mat-checkbox 
        value={{option}} 
        (change)="setAnswer($event)" 
        checked={{indexSelected[indexOfelement]}} 
        id="questionOption{{question.uniqueHtmlIdentifier}}{{indexOfelement}}">
      </mat-checkbox>
    </div>       
    <div fxFlex = "80" fxLayout="column" style="margin-top: 0.2em;">
      <span class="checkbox-textwrap color-dark-blue font-size-small-text">
        {{option}}
      </span> 
    </div>
  </div>
</div>