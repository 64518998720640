import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private _authService: AuthService, private _router: Router) { };

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const isAuthenticated = await this._authService.getTokenFromStorage();
    if (isAuthenticated) {
      return true;
    }
    else {
      await this._authService.logout().then(() => {
        this._router.navigate(['login']);
      });
      return false;
    }
  }
}
