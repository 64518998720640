import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Brand } from 'src/app/models/brand.model';
import { SiteInductionInfo, User } from 'src/app/models/user.model';
import { SiteApiService } from 'src/app/services/site/site.api.service';
import { UserService } from 'src/app/services/user/user.service';
import { LocalizationService } from '../internationalization/localization.service';

@Component({
  selector: 'app-site-induction-add',
  templateUrl: './site-induction-add-edit.component.html',
  styleUrls: ['./site-induction-add-edit.component.scss']
})
export class SiteInductionAddEditComponent implements OnInit {
  public inductionFormGroup! : UntypedFormGroup;
  siteInductionInfo : SiteInductionInfo | undefined;
  currentUser : User | undefined;
  brands : Brand[] = [];
  brandSelected : Brand | undefined;
  isEditMode : boolean = false;

  constructor(private _dialogRef: MatDialogRef<SiteInductionAddEditComponent>,
    private _localizationService : LocalizationService,
    private _formBuilder: UntypedFormBuilder,
    private _userService : UserService,
    private _siteApiService : SiteApiService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this._siteApiService.getPartners().subscribe({
        next : (data) => {
          if (data){
            this.brands = data;
          }
        },
        error : () => {
          alert(this._localizationService.translate('register_getpartner_error'))
        }
      })
    }

  ngOnInit(): void {
    this.currentUser = this.data.user;
    this.siteInductionInfo = this.data.induction;
    this.isEditMode = this.data.isEditMode;
    this.inductionFormGroup = this.initFormGroup();
  }

  selectedBrand(event : any){
    this.brandSelected = event;
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }

  private initFormGroup(): UntypedFormGroup {
    const inductionForm = this._formBuilder.group({
      brandName: new UntypedFormControl({ value: this.siteInductionInfo?.brandName, disabled: this.isEditMode }),
      inductionId: new UntypedFormControl(this.siteInductionInfo?.inductionId, [Validators.required])
    });

    return inductionForm;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'brandName':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('register_brandname_required');
        } 
        break;
      case 'inductionId':  
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('register_inductionid_required');
        } 
        if (this.formHasError(form, controlName, 'exists')) {
          return this._localizationService.translate('register_inductionid_exists');
        } 
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }

  disableSubmitButton(){
    return !this.inductionFormGroup.valid;
  }

  addInduction(){
    if (this.validateInduction()){
      this.siteInductionInfo = this.getPostPayload();
      if (this.siteInductionInfo){
        if (this.currentUser){
          this._userService.addInduction(this.currentUser.id, this.siteInductionInfo).subscribe({
            next: (response => {
              this.currentUser?.siteInductionInfos.push(response);
              this._dialogRef.close(this.currentUser);
            }),
            error: (error => {
              alert(this._localizationService.translate('register_siteinductionid_saveerror'));
            }) 
          });
        }
      }
    } 
  }

  editInduction(){
    if (this.validateInduction()){
      this.siteInductionInfo = this.getPutPayload();
      if (this.currentUser && this.siteInductionInfo && this.siteInductionInfo.id){
        this._userService.editInduction(this.siteInductionInfo.id, this.siteInductionInfo).subscribe({
          next: (response => {
            if (response && this.currentUser?.siteInductionInfos){
              const indx = this.currentUser?.siteInductionInfos
                .findIndex(v => v.inductionId === this.siteInductionInfo?.inductionId) ?? -1;
              if (indx >= 0) 
                this.currentUser.siteInductionInfos[indx] = response;
              this._dialogRef.close(this.currentUser);
            }
          }),
          error: (error => {
            alert(this._localizationService.translate('register_siteinductionid_saveerror'));
          }) 
        });
      }
    } 
  }

  validateInduction() : boolean{
    const inductionId = this.inductionFormGroup.get('inductionId');
    if (this.brandSelected && this.currentUser){
      if (inductionId?.value){
        if (this.currentUser.siteInductionInfos.some(induction => induction.inductionId.toLowerCase() == inductionId.value.toLowerCase() && 
                                                                  induction.brandId.toLowerCase() == this.brandSelected?.id.toLowerCase())){
          inductionId.setErrors({ 'exists': true });
          return false;
        }
      }
    }
    return true;
  }

  getPostPayload() : SiteInductionInfo | undefined {
    const inductionId = this.inductionFormGroup.get('inductionId');
    if (this.brandSelected){
      const payload : SiteInductionInfo = {
        id : undefined,
        brandId : this.brandSelected.id,
        brandName : this.brandSelected.name,
        inductionId : inductionId?.value,
        status: 'Compliance',
        expiryDate: new Date('2029-12-31')
      }
      return payload;
    }
    return;
  }

  getPutPayload() : SiteInductionInfo | undefined {
    if (this.siteInductionInfo){
      const inductionId = this.inductionFormGroup.get('inductionId');
      this.siteInductionInfo.inductionId = inductionId?.value;
      return this.siteInductionInfo;
    }
    return;
  }

}