import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsService } from '@app/services/appinsights/app-insights.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor(private _appInsightsService: AppInsightsService) {
    super();
  }

  override handleError(error: Error) {
    this._appInsightsService.logException(error);
    super.handleError(error);
  }
}
