import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private appInsights: ApplicationInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableAutoRouteTracking: true
    }
  });;

  constructor() {
    if(environment.appInsights.instrumentationKey){
      this.appInsights.loadAppInsights();
      this.appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
        if (item.data) {
          item.data["AppVersion"] = environment.appVersion;
        }
      });
      this.appInsights.trackPageView();
    }
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception, severityLevel });
  }

  logExceptionMessage(exception: string, severityLevel?: number) {
    this.appInsights.trackException({ exception: new Error(exception), severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }
}
