import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VisitorType } from '@app/models/visitortype.model';
import { LocalizationService } from '../internationalization/localization.service';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Brand } from '@app/models/brand.model';
import { Domain } from "@app/models/domain.model";
import { VisitReason } from '@app/models/visitreason.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReasonForVisitDeleteComponent } from './reason-for-visit-delete/reason-for-visit-delete.component';
import { ReasonForVisitAddEditComponent } from './reason-for-visit-add-edit/reason-for-visit-add-edit.component';
import { ReferenceService } from '@app/services/reference/reference.service';
import { debounceTime, distinctUntilChanged, race } from 'rxjs';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-visitor-type-management-add-edit',
  templateUrl: './visitor-type-management-add-edit.component.html',
  styleUrls: ['./visitor-type-management-add-edit.component.scss']
})
export class VisitorTypeManagementAddEditComponent implements OnInit, AfterViewInit {

  public formGroup!: UntypedFormGroup;
  allVisitorTypes: VisitorType[] = [];
  visitorType: VisitorType | undefined;
  existingBrands: Brand[] = [];
  existingDomains: Domain[] = [];
  searchControl: FormControl = new FormControl('');
  debounce: number = 1000;
  isEditMode: boolean = false;
  isVisitorTypeCreated: boolean = false;

  originalVisitReasons: VisitReason[] = [];
  displayedReasonForVisitColumns: string[] = ['Description', 'Actions'];

  editToolTip: string;
  deleteToolTip: string;
  dataSource = new MatTableDataSource<VisitReason>(this.originalVisitReasons);
  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype);
  @ViewChild(MatSort) sort: MatSort;
  pageSize: number = 10;
  totalItems: number = 0;

  defaultSelectDomainOption: Domain = { 
    id: this._localizationService.translate("visitor_type_management_add_edit_default_domain_selection_id"), 
    name: this._localizationService.translate("visitor_type_management_add_edit_default_domain_selection_name"), 
    logoutURL: '' };

  originalFormGroup: any = {
    typeDescription: '',
    typeBrand: '',
    typeDomain: '',
  }

  selectedBrandName: string | undefined = '';
  selectedDomainName: string | undefined = '';
  selectedVisitorTypeId: string | null;
  component: { value: null; };

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _localizationService: LocalizationService,
    private _formBuilder: UntypedFormBuilder,
    private _addFileDialog: MatDialog,
    private _referenceService: ReferenceService,
    private _navigationService: NavigationService,
    private _snackBar: MatSnackBar
  ) {
    this._navigationService.onChange(true, false);
    this.formGroup = this.initFormGroup();
    this.editToolTip = this._localizationService.translate("visitor_type_management_add_edit_edit_tooltip");
    this.deleteToolTip = this._localizationService.translate("visitor_type_management_add_edit_delete_tooltip");
  }

  async ngOnInit(): Promise<void> {
    await this.loadVisitorType();
    this.closeSearch();
    this.searchValues();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private initFormGroup(): UntypedFormGroup {
    const formGroup = this._formBuilder.group({
      description: ['', [Validators.required, Validators.maxLength(250)]],
      brand: ['', Validators.required],
      domain: [''],
    });
    return formGroup;
  }

  async loadVisitorType(): Promise<void> {
    this._route.paramMap.subscribe(async params => {
      const visitorTypeId = params.get('visitorTypeId');
      if (visitorTypeId && visitorTypeId !== '') {
        this.selectedVisitorTypeId = visitorTypeId;
        this._referenceService.getVisitorTypeById(visitorTypeId).subscribe({
          next: async (vt) => {
            if (vt) {
              this.visitorType = vt;
              this.isEditMode = true;

              if (vt.brand) {
                this.selectedBrandName = vt.brand?.name;
                this.existingBrands.push(vt.brand!);
              }

              if (vt.domain) {
                this.selectedDomainName = vt.domain?.name;
                this.existingDomains.push(vt.domain!);
              }

              await this.populateFormWithVisitorType(vt);
              await this.loadVisitReasons();
            }
          },
          error: (error) => {
            console.error("visitor_type_management_add_edit_error_fetching_visitor_type_by_id", error);
          }
        })
      } else {
        await this.loadDomains();
        await this.loadAllVisitorTypes();
        await this.loadBrands();
      }
    });
  }

  async loadAllVisitorTypes() {
    this._referenceService.getAllVisitorTypes(true).subscribe({
      next: (visitorTypes) => {
        if (visitorTypes !== null && visitorTypes.length > 0) {
          this.allVisitorTypes = visitorTypes;
        }
      },
      error: (error) => {
        console.error(this._localizationService.translate("visitor_type_management_add_edit_error_fetching_visitor_types"), error);
      }
    });
  }

  async populateFormWithVisitorType(vt: VisitorType) {
    if (vt) {
      this.formGroup.patchValue({
        description: vt.description,
        brand: vt.brand!.name,
        domain: vt.domain?.name
      });
      this.originalFormGroup.typeDescription = vt.description;
      this.originalFormGroup.typeBrand = vt.brand!.name;
      this.originalFormGroup.typeDomain = vt.domain?.name;

      this.selectedBrandName = vt.brand?.name;
      this.selectedDomainName = vt.domain?.name;

      this.formGroup.get('brand')?.disable();
      this.formGroup.get('domain')?.disable();
    }
  }

  async loadBrands() {
    this._referenceService.getBrands(true).subscribe({
      next: (brands) => {
        if (brands !== null && brands.length > 0) {
          this.existingBrands = brands;
        }
      },
      error: (error) => {
        console.error(this._localizationService.translate("visitor_type_management_add_edit_error_fetching_brands"), error)
      }
    });
  }

  async loadDomains() {
    this._referenceService.getAllDomains().subscribe({
      next: (domains) => {
        if (domains !== null && domains.length > 0) {
          this.existingDomains = [this.defaultSelectDomainOption, ...domains];
        }
      },
      error: (error) => {
        console.error(this._localizationService.translate("visitor_type_management_add_edit_error_fetching_domains"), error);
      }
    });
  }

  async loadVisitReasons() {
    this._referenceService.getVisitReasons(this.selectedVisitorTypeId!, true).subscribe({
      next: (visitReasons) => {
        if (visitReasons !== null) {
          this.originalVisitReasons = visitReasons;
          this.dataSource.data = visitReasons;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.totalItems = this.dataSource.data.length;
        } else {

        }
      },
      error: (error) => {
        console.error(this._localizationService.translate("visitor_type_management_add_edit_error_fetching_visit_reasons"), error);
      }
    });
  }

  searchValues() {
    this.searchControl.valueChanges
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(query => {
        if (query) {
          if (query.length > 2) {
            let queryLower = query.toLowerCase();
            this.dataSource.data = this.originalVisitReasons.filter(vs =>
              vs.description.toLowerCase().includes(queryLower));
          }
        } else {
          this.dataSource.data = this.originalVisitReasons;
        }
      });
  }

  saveOnClick(): void {
    if (this.formGroup.valid) {
      if (this.isEditMode && this.visitorType) {
        const updatedVisitorType: VisitorType = {
          id: this.visitorType.id,
          isActive: this.visitorType.isActive,
          description: this.formGroup.get('description')?.value,
          languages: this.visitorType.languages !== null ? this.visitorType.languages : undefined,
          brand: this.visitorType.brand,
          domain: this.visitorType.domain !== null ? this.visitorType.domain : undefined
        }
        const isExisted = this.isVisitorTypeExisted(updatedVisitorType);
        if (!isExisted) {
          this.updateVisitorType(updatedVisitorType);
          this.loadAllVisitorTypes();
        }
      } else {

        const brandName = this.formGroup.get('brand')?.value;
        let selectedBrand: Brand | undefined = this.getValidBrandObject(brandName);

        const domainName = this.formGroup.get('domain')?.value;
        let selectedDomain: Domain | undefined;

        if (domainName && domainName !== this.defaultSelectDomainOption.name) {
          selectedDomain = this.getValidDomainObject(domainName);
        }

        const newVisitorType: VisitorType = {
          id: undefined,
          isActive: true,
          description: this.formGroup.get('description')?.value,
          languages: undefined,
          brand: selectedBrand ? selectedBrand : undefined,
          domain: selectedDomain ? selectedDomain : undefined
        }
        const isExisted = this.isVisitorTypeExisted(newVisitorType);
        if (!isExisted) {
          this.addNewVisitorType(newVisitorType);
          this.loadAllVisitorTypes();
        }
      }
    }
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = form.controls[controlName].hasError('maxlength');
    return validationOutput;
  }

  disableSaveOnClick(): boolean {
    const currentDescription = this.formGroup.get('description')?.value;
    const currentDomain = this.formGroup.get('domain')?.value;
    const currentBrand = this.formGroup.get('brand')?.value;

    if (currentDescription === this.originalFormGroup.typeDescription &&
      currentBrand === this.originalFormGroup.typeBrand &&
      currentDomain === this.originalFormGroup.typeDomain) {
      return true;
    }
    return this.formGroup.invalid;
  }

  onSortChange(sortState: any) {
    if (sortState.active && sortState.direction) {
      this.dataSource.data = this.dataSource.data.slice().sort((a, b) => {
        const isAsc = sortState.direction === 'asc';
        switch (sortState.active) {
          case 'Description': return this.compare(a.description || '', b.description || '', isAsc);
          default: return 0;
        }
      });
    }
  }

  compare(a: string | number | boolean, b: string | number | boolean, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSearchInputKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  closeOnClick(): void {
    this._router.navigate(['signin']);
  }

  openSearch() {
    this.searchControl.setValue('');
  };

  closeSearch() {
    this.searchControl.setValue(null);
  };

  showSearchButton() {
    return this.searchControl.value === null;
  };

  showSearchInput() {
    return this.searchControl.value !== null;
  };

  addNewVisitorType(visitorType: VisitorType) {
    this._referenceService.addVisitorType(visitorType).subscribe({
      next: (vt) => {
        if (vt) {
          this.visitorType = vt;
          this.isVisitorTypeCreated = true;
          this.formGroup.disable();
          this.showSnackbar(this._localizationService.translate("visitor_type_management_add_edit_snackbar_visitor_type_added_msg"));
        }
      },
      error: (error) => {
        console.error(this._localizationService.translate("visitor_type_management_add_edit_error_adding_visitor_types"), error);
      }
    })
  }

  updateVisitorType(visitorType: VisitorType) {
    this._referenceService.updateVisitorType(visitorType).subscribe({
      next: async (vt) => {
        if (vt) {
          this.showSnackbar(this._localizationService.translate("visitor_type_management_add_edit_snackbar_visitor_type_edited_msg"));
          this.originalFormGroup.typeDescription = vt.description;
        }
      },
      error: (error) => {
        console.error(this._localizationService.translate("visitor_type_management_add_edit_error_updating_visitor_types"), error);
      }
    })
  }

  isVisitorTypeExisted(visitorType: VisitorType): boolean {

    const isExisted = this.allVisitorTypes.some((vt) => {
      return vt.description.toLowerCase() === visitorType.description.toLowerCase().trim() &&
        vt.brand?.name.toLowerCase() === visitorType.brand?.name.toLowerCase().trim() &&
        vt.domain?.name.toLowerCase() === visitorType.domain?.name.toLowerCase().trim();
    });

    if (isExisted) {
      alert(this._localizationService.translate("visitor_type_management_add_edit_error_visitor_types_already_exist"));
    }

    return isExisted;
  }

  getValidBrandObject(brandName: string): Brand | undefined {
    const foundBrand = this.existingBrands.find(brand => brand && brand.name && brand.name.toLowerCase() === brandName.toLocaleLowerCase());
    return foundBrand ? foundBrand : undefined;
  }

  getValidDomainObject(domainName: string): Domain | undefined {
    const foundDomain = this.existingDomains.find(domain => domain && domain.name && domain.name.toLowerCase() === domainName.toLowerCase());
    return foundDomain ? foundDomain : undefined;
  }

  showSnackbar(message: string) {
    this._snackBar.open(message, 'Close', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  addNewReasonForVisit() {
    let addEditVisitorType: MatDialogRef<ReasonForVisitAddEditComponent>;
    const dialogConfig = {
      data: {
        reason: undefined,
        type: this.visitorType,
        reasonList: this.originalVisitReasons
      },
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass: 'details-container'
    };
    addEditVisitorType = this._addFileDialog.open(ReasonForVisitAddEditComponent, dialogConfig);
    addEditVisitorType.addPanelClass('details-container');
    addEditVisitorType.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedVisitorTypeId = result.visitorTypeId;
        this.loadVisitReasons();
        this.closeSearch();
        this.showSnackbar(this._localizationService.translate("visitor_type_management_add_edit_snackbar_visit_reason_added_msg"));
      }
    });
  }

  editReasonForVisit(reason: VisitReason) {
    let addEditVisitorType: MatDialogRef<ReasonForVisitAddEditComponent>;
    const dialogConfig = {
      data: {
        reason: reason,
        type: this.visitorType,
        reasonList: this.originalVisitReasons
      },
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass: 'details-container'
    };
    addEditVisitorType = this._addFileDialog.open(ReasonForVisitAddEditComponent, dialogConfig);
    addEditVisitorType.addPanelClass('details-container');
    addEditVisitorType.afterClosed().subscribe((result) => {
      if (result) {
        this.loadVisitReasons();
        this.closeSearch();
        this.showSnackbar(this._localizationService.translate("visitor_type_management_add_edit_snackbar_visit_reason_edit_msg"));
      }
    })
  }

  deleteReasonForVisit(reason: VisitReason) {
    let deleteVisitorType: MatDialogRef<ReasonForVisitDeleteComponent>;
    const dialogConfig = {
      data: {
        reason: reason
      },
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass: 'details-container'
    };
    deleteVisitorType = this._addFileDialog.open(ReasonForVisitDeleteComponent, dialogConfig);
    deleteVisitorType.addPanelClass('details-container');
    deleteVisitorType.afterClosed().subscribe((result) => {
      if (result) {
        this.loadVisitReasons();
        this.closeSearch();
        this.showSnackbar(this._localizationService.translate("visitor_type_management_add_edit_snackbar_visit_reason_delete_msg"));
      }
    })
  }

  back() {
    this._router.navigate(['visitortypemanagement']);
  }

}