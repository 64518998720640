<div class="main-content" #topPosition>
    <mat-toolbar class="bg-white mat-toolbar-override">
        <button mat-icon-button (click)="back()" id="btn_inductionback">
            <mat-icon color="primary">
                keyboard_backspace
            </mat-icon>
        </button>
    </mat-toolbar>
    <div *ngIf="hasError" >
        <div class="alert alert-danger">
            <p data-testid="induction_error_message">
                {{errorMessage}}
            </p>
        </div>
    </div>
    <div fxLayout="column">
        <div id="inductionheader" fxLayout="row">
            <div fxFlex = "70" fxLayout="column">
                <div data-testid="induction_title">
                    <span class="font-size-h1 color-city-blue">{{inductionTitle}}</span>
                </div>
                <div class="color-city-neutral font-size-small-text-bold" *ngIf="inductionSequence !== totalSequence">{{ 'induction_lbl_next' | translate }}</div>
                <div data-testid="induction_sub_title" class="color-city-blue font-size-small-text-bold">{{inductionSubTitle}}</div>
            </div>
            <div fxFlex = "30" class="spinner-progress" #spinnerDiv>
                <mat-progress-spinner class="spinner-background"
                    [mode]="progressMode"
                    value="100"
                    [diameter]="80">
                </mat-progress-spinner>
                <mat-progress-spinner #spinner
                    [color]="progressColor"
                    [mode]="progressMode"
                    [value]="progressValue"
                    [diameter]="80">
                </mat-progress-spinner>
                <div class="spinner-text">{{inductionSequence}} of {{totalSequence}}</div>
            </div>
        </div>
        <div class="divider"></div>
        <div class="divider"></div>
        <div id="inductionContent">
            <form novalidate name="induction.qnaForm">
                <div *ngIf="inductionConfigDetail?.type?.toLowerCase() == 'activity'">
                    <div class="form-group"  *ngFor="let question of runningQuestions">  
                        <ng-form name="templateFieldForm" *ngIf="question.question.questionType.name.trim().toLowerCase() !== 'endsysctrl'">
                            <app-questionrender [question]="question"></app-questionrender>
                        </ng-form>
                    </div>
                </div>
                <div *ngIf="inductionConfigDetail?.type?.toLowerCase() == 'compliancecheck'">
                    <app-site-compliance-check (showComplianceError)="updateHasError($event)" [inductionConfigDetail]="inductionConfigDetail"></app-site-compliance-check>
                </div>
                <div *ngIf="inductionConfigDetail?.type?.toLowerCase() == 'reasonforvisit'">
                    <app-reason-for-visit [inductionConfigDetail]="inductionConfigDetail"></app-reason-for-visit>
                </div>
            </form>
        </div>        
        <div class="divider"></div>
        <div fxFlex="100" class="continue_btn">
            <button mat-raised-button (click)="continueOnClick()" 
                id="btn_inductioncontinue"
                data-testid="induction_btn_continue">
                {{ submitButtonCaption }}
            </button>
        </div>
    </div>
</div>