
  
<div class="form-list">
  <div fxLayout="row" fxLayoutAlign="space-between end">
    <h5> {{ ('user_documents_list_swms' | translate)}} <mat-icon>help</mat-icon>
    </h5>
    <button data-testid="user_documents_list_swms_add" mat-fab color="primary" 
      (click)="attachFile('SWMS')">
      <mat-icon>note_add</mat-icon>
    </button>
  </div>  
  <div class="divider"></div>
  <mat-divider class="table-divider-header"></mat-divider> 
  <div>
    <table mat-table [dataSource]="documentsSWMS">            
      <ng-container matColumnDef="documentName" >
        <th mat-header-cell *matHeaderCellDef  fxFlex="82" fxLayoutAlign="start center"> {{ ('user_documents_list_document_table_header' | translate)}} </th>
        <td mat-cell class="color-dark-blue" *matCellDef="let document" fxFlex="82" fxLayoutAlign="start center"> {{document.documentName}} </td>
      </ng-container>
      <ng-container matColumnDef="rowbutton">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions" fxFlex="18" fxLayoutAlign="end center">&nbsp;</th>
        <td mat-cell *matCellDef="let document;" fxFlex="18" fxLayoutAlign="end center">
          <mat-icon data-testid="user_documents_list_swms_delete" color="primary" 
            (click)="deleteFile(document)">delete</mat-icon>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedDocumentColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDocumentColumns;"></tr>
    </table>
  </div>  
</div>  
<div class="form-list">
  <div fxLayout="row" fxLayoutAlign="space-between end">
    <h5> {{ ('user_documents_list_licences' | translate)}} <mat-icon>help</mat-icon>
    </h5>
    <button data-testid="user_documents_list_license_add" mat-fab color="primary" (click)="attachFile('License')">
      <mat-icon>note_add</mat-icon>
    </button>
  </div>  
  <div class="divider"></div>
  <mat-divider class="table-divider-header"></mat-divider> 
  <div>
    <table mat-table [dataSource]="documentsLicences">    
      <ng-container matColumnDef="documentName" >
        <th mat-header-cell *matHeaderCellDef fxFlex="82" fxLayoutAlign="start center"> {{ ('user_documents_list_document_table_header' | translate)}} </th>
        <td mat-cell class="color-dark-blue" *matCellDef="let document" fxFlex="82" fxLayoutAlign="start center"> {{document.documentName}} </td>
      </ng-container>      
      <ng-container matColumnDef="rowbutton">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions" fxFlex="18" fxLayoutAlign="end center">&nbsp;</th>
        <td mat-cell *matCellDef="let document;" fxFlex="18" fxLayoutAlign="end center">
          <mat-icon data-testid="user_documents_list_licences_delete" color="primary" 
            (click)="deleteFile(document)">delete</mat-icon>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedDocumentColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDocumentColumns;"></tr>
    </table>
  </div>    
</div>
<div class="form-list">
  <div fxLayout="row" fxLayoutAlign="space-between end">
    <h5> {{ ('user_documents_list_cert' | translate)}} <mat-icon>help</mat-icon>
    </h5>
    <button data-testid="user_documents_list_cert_add" mat-fab color="primary" (click)="attachFile('Cert')">
      <mat-icon>note_add</mat-icon>
    </button>
  </div>    
  <div class="divider"></div>
  <mat-divider class="table-divider-header"></mat-divider> 
  <div>
    <table mat-table [dataSource]="documentsCert">    
      <ng-container matColumnDef="documentName" >
        <th mat-header-cell *matHeaderCellDef  fxFlex="82" fxLayoutAlign="start center"> {{ ('user_documents_list_document_table_header' | translate)}} </th>
        <td mat-cell class="color-dark-blue" *matCellDef="let document" fxFlex="82" fxLayoutAlign="start center"> {{document.documentName}} </td>
      </ng-container>      
      <ng-container matColumnDef="rowbutton">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions" fxFlex="18" fxLayoutAlign="end center">&nbsp;</th>
        <td mat-cell *matCellDef="let document;" fxFlex="18" fxLayoutAlign="end center">
          <mat-icon data-testid="user_documents_list_cert_delete" color="primary" 
            (click)="deleteFile(document)">delete</mat-icon>
        </td>
      </ng-container>    
      <tr mat-header-row *matHeaderRowDef="displayedDocumentColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDocumentColumns;"></tr>
    </table>
  </div>    
</div>