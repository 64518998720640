import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/components/internationalization/localization.service';
import { User, UserDocument } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { QnAQuestionAttachmentModel } from 'src/app/models/inductionqna.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-cityonsite-attachment-from-profile',
  templateUrl: './cityonsite-attachment-from-profile.component.html',
  styleUrls: ['./cityonsite-attachment-from-profile.component.scss']
})
export class CityonsiteAttachmentFromProfileComponent implements OnInit {
  dataSource : MatTableDataSource<UserDocument> = new MatTableDataSource<UserDocument>([]);
  displayedDocumentColumns: string[] = ['select', 'documentName', 'documentType'];
  displayDocumentHeader: string | undefined;
  displayDocumentTypeHeader : string | undefined;
  currentUser: User | undefined;
  selection = new SelectionModel<UserDocument>(true, []);
  questionAttachments : QnAQuestionAttachmentModel[] = [];
  profile: string = "PROFILE";

  constructor(private _dialogRef: MatDialogRef<CityonsiteAttachmentFromProfileComponent>,
    private _localizationService : LocalizationService,
    private _authService : AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    this._authService.getUser().then(user => {
      this.currentUser = user;
      this.displayDocumentTypeHeader = this._localizationService.translate("cityonsiteattachment_addfromprofile_table_typeheader");
      if (this.currentUser?.userDocuments && this.currentUser.userDocuments.length > 0){
        this.displayDocumentHeader = this._localizationService.translate("cityonsiteattachment_addfromprofile_table_header");
        this.dataSource.data = this.currentUser.userDocuments;
      }
      else{
        this.displayDocumentHeader = this._localizationService.translate("cityonsiteattachment_addfromprofile_table_header_nodocument");
      }
    });
  }

  ngOnInit(): void {
    if(this.data.question.questionAttachments !== undefined && this.data.question.questionAttachments !== null){
      this.questionAttachments = this.data.question.questionAttachments;
    }
  }

  dismissDialog(): void {
    this._dialogRef.close();
  }

  addSelected(): void {
    if ((this.questionAttachments.length + this.selection.selected.length) > 10){
      alert(this._localizationService.translate("cityonsiteattachment_addfile_invalid_maxnumber"));
      return;
    }
    let isExists = false;
    let msg = "";
    if (this.questionAttachments.length > 0){
      for (let selectedDoc of this.selection.selected) {
        let attachment = this.questionAttachments.find((att : any) => att.fileName.toLowerCase() == selectedDoc.documentName.toLowerCase());
        if (attachment){
          isExists = true;
          msg = this._localizationService.translate('cityonsiteattachment_addfromprofile_invalid_samefile').replace("{filename}", attachment.fileName);
          break;;
        }
      }
    }
    if (isExists){
      alert(msg);
      return;
    }
    this.selection.selected.forEach(selectedDoc => {
      let questionAttachment = {
        activityTaskInstanceJobEventQnAFileId : "",
        fileName : selectedDoc.documentName,
        fileByteArray : "",
        data : undefined,
        fileType : this.profile,
        htmlTag : "",
        fileGuid : selectedDoc.id
      }
      this.questionAttachments.push(questionAttachment);
    });
      
    this.data.question.questionAttachments = this.questionAttachments
    this._dialogRef.close(this.data);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  selectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isSelected(){
    return this.selection.selected.length > 0;
  }
}