import { Injectable } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';
import { ConfigurationService } from '../configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private readonly geolocationInterval = environment.geolocationInterval;
  private latestLocation: Position | null = null;

  constructor(private _configurationService: ConfigurationService) {
    this.trackLocationUpdates();
  }

  async setLatestLocation(): Promise<Position | null> {
    try {
      // Retrieve setting from local settings/API
      let shouldRetrieveFromAPI = await this._configurationService.shouldRetrieveFromAPI();
      let inductionModuleEnable = false;
      if(shouldRetrieveFromAPI){
        await this._configurationService.getAndSetLocalConfigurations(true);
      }
      inductionModuleEnable = await this._configurationService.getInductionModuleEnable();

      if (inductionModuleEnable) {
        this.latestLocation = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
      }
    } catch (error) {
      this.latestLocation = null;
    }
    return this.latestLocation;
  }

  trackLocationUpdates(): void {
    interval(this.geolocationInterval).subscribe(async () => {
      await this.setLatestLocation();
    });
  }

  getLatestLocation(): Position | null {
    return this.latestLocation;
  }
}
