import { Component, OnInit, Input} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {

  @Input('question') question : any;
  textFormControl: UntypedFormControl = new UntypedFormControl('');

  constructor() { }

  ngOnInit(): void {
    if (this.question){
      if (this.question.question){
        if (this.question.question.isRequired && this.question.question.isRequired === true)
        this.textFormControl.addValidators([Validators.required]);
        if (this.question.question.additionalPropertiesParsed){              
          if (this.question.question.additionalPropertiesParsed.MinLength)
            this.textFormControl.addValidators([Validators.minLength(this.question.question.additionalPropertiesParsed.MinLength)]);
          if (this.question.question.additionalPropertiesParsed.MaxLength)
            this.textFormControl.addValidators([Validators.maxLength(this.question.question.additionalPropertiesParsed.MaxLength)]);
        }
      }
      if (this.question.answer === null || this.question.answer === undefined || this.question.answer === "") {
        this.textFormControl.setValue(undefined);
      }
      else{
        this.textFormControl.setValue(this.question.answer);
      }
      this.saveComment(this.question.answer);
    }
  }

  saveComment(value: any){
    if (this.question && this.textFormControl.valid){
      this.question.answer = value;
      this.question.isMissing = false;
    }
    else{
      this.question.isMissing = true;
    }
  }

}