import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-closedown-iframe',
  templateUrl: './closedown-iframe.component.html',
  styleUrls: ['./closedown-iframe.component.scss']
})
export class ClosedownIframeComponent implements OnInit {
  url = 'https://closedown-dev.cityfm.com.au/';
  constructor() { }

  ngOnInit(): void {
  }

}
