
<mat-expansion-panel [expanded]="true">

    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" class="expansion-header">
        <mat-panel-title class="name" [ngStyle]="captionStyles()" fxFlex="100" > {{question.Caption}}</mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="100">
            <div *ngFor="let question of runningQuestions" >  
                <ng-form name="templateFieldForm">
                    <app-permit-question-render [question]="question" [hasContentError]="question.HasContentError"></app-permit-question-render>
                </ng-form>
            </div>
        </div>
    </div>
</mat-expansion-panel>


