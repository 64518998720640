<div class="dialog-container">
    <label class="addInductionLabel" id="deleteInductionLabel">
        {{ ('site_induction_delete_header' | translate) }}
    </label>
    <div>{{siteInductionInfo?.brandName}} #{{siteInductionInfo?.inductionId}}</div>
    <div class="divider"></div>
    <button type="submit" id="delete_induction" mat-raised-button (click)="deleteInduction()">
        {{'site_induction_delete_submit' | translate }}
    </button>
    <div class="divider"></div>
    <button mat-stroked-button class="color-city-blue btn-border-color" (click)="dismissDialog()">
        {{ 'site_induction_delete_cancel' | translate }}
    </button>   
</div>