<div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
  <label class="signatureLabel"  id="{{question.uniqueHtmlIdentifier}}">{{question.question.caption}}
    <mat-label *ngIf="question.question.isRequired" class="color-city-red" > *</mat-label>
  </label>
  <div class="signaturewrapper" #signatureWrapper>
    <div>
      <signature-pad #signature (window:resize)="resizeSignaturePad()" [options]="signaturePadOptions" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)" ></signature-pad>
    </div>
 </div>

  <div>
    <button mat-raised-button (click)="clearSignature()" >
      {{'signature_lbl_clear' | translate }} 
    </button>
  </div>
</div>