<div class="html-container">
  <mat-form-field appearance="outline" class="textWrapper">
    <mat-label class="textLabel">{{question.question.caption}}</mat-label>
    <div *ngIf="!question.question.additionalPropertiesParsed.IsMultiLine; else multiLineComment">
      <input matInput 
        placeholder={{question.question.additionalPropertiesParsed.PlaceHolder}} 
        [formControl]="textFormControl"        
        (change)="saveComment(textFormControl.value)"> 
    </div>
    <ng-template #multiLineComment>
      <textarea matInput 
        placeholder={{question.question.additionalPropertiesParsed.PlaceHolder}} 
        [formControl]="textFormControl"
        (change)="saveComment(textFormControl.value)">
      </textarea>
    </ng-template>
    <mat-error *ngIf="textFormControl.hasError('minlength')">
      {{ ('induction_error_comment_min_length' | translate).replace("{minLength}", this.question.question.additionalPropertiesParsed.MinLength) }}
    </mat-error>
    <mat-error *ngIf="textFormControl.hasError('maxlength')">
      {{ ('induction_error_comment_max_length' | translate).replace("{maxLength}", this.question.question.additionalPropertiesParsed.MaxLength) }}
    </mat-error>
  </mat-form-field>
</div>