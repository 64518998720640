import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/services/form/form.service';
import { InductionConfig } from 'src/app/models/inductionconfig.model';
import { InductionConfigDetail } from 'src/app/models/inductionconfigdetail.model';
import { LocalizationService } from '../internationalization/localization.service';
import { SiteService } from 'src/app/services/site/site.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { Site } from '@app/models/site.model';
import { ClosedownService } from '@app/services/closedown/closedown.service';
import { ViewportScroller } from '@angular/common';
import { CityComplianceStatus } from '@app/shared/enums';
import { ConfigurationService } from '@app/services/configuration/configuration.service';

@Component({
  selector: 'app-induction',
  templateUrl: './induction.component.html',
  styleUrls: ['./induction.component.scss']
})
export class InductionComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscriberBackBtn : Subject<void> = new Subject<void>();
  site: Site | undefined;
  permit: string[] = [];
  inductionSequence: number = 0;
  inductionTitle: string | undefined;
  inductionSubTitle: string | undefined;
  progressColor: ThemePalette = 'primary';
  progressMode: ProgressSpinnerMode = 'determinate';
  progressValue: number = 0;
  inductionConfig: InductionConfig | undefined;
  inductionConfigDetail: InductionConfigDetail | undefined
  questions: any;
  runningQuestions : any[] = []
  orderNumber: number = 0;
  submitButtonCaption: string | undefined;
  currCompanyName: string = '';
  hasError: boolean = false;
  errorMessage: string | undefined;
  totalSequence: number = 0;
  cityOnSiteAttachmentsType: string = "cityonsiteattachments";
  multipleChoiceType: string = "multiplechoice";
  signatureType: string = "signature";
  radioButtonType: string = "radiobutton";
  inputTextType: string = "text";

  @ViewChild("topPosition") topPosition: any;
    
  constructor(private _localizationService : LocalizationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _formService: FormService,
    private _siteService: SiteService,
    private _closedownService: ClosedownService,
    private _navigationService: NavigationService,
    private _viewportScroller: ViewportScroller,
    private _configurationService: ConfigurationService
  ){ 
    this._navigationService.onChange(false, false);
    this.initBrowserBackButton();
  }

  ngAfterViewInit(): void {
    let coords = this.topPosition.nativeElement.getBoundingClientRect();
    if (coords != null) this._viewportScroller.scrollToPosition(coords);
  }

  ngOnInit(): void {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.inductionTitle = '';
    this.inductionSubTitle = '';
    this.progressValue = 0;
    this.hasError = false;
    this.errorMessage = "";
    this._activatedRoute.queryParams.subscribe(params => {
      if (isNaN(params['inductionSequence']) || !Number.isInteger(Number(params['inductionSequence']))) {
        this._router.navigate(['signin']);
      } else {
        this.inductionSequence = Number(params['inductionSequence']);
        this.currCompanyName = (params['paramCurrCompanyName']);
        this.getPermitData();
        this.getSiteData();
      }
    });     
  }

  private getSiteData() {
    return this._siteService.getSignedInSite().then(site => {
      if (site) {
        this.site = site;
        this.getInductionFormData();
      } else {
        this._router.navigate(['signin']);
      }
    },
    _ => {
      alert("Failed to get site info.");
      this._router.navigate(['signin']);
    });
  }

  private getPermitData() {
    return this._configurationService.getPermitKey().then(permit => {
      if (permit) {
        this.permit = permit;
      } 
    },
    _ => {
      alert("Failed to get permit info.");
    });
  }

  private getInductionFormData(): Promise<void> {
    return this._formService.getInductionConfig().then(form => {
      if (form) {
        this.inductionConfig = form;
        this.isInductionSequenceAllowed();  
        if(this.inductionSequence == this.inductionConfig.inductionConfigDetails.length){
          this.submitButtonCaption = this._localizationService.translate('induction_lbl_complete');
        // }else if(this.inductionSequence == 2){
        //   this.submitButtonCaption = this._localizationService.translate('induction_lbl_i_understand');
        }else{
          this.submitButtonCaption = this._localizationService.translate('induction_lbl_continue');          
        }
        this.processInductionForm();
      } else {
        this.hasError = true;
        this.errorMessage = "Site Induction not configured. Please contact administrator.";      
      }
    },
    _ => {
      alert("Failed to get induction form info");
      this._router.navigate(['signin']);
    });
  }

  private traverseToFirst(): any {
    for (var i = 0; i < this.questions.length; i++) {
      var currentQues = this.questions[i];
      if (currentQues.question.isSystemType && currentQues.question.questionType.name === 'startSysCtrl') {
        return currentQues;
      }
    }
  };

  private traverseToId(id: number): void {
    var questionsBank = this.questions;
    for (var i = 0; i < questionsBank.length; i++) {
      if (questionsBank[i].id === id) {
        var runningCopyQuestion = questionsBank[i];
        runningCopyQuestion.orderNumber = this.orderNumber++;
        this.createUniqueHtmlIdenfitifer(runningCopyQuestion);
        this.deserializeNewRunningCopyQuestionAdditionalProperties(runningCopyQuestion);
        this.runningQuestions.push(runningCopyQuestion);
      }
    }
  };

  private createUniqueHtmlIdenfitifer(runningCopyQuestion: any) {
    var basicUhi = 'q' + runningCopyQuestion.id + 'rnullinull';
    runningCopyQuestion.uniqueHtmlIdentifier = basicUhi;
  }

  private deserializeNewRunningCopyQuestionAdditionalProperties(runningCopyQuestion: any) {
    this.deserializeQuestionAdditionalProperties(runningCopyQuestion);
  }

  private deserializeQuestionAdditionalProperties(runningCopyQuestion: any) {
    
    if (runningCopyQuestion.question.additionalProperties !== null 
      && runningCopyQuestion.question.additionalProperties !== undefined) {      
      runningCopyQuestion.question.additionalPropertiesParsed 
        = JSON.parse(runningCopyQuestion.question.additionalProperties);      
      if (runningCopyQuestion.question.additionalPropertiesParsed.hasOwnProperty('Options')) {
        if (runningCopyQuestion.question.additionalPropertiesParsed.Options !== null 
          && runningCopyQuestion.question.additionalPropertiesParsed.Options !== undefined
          && !Array.isArray(runningCopyQuestion.question.additionalPropertiesParsed.Options))
          runningCopyQuestion.question.additionalPropertiesParsed.Options 
            = runningCopyQuestion.question.additionalPropertiesParsed.Options.split(" | ");
      }
      if (runningCopyQuestion.question.additionalPropertiesParsed.hasOwnProperty('OptionsColour')) {
        if (runningCopyQuestion.question.additionalPropertiesParsed.OptionsColour !== null 
          && runningCopyQuestion.question.additionalPropertiesParsed.OptionsColour !== undefined
          && !Array.isArray(runningCopyQuestion.question.additionalPropertiesParsed.OptionsColour)) {
          runningCopyQuestion.question.additionalPropertiesParsed.OptionsColour 
            = runningCopyQuestion.question.additionalPropertiesParsed.OptionsColour.split(" | ");
          runningCopyQuestion.question.additionalPropertiesParsed.OptionsColourStyle = [];

          for (var color in runningCopyQuestion.question.additionalPropertiesParsed.OptionsColour) {
            runningCopyQuestion.question.additionalPropertiesParsed.OptionsColourStyle
              .push({ 'border-color': runningCopyQuestion.question.additionalPropertiesParsed.OptionsColour[color] });
          }
        }
      }
      if (runningCopyQuestion.question.additionalPropertiesParsed.hasOwnProperty('OptionsDescription')) {
        if (runningCopyQuestion.question.additionalPropertiesParsed.OptionsDescription !== null 
          && runningCopyQuestion.question.additionalPropertiesParsed.OptionsDescription !== undefined
          && !Array.isArray(runningCopyQuestion.question.additionalProperties.OptionsDescription)) {
          runningCopyQuestion.question.additionalPropertiesParsed.OptionsDescription 
            = runningCopyQuestion.question.additionalPropertiesParsed.OptionsDescription.split(" | ");
        }
      }
      if (runningCopyQuestion.question.additionalPropertiesParsed.hasOwnProperty('Info')) {
        try {
          runningCopyQuestion.question.additionalPropertiesParsed.Info 
            = runningCopyQuestion.question.additionalPropertiesParsed.Info;
        } catch (e) {
          console.info('Question info replaced');
          runningCopyQuestion.question.additionalPropertiesParsed.Info = '<p>Failed to load info</p>';
        }
      }
      if (runningCopyQuestion.question.additionalPropertiesParsed.hasOwnProperty('IsMultiLine')) {
        runningCopyQuestion.question.additionalPropertiesParsed.IsMultiLine 
          = runningCopyQuestion.question.additionalPropertiesParsed.IsMultiLine;
      }
      if (runningCopyQuestion.question.additionalPropertiesParsed.hasOwnProperty('PlaceHolder')) {
        runningCopyQuestion.question.additionalPropertiesParsed.PlaceHolder 
          = runningCopyQuestion.question.additionalPropertiesParsed.PlaceHolder;
      }
      if (runningCopyQuestion.question.additionalPropertiesParsed.hasOwnProperty('MaxLength')) {
        runningCopyQuestion.question.additionalPropertiesParsed.MaxLength 
          = runningCopyQuestion.question.additionalPropertiesParsed.MaxLength;
      }
      if (runningCopyQuestion.question.additionalPropertiesParsed.hasOwnProperty('MinLength')) {
        runningCopyQuestion.question.additionalPropertiesParsed.MinLength 
          = runningCopyQuestion.question.additionalPropertiesParsed.MinLength;
      }
    }
  }

  private showQuestionsUpUntilDecision(fromQuestion: any): void {
    var questionsBank = this.questions;
    var nextId = fromQuestion.nextID;

    if (nextId === null || typeof nextId === 'undefined') {
      return;
    }

    var nextQuestion = null;

    for (var i = 0; i < questionsBank.length; i++) {
      if (questionsBank[i].id === nextId) {
        nextQuestion = questionsBank[i];
        break;
      }
    }

    if (nextQuestion !== null) {
      this.traverseToId(nextQuestion.id);
      this.showQuestionsUpUntilDecision(nextQuestion);
    }
  };

  private processInductionForm(): void {
    if (this.inductionConfig) {
      this.totalSequence = this.inductionConfig.inductionConfigDetails.length;
      this.inductionConfigDetail = this.inductionConfig.inductionConfigDetails
                                    .find(detail => detail.sequence == this.inductionSequence);

      this.inductionTitle = (this.inductionConfigDetail?.siteRequired) ?  this.inductionConfigDetail?.name + " " + this.site?.name : this.inductionConfigDetail?.name;

      if (this.inductionSequence < this.inductionConfig.inductionConfigDetails.length) {
        let nextIndcutionConfigDetail =  this.inductionConfig.inductionConfigDetails
                                          .find(detail => detail.sequence == this.inductionSequence + 1);
  
        this.inductionSubTitle = (nextIndcutionConfigDetail?.siteRequired) ? nextIndcutionConfigDetail?.name + " " + this.site?.name : nextIndcutionConfigDetail?.name;

        let incrementValue = 100 / this.inductionConfig.inductionConfigDetails.length;
        this.progressValue = this.inductionSequence * incrementValue;
      }
      else {
        this.inductionSubTitle = '';
        this.progressValue = 100;
      } 
      this.questions = this.inductionConfig.inductionConfigDetails
        .find(detail => detail.sequence == this.inductionSequence)?.questions;
      if (this.questions) {
        var firstQuestion = this.traverseToFirst();
        this.showQuestionsUpUntilDecision(firstQuestion);
      }
    }
  }
  
  isCityComplianceCheck(inductionSequence: number) {
    let result = false;
    if (this.site?.isComplianceCheckEnable === true) {
      let inductionDetail = this.inductionConfig?.inductionConfigDetails.find(x => x.sequence == inductionSequence);
      if (inductionDetail?.answer) {
        let answerParsed = JSON.parse(inductionDetail.answer);
        let isRapidGlobalId = answerParsed.rapidGlobalId == null ? false : answerParsed.rapidGlobalId.length > 0;
        let isCompanyName = answerParsed.companyName == null ? false : answerParsed.companyName.length > 0;
        let isCompanyId = answerParsed.companyId == null ? false : answerParsed.companyId.length > 0;
        let statusId = answerParsed.statusId;

        if (isCompanyId) {
          result = !(isRapidGlobalId && isCompanyName && (statusId == CityComplianceStatus.COMPLIANT || statusId == CityComplianceStatus.NOTAPPLICABLE));
        } else {
          result = !(isCompanyName);
        }
      } else {
        result = true;
      }
    }
    return result;
  }

  /* NOTE: If the switch case conditions are updated, 
  please update isInductionSequenceAllowed function */
  continueOnClick() {
    if (this.inductionConfig){
      this.hasError = false;
      this.errorMessage = "";
      let inductionDetail = this.inductionConfig.inductionConfigDetails.find(x => x.sequence == this.inductionSequence);
      switch (inductionDetail?.type.toLowerCase()){
        case "reasonforvisit" : {
          if (!inductionDetail.answer || inductionDetail.answer.length == 0){
            this.hasError = true;
            this.errorMessage = this._localizationService.translate('induction_error_reasonforvisit_selectonce');
          }
          break;
        }
        case "compliancecheck" : {
          if (this.isCityComplianceCheck(this.inductionSequence) === true){
            this.hasError = true;
            this.errorMessage = this._localizationService.translate('induction_error_compliancecheck_companyrequired');
          }
          break;
        }        
        default :{
          let questions  = inductionDetail?.questions;
          let isNewPage = true;
          if (questions){
            for (let question of questions){
              if (question.question.isRequired || question.isMissing){
                switch (question.question.questionType.name.toLowerCase()){
                  case this.cityOnSiteAttachmentsType:
                    if (question.question.questionAttachments == undefined || question.question.questionAttachments.length == 0){
                      this.hasError = true;
                      this.errorMessage = this._localizationService.translate('induction_error_attachmentrequired').replace("{type}", question.question.caption);
                    } 
                    break;
                  case this.radioButtonType:

                    if(question.question.caption == this.permit[0]){
                      if(this.site?.isPermitRequired){
                        if (question.answer == undefined || question.answer.trim() == ""){
                          this.hasError = true;
                          this.errorMessage = this._localizationService.translate('induction_error_inputrequired');
                          this._formService.removePermitQnA().then(f => {});
                        }else{
                          this._formService.setPermitQnA(question).then(f => {});
                        }
                      }

                    }else{
                      if (question.answer == undefined || question.answer.trim() == ""){
                        this.hasError = true;
                        this.errorMessage = this._localizationService.translate('induction_error_inputrequired');
                      }
                    }

                    break;
                  case this.signatureType:
                    if (question.answer == undefined || question.answer.trim() == ""){
                      this.hasError = true;
                      this.errorMessage = this._localizationService.translate('induction_error_signaturerequired');
                    } 
                    break;
                  case this.inputTextType:
                    if (question.answer == undefined || question.answer.trim() == "" || question.isMissing){
                      this.hasError = true;
                      this.errorMessage = this._localizationService.translate('induction_error_inputrequired');
                    } 
                    break;
                  default:
                    break;
                }

                if(this.hasError)
                  break;
              }

              //update user doc id that in use, can be duplicate, on sign in completed, remove duplicate
              if (question.question.questionType.name.toLowerCase() === this.cityOnSiteAttachmentsType){
                if (question.question.questionAttachments && question.question.questionAttachments.length > 0)
                {
                  question.question.questionAttachments.forEach(attachment => {
                    if(inductionDetail && attachment && attachment.fileGuid && attachment.fileGuid.length > 0){
                      if(isNewPage){
                        inductionDetail.userDocumentIds = [];
                        isNewPage = false;
                      }
                      inductionDetail.userDocumentIds.push(attachment.fileGuid);
                    }
                  });
                }
              }

            }
          }
        }
      }

      if (this.hasError){
        return;
      }

      //save answer to local storage
      this._formService.setInductionConfig(this.inductionConfig);

      if (this.inductionSequence < this.inductionConfig.inductionConfigDetails.length) {        
          this._router.navigate(['induction'], {
            queryParams: {
              inductionSequence: Number(this.inductionSequence + 1),
              paramCurrCompanyName: this.currCompanyName
            },
            replaceUrl: true
          });
          history.pushState(null, location.href, location.href);
      }
      else {
        if(confirm(this._localizationService.translate("induction_lbl_confirm_induction"))){
          this._closedownService.setInductionFirstRedirect('true');
          this._router.navigate(['siteinductioncompleted']);
        }
      }
    }
  }

  back(){
    if (this.inductionSequence == 1){
      if (this.inductionConfig?.visitorId){
        this._router.navigate(['signedinvisitor']);
      }
      else{
        if(confirm(this._localizationService.translate("signedin_lbl_leave_store"))){
          this._siteService.removeVisitReasons().then(vr => {
            this._closedownService.removeProcessFlow().then(pf => {
              this._formService.removePermitQnA().then(p =>{
                this._formService.removeInductionConfig().then(f =>{
                  this._siteService.removeSignedInSite().then(r => {
                    this.currCompanyName = "";
                    this._router.navigate(['signin']);
                  });
                });
              });
            });
          });
        }
      }

    }
    else {
      if (this.inductionConfig){
              this._formService.setInductionConfig(this.inductionConfig);
      }
      this._router.navigate(['induction'], {
        queryParams: {
          inductionSequence: Number(this.inductionSequence - 1),
          paramCurrCompanyName: this.currCompanyName
        },
        replaceUrl: true
      });
    }
  }

  initBrowserBackButton(){
    fromEvent(window, 'popstate').pipe(takeUntil(this.unsubscriberBackBtn)).subscribe((_) => {
      window.location.reload();
      this.back();
    });
  }

  ngOnDestroy():void{
      this.unsubscriberBackBtn.next();
      this.unsubscriberBackBtn.complete();
  }

  isInductionSequenceAllowed() {
    if (this.inductionConfig) {
      let currentInductionSequence = this.inductionConfig.inductionConfigDetails.length;
      let isIncompleteInductionStepFound = false;
      if (this.inductionSequence > this.inductionConfig.inductionConfigDetails.length || this.inductionSequence < 1) {
        this._router.navigate(['signin']);
      }
      this.inductionConfig.inductionConfigDetails.sort((a, b) => a.sequence - b.sequence);
      this.inductionConfig.inductionConfigDetails.some(inductionDetail => {
        switch (inductionDetail?.type.toLowerCase()) {
          case "reasonforvisit": {
            if (!inductionDetail.answer || inductionDetail.answer.length == 0) {
              currentInductionSequence = inductionDetail.sequence;
              isIncompleteInductionStepFound = true;
            }
            break;
          }
          case "compliancecheck": {
            if (this.isCityComplianceCheck(inductionDetail.sequence) === true) {
              currentInductionSequence = inductionDetail.sequence;
              isIncompleteInductionStepFound = true;
            }
            break;
          }
          default: {
            let questions = inductionDetail?.questions;
            if (questions) {
              for (let question of questions) {
                if (question.question.isRequired || question.isMissing) {
                  switch (question.question.questionType.name.toLowerCase())
                  {
                    case this.cityOnSiteAttachmentsType:
                      if (question.question.questionAttachments == undefined || question.question.questionAttachments.length == 0){
                        currentInductionSequence = inductionDetail.sequence;
                        isIncompleteInductionStepFound = true;
                      } 
                      break;
                    case this.radioButtonType:

                      if(question.question.caption == this.permit[0]){
                        if(this.site?.isPermitRequired){
                          if (question.answer == undefined || question.answer.trim() == ""){
                            currentInductionSequence = inductionDetail.sequence;
                            isIncompleteInductionStepFound = true;
                          }
                        }

                      }else{
                        if (question.answer == undefined || question.answer.trim() == ""){
                          currentInductionSequence = inductionDetail.sequence;
                          isIncompleteInductionStepFound = true;
                        }
                      }

                      break;
                    case this.signatureType:
                      if (question.answer == undefined || question.answer.trim() == ""){
                        currentInductionSequence = inductionDetail.sequence;
                        isIncompleteInductionStepFound = true;
                      } 
                      break;
                    case this.inputTextType:
                      if (question.answer == undefined || question.answer.trim() == "" || question.isMissing){
                        currentInductionSequence = inductionDetail.sequence;
                        isIncompleteInductionStepFound = true;
                      } 
                      break;
                    default:
                      break;                  
                  }

                  if(this.hasError)
                    break;
                }
              }
            }
          }
        }
        
        if (isIncompleteInductionStepFound) {
          return true;
        }
        return false;
      });
      
      if (this.inductionSequence > currentInductionSequence) {
        this._router.navigate(['induction'], {
          queryParams: {
            inductionSequence: Number(currentInductionSequence),
            paramCurrCompanyName: this.currCompanyName
          },
          replaceUrl: true
        });
      }
    } else {
      this._router.navigate(['signin']);
    }
  }

  /**
   * Helper method that sets hasError status when value is emitted from any child component.
   * @param showComplianceError 
   * Boolean value indicating whether or not to display compliance error.
   */
  updateHasError(showComplianceError: boolean){
    this.hasError = showComplianceError;
  }
}