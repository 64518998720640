<div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
    <mat-label class="signatureLabel" [ngStyle]="captionStyles()" id="{{question.Id}}">{{question.Caption}}
      <mat-label *ngIf="question.IsRequired" class="color-city-red" > *</mat-label>
    </mat-label>
    <div class="signaturewrapper" #signatureWrapper>
      <div>
        <signature-pad [ngStyle]="questionDetailsStyles()" #signature (window:resize)="resizeSignaturePad()" [options]="signaturePadOptions" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)" ></signature-pad>
      </div>
    </div>
    <mat-error *ngIf="question.IsRequired && !question.Answer && answerTouched">
      {{isRequiredMessageDisplay}}
    </mat-error>
    <div>
      <button mat-raised-button (click)="clearSignature()" >
        {{'permit_signature_label_clear' | translate }} 
      </button>
    </div>
  </div>
