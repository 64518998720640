import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { catchError, Observable, tap } from 'rxjs';
import { Job, PickupJob } from 'src/app/models/job.model';
import { StorageKey } from 'src/app/shared/enums';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base/base.service';

@Injectable({
    providedIn: 'root'
})
export class JobService extends BaseService {
    readonly KEY = StorageKey[StorageKey.JOB];
    readonly BASE_URL = `${environment.gatewayAPI_V1}/Jobs/`;
    //readonly BASE_URL = `https://localhost:44325/api/v1/`;

    constructor(private httpClient: HttpClient) {
        super();
    }

    public getJobExistsByCompanyRefNumberNEmail(emailAddress: string, companyRefNumbers: string[]): Observable<string> {
        const url = `${this.BASE_URL}Jobs/getJobExistsByCompanyRefNumberNEmail`;
        let params = new HttpParams();

        params = params.append('userEmail', emailAddress);

        companyRefNumbers.forEach(refNumber => {
            params = params.append('companyRefNumbers', refNumber);
        })

        return this.httpClient.get<string>(url, { params: params }).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getJobsBySelectedReasons(reasons: string[]): Observable<Job[]> {
        const url = `${this.BASE_URL}Jobs/GetJobsBySelectedReasons`;
        let params = new HttpParams();

        reasons.forEach(refNumber => {
            params = params.append('reasons', refNumber);
        })

        return this.httpClient.get<Job[]>(url, { params: params }).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getJobsByCompanyRefNumberNEmail(companyRefNumbers: string[], siteId: string | undefined): Observable<Job[]> {
        const url = `${this.BASE_URL}Jobs/getJobsByCompanyRefNumberNEmail`;
        let params = new HttpParams();

        companyRefNumbers.forEach(refNumber => {
            params = params.append('companyRefNumbers', refNumber);
        })

        if (siteId != null && siteId != undefined)
            params = params.append('siteId', siteId);

        return this.httpClient.get<Job[]>(url, { params: params }).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getJobsByCompanyRefNumber(siteId: string | undefined): Observable<Job[]> {
        const url = `${this.BASE_URL}Jobs/getJobsByCompanyRefNumber`;
        let params = new HttpParams();

        if (siteId != null && siteId != undefined)
            params = params.append('siteId', siteId);

        return this.httpClient.get<Job[]>(url, { params: params }).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getJobsByCompanyRefNumberWithSkipHeader(siteId: string | undefined): Observable<Job[]> {
        const url = `${this.BASE_URL}Jobs/getJobsByCompanyRefNumber`;
        let params = new HttpParams();

        if (siteId != null && siteId != undefined)
            params = params.append('siteId', siteId);

        // Add skip header to hide loading spinner
        return this.httpClient.get<Job[]>(url, { params: params, headers:{skip:"true"} }).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );
    }

    public getJobsByAllocatedEmail(allocatedEmail: string, siteId: string | undefined): Observable<Job[]> {
        const url = `${this.BASE_URL}Jobs/GetJobsByAllocatedEmail`;
        let params = new HttpParams();

        params = params.append('allocatedEmail', allocatedEmail);

        if (siteId && siteId != undefined)
            params = params.append('siteId', siteId);

        return this.httpClient.get<Job[]>(url, { params: params }).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );

    }

    public getJobsByJobNSubContractorId(jobId: string, subContractorId: string): Observable<Job[]> {
        const url = `${this.BASE_URL}Jobs/GetJobsByJobNSubContractorId`;
        let params = new HttpParams();

        params = params.append('jobId', jobId);
        params = params.append('subContractorId', subContractorId);

        return this.httpClient.get<Job[]>(url, { params: params }).pipe(
            tap(_ => _),
            catchError(this.handleError)
        );

    }

    public pickupJob(pickupJob: PickupJob): Observable<boolean> {
        const url = `${this.BASE_URL}Jobs/PickupJob`;
        //const url = `https://localhost:7062/v1/gateway/Jobs/Jobs/PickupJob`;

        return this.httpClient.post<boolean>(url, pickupJob).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    async getSignedInJob(): Promise<Job> {
        const item = await Preferences.get({ key: this.KEY });
        var obj;
        if (item.value != null)
            obj = JSON.parse(item.value);

        return obj;
    }

    async setSignedInJob(job: Job) {
        await Preferences.set({
            key: this.KEY,
            value: JSON.stringify(job),
        });
    }

    async removeSignedInJob() {
        await Preferences.remove({ key: StorageKey[this.KEY] });
    }
}