<div class="main-content">
  <div class="document-header">  
    <h1>{{('user_documents_header' | translate)}}</h1>  
  </div>  
  <app-user-documents-list></app-user-documents-list>  
  <div class="close-button">
    <button type="submit" mat-raised-button 
      id="userprofile_close"
      data-testid="userprofile_close"
      (click)="closeUserDocuments()">
      {{'userprofile_close' | translate }}
    </button>
  </div>
</div>