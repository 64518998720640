import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileStorage, FileStorageSetting, UploadFileStorage } from '../../models/filestorage.model';
import { BaseService } from '../base/base.service';

@Injectable({
  providedIn: 'root'
})
export class FileStorageService extends BaseService {
  //readonly BASE_URL = `https://localhost:7062/v1/gateway/filestorages/`;
  readonly BASE_URL = `${environment.gatewayAPI_V1}/filestorages/`;
  constructor(private httpClient: HttpClient) {
    super();
  }

  public UploadDocument(uploadFile : UploadFileStorage): Observable<FileStorage> {
    const url = `${this.BASE_URL}filestorage/PostDocument`;
    return this.httpClient.post<FileStorage>(url, JSON.stringify(uploadFile)).pipe(
      tap(_ => _),
      catchError(this.handleError));      
  }

  public RemoveDocument(id: string | undefined) {
    const url = `${this.BASE_URL}filestorage/${id}`;    
    return this.httpClient.delete(url).pipe(
      tap(_ => _),
      catchError(this.handleError));  
  }

  public UploadImage(uploadFile : UploadFileStorage): Observable<FileStorage> {
    const url = `${this.BASE_URL}filestorage/PostImage`;
    return this.httpClient.post<FileStorage>(url, JSON.stringify(uploadFile)).pipe(
      tap(_ => _),
      catchError(this.handleError));      
  }

  public download(id: string): Observable<UploadFileStorage> {
    const url = `${this.BASE_URL}FileStorage/DownloadByFileStorageId?id=${id}`;
    return this.httpClient.get<UploadFileStorage>(url).pipe(
      tap(_ => _),
      catchError(this.handleError));
  }

  public GetFileStorageSettings(): Observable<FileStorageSetting> {
    const url = `${this.BASE_URL}FileStorage/GetFileStorageSettings`;
    return this.httpClient.get<FileStorageSetting>(url).pipe(
      tap(_ => _),
      catchError(this.handleError));
  }

}