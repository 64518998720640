import { Component, DoCheck, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { LocalizationService } from '@app/components/internationalization/localization.service';
import { PermitService } from '@app/services/permit/permit-service';

@Component({
  selector: 'app-permit-text-box',
  templateUrl: './permit-text-box.component.html',
  styleUrls: ['./permit-text-box.component.scss']
})
export class PermitTextBoxComponent implements OnChanges  {
  @Input() question : any;
  textFormControl: UntypedFormControl = new UntypedFormControl('');
  @Input() hasContentError: boolean = false;
  isRequiredMessageDisplay: string = '';

  constructor(private _permitService: PermitService){
  }

  ngOnInit(): void {
    if(this.question.Answer){
      this.textFormControl.setValue(this.question.Answer);
    }
    
    if(this.question.IsRequired)
    {
      this.isRequiredMessageDisplay = this._permitService
        .determineIsRequiredMessage(this.question.IsRequiredMessage);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.question.HasContentError && this.question.IsRequired && !this.question.Answer){
      this.textFormControl.markAsTouched();
    }
  }

  captionStyles() : object {
    return JSON.parse(this.question.CaptionStyles);
  }

  questionDetailsStyles() : object {
    return JSON.parse(this.question.QuestionDetailsStyles);
  }

  saveComment(value: any){
    if (this.question && value !== ''){
      this.question.Answer = value;
    }
    else{
      this.question.Answer = null;
    }
  }
}
