export interface InductionQnA {
    id : number;
    loopIndex : number;
    parentKey : number;
    groupIndex : number;
    question: QnAQuestionModel;
    answer : string;
    answerMulti : [][];
    subProcessFlowStatus : SubProcessFlowStatus;
    subProcessFlowStatusMulti : [][];
    orderNumber : number;
    orderNumberMulti : [][];
    numericAnswer : number;
    nextID : number;
    loopTemplate : InductionQnA[];
    isMissing : boolean;
    rules : QnARule[];
    qnASequenceId : number;
    activityTaskInstanceJobEventQnAId : string;
    loopAdditionalProperties : QnALoopAdditionalPropertiesModel;
    groupAdditionalProperties : QnAGroupAdditionalPropertiesModel;
    subprocessFlowAdditionalProperties : QnASubprocessFlowAdditionalPropertiesModel; 
    groupTemplate : InductionQnA[];
    metaTagAdditionalProperties : QnAMetaTagAdditionalPropertiesModel;
    uniqueHtmlIdentifier : string;
    loopRuns : LoopRun[];
    groupChildren : GroupChild[];
    subprocessFlow : InductionQnA[];
}

export interface QnARule
{
    operator : string;
    value : string;
    nextID : number
}

export interface LoopRun
{
    iteration : number;
    questions : InductionQnA[];
}

export interface QnAQuestionModel
{
    questionId : string;
    caption : string;
    hashtag : string;
    questionType : QnAQuestionTypeModel;
    isRequired : boolean;
    isSystemType : boolean;
    additionalProperties : any;
    answer : string;
    selectedAnswer : string;
    questionAttachments : QnAQuestionAttachmentModel[];
    description : string;
    metaTag : string;
    additionalPropertiesParsed : any;   
}

export interface QnAQuestionAttachmentModel
{
    activityTaskInstanceJobEventQnAFileId : string;
    fileName : string
    fileByteArray : string;
    data : any[] | undefined;
    fileType : string;
    htmlTag : string;
    fileGuid : string | undefined;
}

export interface QnAQuestionTypeModel
{
    questionTypeId : string;
    name : string;
    description : string;
    version : string;
    deleted : boolean;
    referenceNumber : number;
}

export interface QnALoopAdditionalPropertiesModel
{
    loopTitle : string;
    questionTitle : string;
    loopCount : string;
}

export interface GroupChild
{
    groupChildNumber : number;
    groupText : string;
    questions : InductionQnA[];
}

export interface QnAGroupAdditionalPropertiesModel
{
    groupTitle : string
    groupItems : string[];
}

export interface QnASubprocessFlowAdditionalPropertiesModel
{
    processFlowId : string;
    processFlowTitle : string;
}

export interface SubProcessFlowStatus
{
    subProcessFlowKey : string;
}

export interface QnAMetaTagAdditionalPropertiesModel
{
    metaTagName : string;
    containsValue : string;
}

export class ComplianceCheckAnswerModel
{
    companyId : string = "";
    companyName : string = "";
    rapidGlobalId : string = "";
    inductionNo : string = "";
    rapidGlobalGuid : string = "";
    inductionGuid : string = "";
    statusId : number = -1;
}