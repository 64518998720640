import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { DeviceDetectorService } from 'ngx-device-detector';

const piexif = require('src/assets/scripts/piexif.js');

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  constructor(private _deviceService: DeviceDetectorService) {
  }

  // Mobile app and mobile browser
  isMobileDevice() {
    return this._deviceService.isMobile() || this._deviceService.isTablet();
  } 

  // Mobile app only
  isMobileAndTablet(){
    return Capacitor.isNativePlatform();
  } 

  // Web only
  isWebBrowser(){
    return !Capacitor.isNativePlatform();
  }

  isUndefinedOrNullOrEmpty(value: string): boolean {
    return value == undefined || value == null || value.trim().length <= 0;
  }

  isUndefinedOrNull(value: any): boolean {
    return value == undefined || value == null;
  }

  isAndroid(): boolean {
    const deviceOS = this._deviceService.getDeviceInfo().os.toLowerCase();
    return deviceOS.toLowerCase() == 'android';
  }

  isIOS(): boolean {
    const deviceOS = this._deviceService.getDeviceInfo().os.toLowerCase();
    return deviceOS.toLowerCase() == 'ios'
  }

  isEdgeBrowser(){
    return (this._deviceService.browser.toLowerCase() == "ms-edge-chromium");
  }

  getDistanceBetweenTwoPointsinKM(lat1: number, long1: number, lat2: number, long2: number) {
    if (lat1 == lat2 && long1 == long2)
      return 0;
  
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
  
    const theta = long1 - long2;
    const radtheta = (Math.PI * theta) / 180;
  
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  
    if (dist > 1) {
      dist = 1;
    }
  
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344; //convert miles to km
    
    return dist;
  }

  checkMimeType(file: File): string | undefined {
    let extension = file.name.substring(file.name.lastIndexOf('.')+1);
    if (extension.toLowerCase() === 'jpeg') extension = 'jpg';
    let fileType = undefined;
    if (file.type && file.type.length > 0) {
      switch (file.type) {
        case "text/plain":
          fileType = "txt";
          break;
        case "application/msword":
          fileType = "doc";
          break;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          fileType = "docx";
          break;
        case "application/pdf":
          fileType = "pdf";
          break;
        case "image/jpeg":
          fileType = "jpg";
          break;
        case "image/png":
          fileType = "png";
          break;
        default:
          fileType = undefined;
      }
      if (!(fileType && extension && fileType.toLowerCase() === extension.toLowerCase()))
        return undefined;
      return fileType;
    } 
    else return undefined;
  }

  getBase64StringFileExtension(dataUrl: string | undefined): string {
    let result = '';
    let fileType = dataUrl?.replace(/^.+,/, '').substring(0, 5).toUpperCase();
    if (fileType && fileType.length > 0) {
      switch (fileType) {
        case "IVBOR":
          return "png";
        case "/9J/4":
          return "jpg";
        /* for possible later usage
        case "AAAAF":
          return "mp4";
        case "JVBER":
          return "pdf";
        case "AAABA":
          return "ico";
        case "UMFYI":
          return "rar";
        case "E1XYD":
          return "rtf";
        case "U1PKC":
          return "txt";
        case "MQOWM":
        case "77U/M":
          return "srt";
        */
        default:
          return result;
      }
    } else return result;    
  }

  removeExifMetadataFromDataUrl(dataUrl: string): string {
    let part1 = dataUrl.split(",")[0];
    let part2 = dataUrl.split(",")[1];
    let decodedData = atob(part2);
    let oldExifObj = piexif.load(decodedData);
    var zeroth = {} as any;
    let orientation = oldExifObj['0th'][piexif.ImageIFD.Orientation];
    zeroth[piexif.ImageIFD.Orientation] = orientation;
    var newExifObj = {"0th":zeroth};
    var newExifStr = piexif.dump(newExifObj);
    piexif.remove(decodedData);
    let updatedData = piexif.insert(newExifStr, decodedData);
    let encodedData = btoa(updatedData);
    return `${part1},${encodedData}`;
  }

  updateFileFormatToAcceptedFormat(...args: string[]): string {
    let finalFormat = '';
    let firstLoop = true;
    args?.forEach(data => {
      if(data){
        let val = data.split(',');
        val.forEach(i => {
          if(i){
            if(firstLoop){
              finalFormat = "." + i;
              firstLoop = false;
            }else{
              finalFormat = finalFormat + ",." + i;
            }
          }
        })
      }
    });
    return finalFormat;
  }

  isNotAndroidOrIOS(): boolean {
    const deviceOS = this._deviceService.getDeviceInfo().os.toLowerCase();
    return deviceOS.toLowerCase() !== 'android' && deviceOS !== 'ios';
  }

  convertKilometersToMeters(kilometers: number): number {
    return kilometers * 1000;
  }

  
}