<div *ngIf="!isJobReason()">
    <div><label class="reasonForVisitLabel" id="reasonForVisitLabel">{{'induction_lbl_reasonforvisit' |
            translate}}</label></div>
    <div class="divider"></div>
    <div fxLayout="row" *ngFor="let reason of siteVisitReasons" fxLayoutAlign="end">
        <div fxLayout="column" fxFlex="95" >
            <li class = "color-city-blue" *ngIf="reason.calloutReferenceNumber != ''"> {{ reason.calloutReferenceNumber }} </li>
        </div>
    </div>
</div>
<div *ngIf="isJobReason()">
    <div *ngIf="jobsAllocated.length > 0">
        <div class="divider"></div>
        <div *ngFor="let job of jobsAllocated">
            <div fxLayout="column" fxFlex="100" [ngClass]="{'disableDiv': nonCityFMWorkChecked}">
                <div fxLayout="row" fxFlex="100">
                    <div fxFlex="10" fxLayoutAlign="center center">
                        <mat-icon class="{{job.imageUrl}}">{{job.imageUrl}}</mat-icon>
                    </div>
                    <div fxFlex="90">
                        <mat-expansion-panel [ngClass]="{'disableDiv': nonCityFMWorkChecked}">
                            <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'"
                                class="expansion-header">
                                <mat-panel-title class="name" fxFlex="100">
                                    <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
                                        <div *ngIf="job.job.subSite != null" class="joblabel">
                                            {{job.job.subSite}}
                                        </div>
                                        <div class="joblabel color-city-neutral">
                                            #{{job.job.referenceNumber}}
                                        </div>
                                        <div class="faultservicelabel color-dark-blue">
                                            {{job.job.faultServiceName}}
                                        </div>

                                        <div *ngIf="job.job.eta != null" fxLayout="row" fxFlex="100">
                                            <div class="job-icon">
                                                <mat-icon class="color-city-blue">today</mat-icon>
                                            </div>
                                            <div *ngIf="isReactiveJob(job.job)" class="color-city-blue eta-lbl-font">
                                                ETA {{job.job.eta | date:'dd-MM-yyyy hh:mm a' }}
                                            </div>
                                            <div *ngIf="isPPMJob(job.job)" class="color-city-blue eta-lbl-font">
                                                Due On {{job.job.eta | date:'dd-MM-yyyy' }}
                                            </div>
                                        </div>
                                    </div>
                                </mat-panel-title>

                                <div fxLayout="row wrap" fxFlex="40" fxLayoutAlign="end center">
                                    <div class="jobstatus color-city-neutral {{job.jobStatus.toLowerCase()}}">
                                       {{job.jobStatus}}
                                    </div>
                                </div>

                            </mat-expansion-panel-header>
                            <div fxLayout="column" fxFlex="100">
                                <div class="expandedheaderlabel color-city-neutral">
                                    {{'common_lbl_department' | translate}}
                                </div>
                                <div class="expandedlabel color-dark-blue">
                                    {{job.job.departmentName}}
                                </div>
                                <div class="expandedheaderlabel color-city-neutral">
                                    {{'common_lbl_notes' | translate}}
                                </div>
                                <div class="expandedlabel color-dark-blue">
                                    {{job.job.notes}}
                                </div>

                            </div>
                        </mat-expansion-panel>
                        <div *ngIf="job.jobStatus == 'Not Updated' && !outOfRange && job.fastLinkEnabled">
                            <button class="fastlinkbutton" fxLayoutAlign="end end"
                                (click)="fastlinkOnClick(job.resourceId, job.job.referenceNumber, job.job.allocatedReferenceId, job.job.calloutReferenceNumber, job.job.jobType)">
                                {{ 'sitesignout_lbl_job_details' | translate }}</button>
                        </div>
                        <div class="divider"></div>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>

        </div>
    </div>
    <div class="divider"></div>

    <div class="divider"></div>
    <div class="divider"></div>

</div>