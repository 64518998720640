const b2cUrl = 'https://cityonsitetst.b2clogin.com/cityonsitetst.onmicrosoft.com';

export const environment = {
  production: true,
  appVersion: "1.1.4",
  maxFileSize: 10,
  maxSWMSFileUploadTotal: 10,
  appEnvironment: 'TST',
  isDemo: false,
  defaultLang: "en-US",
  appName: 'cityonsite',
  appDomain: "onsite-tst.cityfm.com.au",
  gatewayAPI_V1: "https://onsite-tst.cityfm.com.au/api/v1/gateway",
  capchaKey: "6LcWB_0hAAAAAFOTxPOa-ZGlfUqiS3vi003Z8Ghz",
  b2cSignInPolicy: "B2C_1A_TST_CITYONSITE_Signin",
  b2cSignUpPolicy: "B2C_1A_TST_CITYONSITE_SIGNUP",
  b2cEditProfilePolicy: "B2C_1A_TST_CITYONSITE_PROFILEEDIT",
  b2cDeactivateProfilePolicy: "B2C_1A_TST_CITYONSITE_DEACTIVATEPROFILE",
  b2cForgotPasswordPolicy: "B2C_1A_TST_CITYONSITE_FORGOTPASSWORD",
  inductionModuleEnable: true,
   authB2C: {
    authorizationBaseUrl: `${b2cUrl}/{azure_policy}/oauth2/v2.0/authorize`,
    accessTokenEndpoint: `${b2cUrl}/{azure_policy}/oauth2/v2.0/token`,
    signOutRequestUrl: `${b2cUrl}/{azure_policy}/oauth2/v2.0/logout`,
    resetPasswordUrl: `${b2cUrl}/oauth2/v2.0/authorize?p=B2C_1A_TST_CITYONSITE_FORGOTPASSWORD`,
    editProfileUrl: `${b2cUrl}/oauth2/v2.0/authorize?p=B2C_1A_TST_CITYONSITE_PROFILEEDIT`,
    deactivateProfileUrl: `${b2cUrl}/oauth2/v2.0/authorize?p=B2C_1A_TST_CITYONSITE_DEACTIVATEPROFILE`,
    appId: '1ec43617-46f4-4b44-81fb-9b9675f8bcb0',
    scope: '1ec43617-46f4-4b44-81fb-9b9675f8bcb0 offline_access'
  },
  geolocationInterval: 60000,
  appVersionCheckInterval: 0, // In hours
  geofencingRangeMeterDefault: 5000,
  webGeolocationWatchEnableHighAccuracy: false,
  webGeolocationWatchMaximumAge: 30000,
  webGeolocationWatchTimeout: 30000,
  mobileGeolocationWatchBackgroundMessage: "City Onsite is tracking your location as you are logged into the site.",
  mobileGeolocationWatchBackgroundTitle: "City Onsite Tracker",
  mobileGeolocationWatchRequestPermissions: true,
  mobileGeolocationWatchStale: false,
  mobileGeolocationWatchDistanceFilter: 1,
  isSiteListJobCountEnable:true,
  isServiceWorkerEnabled: true,
  blockSearchIndexing: true,
  appInsights: {
    //instrumentationKey: 'a41c2ec8-18a8-4102-a472-dad86fe5ca25',
    instrumentationKey: '',
  },
  configurationCheckInterval: 1, // In hours
  qrCodeLoginUrl: 'https://onsite-tst.cityfm.com.au/login',
};
