<div class="dialog-container">
  <label class="deleteVisitorTypeHeader" id="deleteVisitorTypeHeader">
    {{ deletePopupHeader }}
  </label>
  <div class="divider"></div>
  <button type="submit" mat-raised-button id="removeVisitorTypeBtn" data-testid="remove_visitor_type_btn"
    (click)="deleteVisitorType()">
    {{'visitor_type_management_delete_popup_remove_btn' | translate }}
  </button>
  <div class="divider"></div>
  <button mat-stroked-button class="color-city-blue btn-border-color" data-testid="cancel_visitor_type_deletion_tbn"
    (click)="dismissDialog()">
    {{ 'visitor_type_management_delete_popup_cancel_btn' | translate }}
  </button>
</div>