import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Site } from '../../models/site.model';
import { StorageKey } from 'src/app/shared/enums';
import { Preferences } from '@capacitor/preferences';
import { BaseService } from '../base/base.service';
import { SiteVisitReason } from 'src/app/models/sitevisitreason.model';
import { Job } from '../../models/job.model';
import { NotificationPushService } from 'src/app/services/notification/notification.push.service';
import { AllocatedJobVisitReason } from '@app/models/allocatedjobvisitreason.model';

@Injectable({ providedIn: 'root' })
export class SiteService extends BaseService {
    readonly KEY = StorageKey[StorageKey.SITE];
    readonly QRSITEKEY = StorageKey[StorageKey.QRSITE];
    readonly SITEVISITREASONKEY = StorageKey[StorageKey.SITEVISITREASONS];
    readonly BASE_URL = `${environment.gatewayAPI_V1}/Sites/`;
    private siteVisitReasons: SiteVisitReason[] = [];
    private qrSiteId: string | undefined;
    constructor(
        private _notificationPushService: NotificationPushService) {
        super();
    }

    async getQrSiteId(): Promise<string | undefined> {
        const item = await Preferences.get({ key: this.QRSITEKEY });
        var obj;
        if (item.value != null)
            obj = item.value;
        return obj;
    }

    async setQrSiteId(siteId: string): Promise<void> {
        await Preferences.set({
            key: this.QRSITEKEY,
            value: siteId,
        });
    }

    async removeQRSiteId() {
        await Preferences.remove({ key: StorageKey[this.QRSITEKEY] });
    }

    async getSignedInSite(): Promise<Site> {
        const item = await Preferences.get({ key: this.KEY });
        var obj;
        if (item.value != null)
            obj = JSON.parse(item.value);
        return obj;
    }

    async setSignedInSite(site: Site): Promise<void> {
        await Preferences.set({
            key: this.KEY,
            value: JSON.stringify(site),
        });
    }

    async isSiteAuthenticated() {
        const item = await Preferences.get({ key: this.KEY });
        if (item.value != null) {
            return true;
        }
        return false;
    }

    async removeSignedInSite() {
        await Preferences.remove({ key: StorageKey[this.KEY] });
        await this._notificationPushService.endLocationWatch();
    }

    public async addVisitReason(job: Job) {
        var visitReason: SiteVisitReason = {
            id: job.referenceNumber,
            calloutReferenceNumber: job.calloutReferenceNumber,
            status: job.jobStatusName,
            version: job.version
        }
        this.removeVisitReason(job);
        this.siteVisitReasons.push(visitReason);
        await Preferences.set({
            key: this.SITEVISITREASONKEY,
            value: JSON.stringify(this.siteVisitReasons)
        });
    }

    public async addVisitorTypeReason(visitorType: string) {
        var visitReason: SiteVisitReason = {
            id: "",
            calloutReferenceNumber: visitorType,
            status: "",
            version: 0
        }
        this.siteVisitReasons.push(visitReason);
        await Preferences.set({
            key: this.SITEVISITREASONKEY,
            value: JSON.stringify(this.siteVisitReasons)
        });
    }

    public async removeVisitReason(job: Job) {
        this.siteVisitReasons.forEach((e, i) => {
            if (e.id == job.referenceNumber) {
                this.siteVisitReasons.splice(i, 1);
            }
        });
        await Preferences.set({
            key: this.SITEVISITREASONKEY,
            value: JSON.stringify(this.siteVisitReasons)
        });
    }

    public async removeAllVisitReasons() {
        this.siteVisitReasons = [];
        await Preferences.remove({ key: StorageKey[this.SITEVISITREASONKEY] });
    }

    public async setReasonForVisit(reasonForVisit: string) {
        this.removeAllVisitReasons();
        try{
            var allocatedJobVisitReasons =  JSON.parse(reasonForVisit) as AllocatedJobVisitReason[];
            if(allocatedJobVisitReasons != null && allocatedJobVisitReasons != undefined){
                allocatedJobVisitReasons.forEach(e => {
                    
                    var visitReason: SiteVisitReason = {
                        id: e.jobNumber,
                        calloutReferenceNumber: e.calloutReferenceNumber,
                        status: "Not Updated",
                        version: e.version
                    };
                    this.siteVisitReasons.push(visitReason);
                    
                });
            }

        }
        catch {
            if (reasonForVisit != "") {
                reasonForVisit.split(',').forEach(e => {
                     var visitReason: SiteVisitReason = {
                         id: "",
                         calloutReferenceNumber: e,
                         status: "",
                         version: 0
                     };
                     this.siteVisitReasons.push(visitReason);
                });
             } else {
                var visitReason: SiteVisitReason = {
                    id: "",
                    calloutReferenceNumber: "",
                    status: "",
                    version: 0
                };
                this.siteVisitReasons.push(visitReason);
             }          
        }
              
        await Preferences.set({
            key: this.SITEVISITREASONKEY,
            value: JSON.stringify(this.siteVisitReasons)
        });
    }

    public async getVisitReasons() {
        const item = await Preferences.get({ key: this.SITEVISITREASONKEY });
        this.siteVisitReasons = JSON.parse('{}');
        if (item.value != null) {
            this.siteVisitReasons = JSON.parse(item.value);
        }

        return this.siteVisitReasons;
    }

    async removeVisitReasons() {
        await Preferences.remove({ key: StorageKey[this.SITEVISITREASONKEY] });
        this.siteVisitReasons = [];
    }
}