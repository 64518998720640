import { Component, Inject, OnInit } from '@angular/core';
import { VisitorType } from '@app/models/visitortype.model';
import { LocalizationService } from '../internationalization/localization.service';
import { ReferenceService } from '@app/services/reference/reference.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-visitor-type-management-delete',
  templateUrl: './visitor-type-management-delete.component.html',
  styleUrls: ['./visitor-type-management-delete.component.scss']
})
export class VisitorTypeManagementDeleteComponent implements OnInit {

  visitorType: VisitorType | undefined;
  deletePopupHeader: string = "";

  constructor(private _localizationService: LocalizationService,
    private _referenceService: ReferenceService,
    private _dialogRef: MatDialogRef<VisitorTypeManagementDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: VisitorType | undefined;
    }
  ) { }

  ngOnInit(): void {
    this.visitorType = this.data.type;
    this.setHeader();
  }

  setHeader() {
    if (this.visitorType) {
      this.deletePopupHeader = this._localizationService.translate("visitor_type_management_delete_popup_header")
        .replace('{visitor_type}', this.visitorType?.description);
    }
  }

  deleteVisitorType() {
    if (this.visitorType && this.visitorType.id && this.visitorType.id !== '') {
      this._referenceService.deleteVisitorType(this.visitorType.id).subscribe({
        next: (result) => {
          if (result) {
            this.dismissDialog(result);
          }
        },
        error: (error) => {
          console.error(this._localizationService.translate("visitor_type_management_delete_error_deleting_visitor_type"), error);
        }
      });
    }
  }

  dismissDialog(result?: boolean) {
    this._dialogRef.close(result);
  }
}
