import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilsService } from 'src/app/services/util/util-service';
import { SiteService } from 'src/app/services/site/site.service';
import { SiteApiService } from 'src/app/services/site/site.api.service';
import { LocalizationService } from '../internationalization/localization.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { JobService } from 'src/app/services/job/job.service';
import { User } from '@app/models/user.model';

@Component({
  selector: 'app-login-password',
  templateUrl: './login-password.component.html',
  styleUrls: ['./login-password.component.scss']
})
export class LoginPasswordComponent {

  public loginPasswordFormGroup!: UntypedFormGroup;
  public captcha: string;
  public captchaEmail: string;
  hide = true;
  isEdgeBrowser: boolean = false;
  public emailAddress: string | undefined;
  public siteId: string | undefined;
  public calloutId: any;
  SUB_CONTRACTOR: string = 'SUBCON';


  constructor(private _authService: AuthService,
    private _location: Location,
    private _formBuilder: UntypedFormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _userService: UserService,
    private _router: Router,
    private _utilService: UtilsService,
    private _siteService: SiteService,
    private _siteApiService: SiteApiService,
    private _jobService: JobService,
    private _localizationService: LocalizationService,
    private _navigationService: NavigationService) {
    this._navigationService.onChange(false, true);

    this.isEdgeBrowser = this._utilService.isEdgeBrowser();
    this.captcha = '';
    this.captchaEmail = 'kang.kokkaun@cityholdings.asia';

    this.emailAddress = this._activatedRoute.snapshot.paramMap.get('email')?.toLocaleLowerCase();;
    this.siteId = this._activatedRoute.snapshot.paramMap.get('siteId')?.toLocaleLowerCase();
    this.calloutId = this._activatedRoute.snapshot.paramMap.get('calloutId');
    this.loginPasswordFormGroup = this.initFormGroup();
  }

  private initFormGroup(): UntypedFormGroup {
    const loginPasswordForm = this._formBuilder.group({
      userPassword: new UntypedFormControl(undefined, [Validators.required])
    });

    return loginPasswordForm;
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    console.log('resolved captcha with response: ' + this.captcha);
  }

  public enterPassword(): void {
    const inputPassword = this.loginPasswordFormGroup.get('userPassword');
    if (this.emailAddress) {
      this._userService.validateUserPassword(this.emailAddress, inputPassword?.value)
        .subscribe(
          {
            next: (user) => {
              if (user) {
                let userCompanies: string[] = [];

                if (user.companies) {
                  user.companies.forEach(company => {
                    if (company.companyType.toUpperCase() != this.SUB_CONTRACTOR) {
                      userCompanies.push(company.referenceNumber);
                    }
                  });
                }

                this.getJob(user, userCompanies);
              }
            },
            error: (error) => {
              inputPassword ? inputPassword.setErrors({ 'invalidPassword': true }) : undefined;
            }
          });
    }
    else {
      inputPassword ? inputPassword.setErrors({ 'invalidPassword': true }) : undefined;
    }

  }

  getJob(user: User, userCompanies: string[]) {
    this._jobService.getJobExistsByCompanyRefNumberNEmail(user.emailAddress, userCompanies).subscribe({
      next: (job) => {
        if (job == "1") {
          user.withJob = true;
        }
        else {
          user.withJob = false;
        }
        this._authService.setUser(user);
        if (this.siteId && this.calloutId && !Number.isNaN(this.calloutId) && this.calloutId > 0) {
          this.fastlinkRedirection(this.siteId, Number(this.calloutId));
        } else if (this.siteId) {
          this.qrRedirection(this.siteId);
        } else {
          this._router.navigate(['signin']);
        }
      },
    })
  }

  qrRedirection(siteId: string) {
    this._siteApiService.getNearbySiteBySiteId(11, 11, siteId).subscribe({
      next: (site) => {
        if (site) {
          this._siteService.setSignedInSite(site).then(s => {
            this._router.navigate(['signedin']);
          });
        } else {
          alert(this._localizationService.translate('loginuser_invalid_site'));
          this._router.navigate(['signin']);
        }
      }
    });
  }

  fastlinkRedirection(siteId: string, calloutId: number) {

    this._siteApiService.getNearbySiteBySiteId(11, 11, siteId).subscribe({
      next: (site) => {
        if (site) {
          this._siteService.setSignedInSite(site).then(s => {
            this._router.navigate(['signedin']);
          });
        } else {
          alert(this._localizationService.translate('loginuser_invalid_site'));
          this._router.navigate(['signin']);
        }
      }
    });

  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'userPassword':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('loginpassword_password_required');
        } else if (this.formHasError(form, controlName, 'invalidPassword')) {
          return this._localizationService.translate('loginpassword_password_invalid');
        }
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }

  back() {
    this._location.back();
  }


}
