<div class="main-content" style="max-height: 80%;">
    <div *ngIf="locationMessage != ''">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="100%">
                <div class="alert alert-danger">
                    <p>
                        {{locationMessage}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="locationMessage == ''" class="site-list">
        <div fxLayout="row" class="select-site-message">
            <div fxLayout="column">
                <span class="font-size-h1 color-city-blue">{{ 'sitesignin_lbl_select_site' | translate }}</span>
                <span class="font-size-body-text color-dark-blue">{{ 'sitesignin_lbl_please_select' | translate }}</span>
            </div>
        </div>
        <div class="divider"></div>
        <div fxLayout="row" *ngIf="!(sites) || sites.length <= 0">
            <span class="display-grey color-city-neutral">
                {{ 'sitesignin_lbl_not_available_range' | translate }}
            </span>
        </div>
        <div fxLayout="row" *ngIf="sites && sites.length > 0">
            <div fxLayout="column" fxFlex="100%" class="sites-list-container">
                <div *ngFor="let site of sites">
                    <div fxLayout="row">
                        <button mat-raised-button  [class]="isSiteOnClickEnable(site) || !isSiteListJobCountEnable ? 'bg-white' : 'bg-grey'" (click)="siteSelectionOnclick(site)" id="btn_siteselection_{{site.id}}" [disabled]="!isSiteOnClickEnable(site) && isSiteListJobCountEnable">
                            <div fxLayout="column" fxFlex="30%">
                                <div fxFlex="0 100 100%" fxLayoutAlign="center center">
                                    <img class="site-image" [src]="site.brand.imageUrl" />
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="70%" >
                                <div fxLayout="row">
                                    <span class="display-name color-city-blue">{{site.name}}</span>
                                </div>
                                <div fxLayout="row" >
                                    <span class="display-grey color-city-neutral">
                                        <mat-icon class="display-grey-icon color-city-neutral">pin_drop</mat-icon>
                                        {{ calculateDistance(site.lat, site.lon) }}
                                    </span>
                                </div>
                                <div fxLayout="row" *ngIf="isSiteListJobCountEnable && getJobCountForSite(site.id) > 0">
                                    <span class="display-grey color-city-neutral">
                                      {{ getJobCountForSite(site.id) }} {{ 'sitesignin_lbl_jobavailabile' | translate}}
                                    </span>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div class="divider"></div>
                </div>
            </div>
        </div>
    </div>
</div>

