import { Component, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, ValidatorFn, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Role, User } from '@app/models/user.model';
import { LocalizationService } from '../internationalization/localization.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CustomValidators } from '@app/shared/custom-validators';
import { UserService } from '@app/services/user/user.service';

interface Email { name: string, valid: boolean }

@Component({
  selector: 'app-admin-management-add-edit',
  templateUrl: './admin-management-add-edit.component.html',
  styleUrls: ['./admin-management-add-edit.component.scss']
})
export class AdminManagementAddEditComponent implements OnInit {
  isEditMode : boolean = false;
  formGroup! : UntypedFormGroup;
  loginUser : User | undefined;
  selectedUser : User | undefined;
  adminUsers : User[] = [];
  addOnBlur = true;
  emails : Email[] = [];
  roles : Role[] = [];

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(
    private _dialogRef: MatDialogRef<AdminManagementAddEditComponent>,
    private _localizationService : LocalizationService,
    private _formBuilder: UntypedFormBuilder,
    private _userService : UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loginUser = this.data.loginUser;
    this.selectedUser = this.data.user;
    this.adminUsers = this.data.adminUsers;
    this.isEditMode = this.data.isEditMode;
    this.roles = this.data.roles;

    this.formGroup = this._formBuilder.group({
      roles: new UntypedFormArray([], this.atLeastOneRoleRequired()), 
      emailAddress: new UntypedFormControl(undefined, [Validators.required, CustomValidators.isEmailValid()])      
    });

    this.roles.forEach(role => {
      let status: boolean = false;
      if (this.selectedUser?.roles){
        status = (this.selectedUser?.roles as Role[]).some(x => x.name.toLowerCase() === role.name.toLowerCase());
      }
      this.rolesFormArray.push(new UntypedFormControl(status));
    });
  }

  ngOnInit(): void {
    
  }

  get rolesFormArray() {
    return this.formGroup.controls['roles'] as UntypedFormArray;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'emailAddress':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('admin_management_add_edit_email_required');
        } else if (this.formHasError(form, controlName, 'invalidEmail')) {
          return this._localizationService.translate('admin_management_add_edit_email_invalid');
        }
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }

  atLeastOneRoleRequired(): ValidatorFn {
    return (control: AbstractControl) => {
      const controlArray = control as UntypedFormArray;
      if (controlArray.controls.some(el => el.value === true)) {
        return null;
      } else {
        return {
          atLeastOneRole: { atLeastOneRole: false }
        }
      }
    }
  }

  addUser(){    
    const emailAddress = this.formGroup.get('emailAddress');
    let proceed = true;
    this.adminUsers.forEach(user => {
      if (user.emailAddress.toLowerCase() === emailAddress?.value.toLowerCase()){
        alert(this._localizationService.translate('admin_management_add_user_exists'));
        proceed = false;
        return;
      }
    });
    if (!proceed){
      return;
    }
    let selectedRoles = [] as string[];
    this.formGroup.controls['roles'].value
    .forEach((value: boolean, index: number) => {
      if (value === true){
        let selectedCompany = this.roles[index];
        selectedRoles.push(selectedCompany.id);
      }          
    });

    this._userService.addAdminUser(emailAddress?.value, selectedRoles).subscribe({
      next: (_ => this._dialogRef.close()),
      error: (_ => alert(this._localizationService.translate('admin_management_add_user_error'))) 
    });
  }

  editUser(){
   
  }

  disableSubmitButton(){
    return (!this.isEditMode && !this.formGroup.valid);
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }
}
