import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Site } from '@app/models/site.model';
import { environment } from '@env/environment';

export interface ExtendedSite {
  site: Site,
  edit: any
  qrCodeLink: string
}

@Component({
  selector: 'app-site-management-edit',
  templateUrl: './site-management-edit.component.html',
  styleUrls: ['./site-management-edit.component.scss']
})
export class SiteManagementEditComponent {
  selectedSite: Site | undefined;
  searchedSites : Site[] = [];
  selectedSites : Site[] = [];

  @ViewChild('paginator') paginator: MatPaginator;
  filteredSites: MatTableDataSource<ExtendedSite> = new MatTableDataSource<ExtendedSite>([]);
  displayedSiteColumns: string[] = ['Name', 'Code', 'Id', 'QR', 'QR Code Link'];
  debounce: number = 1000;

  constructor(
    private _dialogRef: MatDialogRef<SiteManagementEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedSites = this.selectedSites.concat(this.data.selectedSite);
  }

  ngOnInit(): void {
    this.filteredSites.data = this.selectedSites.map(s => { return { 
      site: s,
      qrCodeLink: `${environment.qrCodeLoginUrl}/${s.id}`,
    } as ExtendedSite})
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }
}
