import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-permit-currentdate',
  templateUrl: './permit-currentdate.component.html',
  styleUrls: ['./permit-currentdate.component.scss']
})
export class PermitCurrentdateComponent implements OnInit {

  @Input('question') question: any;

  constructor(private _datePipe: DatePipe) { }

  ngOnInit(): void {
    if(!this.question.Answer){
      this.question.Answer = this._datePipe.transform(new Date(), 'dd/MM/yyyy');
    }
  }

  captionStyles(): object {
    return JSON.parse(this.question.CaptionStyles);
  }
}
