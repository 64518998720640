import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RapidGlobalInfo } from 'src/app/models/user.model';
import { SiteInductionInfo } from 'src/app/models/user.model';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SiteInductionAddEditComponent } from '../site-induction-add-edit/site-induction-add-edit.component';
import { SiteInductionDeleteComponent } from '../../components/site-induction-delete/site-induction-delete.component';
import { LocalizationService } from '../internationalization/localization.service';
import { Camera, CameraDirection, CameraResultType } from '@capacitor/camera';
import { FileStorageService } from 'src/app/services/filestorage/filestorage.service';
import { environment } from 'src/environments/environment';
import { UploadFileStorage } from 'src/app/models/filestorage.model';
import { UtilsService } from 'src/app/services/util/util-service';
import { UserProfileDeleteComponent } from '../user-profile-delete/user-profile-delete.component';
import { RapidGlobalDeleteComponent } from '../rapid-global-delete/rapid-global-delete.component';
import { RapidGlobalAddEditComponent } from '../rapid-global-add-edit/rapid-global-add-edit.component';
import { ConfigurationService } from '@app/services/configuration/configuration.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    trigger('detailRapidExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailInductionExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class UserProfileComponent implements OnInit {
  public userProfileFormGroup!: UntypedFormGroup;
  currentUser: User | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  emailAddress: string | undefined;
  phoneNumber: string | undefined;
  profileImgLink: string | undefined;
  newImagePath: string | undefined;
  newImageFormat: string | undefined;
  rapidGlobalInfos: MatTableDataSource<RapidGlobalInfo> = new MatTableDataSource<RapidGlobalInfo>([]);
  displayedRapidGlobalColumns: string[] = ['companyName', 'status'];
  expandedRapidElement: RapidGlobalInfo | undefined;
  siteInductionInfos: MatTableDataSource<SiteInductionInfo> = new MatTableDataSource<SiteInductionInfo>([]);
  displayedSiteInductionColumns: string[] = ['brandName', 'status'];
  expandedInductionElement: SiteInductionInfo | undefined;
  inductionModuleEnable: boolean = false;
  profileInductionRequired: boolean = false;
  profileRapidGlobalRequired: boolean = false;
  revealElement: boolean = true;

  @ViewChild('img') imgEl: ElementRef | undefined;
  output: string = '';

  constructor(private _authService: AuthService,
    private _router: Router,
    private formBuilder: UntypedFormBuilder,
    private _navigationService: NavigationService,
    private _userService: UserService,
    private _addDialog: MatDialog,
    private _localizationService: LocalizationService,
    private _fileStorageService: FileStorageService,
    private _utilsService: UtilsService,
    private _configurationService: ConfigurationService,
  ) {
    this._navigationService.onChange(true, false);
    this.userProfileFormGroup = this.initUserProfileForm();
  }

  async ngOnInit(): Promise<void> {
    this.inductionModuleEnable = await this._configurationService.getInductionModuleEnable();
    this.profileInductionRequired = await this._configurationService.getProfileInductionRequired();
    this.profileRapidGlobalRequired = await this._configurationService.getProfileRapidGlobalRequired();
    this.loadUser(true);
  }

  private initUserProfileForm(): UntypedFormGroup {
    const userProfileFormGroup = this.formBuilder.group({});
    return userProfileFormGroup;
  }

  private setCurrentUser(user: User): void {
    this.currentUser = user;
    this.firstName = this.currentUser.firstName;
    this.lastName = this.currentUser.lastName;
    this.emailAddress = this.currentUser.emailAddress;
    this.phoneNumber = this.currentUser.mobileNumber;
    this.getProfileImageLink(this.currentUser.photo);
    this.rapidGlobalInfos.data = this.currentUser.rapidGlobalInfos;
    this.siteInductionInfos.data = this.currentUser.siteInductionInfos;
  }

  private async loadUser(refreshUser: boolean): Promise<void> {

    var user = await this._authService.getUser();
    await this.getUserDomain();
    if (user) {
      if (refreshUser) {
        this._userService.getUserByEmailWithDetails()
          .subscribe({
            next: (user) => {
              this.setCurrentUser(user);
              this._authService.setUser(user);
            },
            error: (err) => alert(err)
          })
      } else this.setCurrentUser(user)
    }
    else alert(this._localizationService.translate('loginuser_email_usernotfound'));
  }

  private async getUserDomain(): Promise<void> {
    try {
      const isSSODomain = await this._authService.getSSODomain();
      if (isSSODomain) {
        this.revealElement = false;
      }
    } catch (error) {
      alert(error);
    }
  }

  getStatus(item: any): string {
    let curentDate = new Date().getTime();
    let expiryDate = Date.parse(item.expiryDate);
    if (expiryDate >= curentDate)
      return "active"
    else if (item.status === 'Compliance')
      return "inactive"
    else return "error";
  }

  public closeUserProfile(): void {
    this._router.navigate(['signin']);
  }

  public async changepassword(): Promise<void> {
    await this._authService.resetPassword();
  }

  public async updateUserDetails(): Promise<void> {
    if (this.currentUser)
      await this._authService.updateUserDetails(this.currentUser);
  }

  addRapidGlobal() {
    let addRapidDialog: MatDialogRef<RapidGlobalAddEditComponent>;
    const dialogConfig = {
      data: { user: this.currentUser, isEditMode: false },
      disableClose: true,
      autoFocus: false,
      panelClass: 'details-container'
    };
    addRapidDialog = this._addDialog.open(RapidGlobalAddEditComponent, dialogConfig);
    addRapidDialog.addPanelClass('details-container');
    addRapidDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.loadUser(false);
      }
    });
  }

  editRapidGlobal(item: RapidGlobalInfo | undefined) {
    let addRapidDialog: MatDialogRef<RapidGlobalAddEditComponent>;
    const dialogConfig = {
      data: { user: this.currentUser, rapid: item, isEditMode: true },
      disableClose: true,
      autoFocus: false,
      panelClass: 'details-container'
    };
    addRapidDialog = this._addDialog.open(RapidGlobalAddEditComponent, dialogConfig);
    addRapidDialog.addPanelClass('details-container');
    addRapidDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.loadUser(false);
      }
    });
  }

  deleteRapidGlobal(item: RapidGlobalInfo | undefined) {
    let deleteRapidDialog: MatDialogRef<RapidGlobalDeleteComponent>;
    const dialogConfig = {
      data: { user: this.currentUser, rapid: item },
      disableClose: true,
      autoFocus: false,
      panelClass: 'details-container'
    };
    deleteRapidDialog = this._addDialog.open(RapidGlobalDeleteComponent, dialogConfig);
    deleteRapidDialog.addPanelClass('details-container');
    deleteRapidDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.loadUser(false);
      }
    });
  }

  addSiteInduction() {
    let addInductionDialog: MatDialogRef<SiteInductionAddEditComponent>;
    const dialogConfig = {
      data: { user: this.currentUser, isEditMode: false },
      disableClose: true,
      autoFocus: false,
      panelClass: 'details-container'
    };
    addInductionDialog = this._addDialog.open(SiteInductionAddEditComponent, dialogConfig);
    addInductionDialog.addPanelClass('details-container');
    addInductionDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.loadUser(false);
      }
    });
  }

  editSiteInduction(item: SiteInductionInfo | undefined) {
    let addInductionDialog: MatDialogRef<SiteInductionAddEditComponent>;
    const dialogConfig = {
      data: { user: this.currentUser, induction: item, isEditMode: true },
      disableClose: true,
      autoFocus: false,
      panelClass: 'details-container'
    };
    addInductionDialog = this._addDialog.open(SiteInductionAddEditComponent, dialogConfig);
    addInductionDialog.addPanelClass('details-container');
    addInductionDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.loadUser(false);
      }
    });
  }

  deleteSiteInduction(item: SiteInductionInfo | undefined) {
    let deleteInductionDialog: MatDialogRef<SiteInductionDeleteComponent>;
    const dialogConfig = {
      data: { user: this.currentUser, induction: item },
      disableClose: true,
      autoFocus: false,
      panelClass: 'details-container'
    };
    deleteInductionDialog = this._addDialog.open(SiteInductionDeleteComponent, dialogConfig);
    deleteInductionDialog.addPanelClass('details-container');
    deleteInductionDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.loadUser(false);
      }
    });
  }

  profileImageOnClick() {
    Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
      direction: CameraDirection.Front
    }).then(
      image => {
        if (image && image?.dataUrl) {
          let imageFormatFromBase64String = this._utilsService.getBase64StringFileExtension(image?.dataUrl);
          this._authService.getFileSettings()
            .then(fs => {
              let formats = fs.supportedImages?.replace(/^,+|,+$/g, '').split(",").join("|");
              if (formats && formats.length > 0) {
                let matches = imageFormatFromBase64String.match(new RegExp(`(${formats})$\w?`));
                if (matches == null) {
                  alert(this._localizationService.translate("userprofile_invalid_filetype").replace('{supported_formats}', fs.supportedImages!.toLocaleUpperCase()));
                } else {
                  this.profileImgLink = image?.dataUrl;
                  this.newImagePath = image?.dataUrl;
                  this.newImageFormat = matches[0];
                }
              } else alert(this._localizationService.translate("userprofile_supported_fileformats_not_found"));
            });
        } else alert(this._localizationService.translate("userprofile_downloadfile_no_data_url_error"));
      },
      reject => {
        alert(reject);
      }
    );
  }

  public savePhotoOnClick() {
    if (this.currentUser && this.newImagePath) {
      var newFile = {
        fileType: this.newImageFormat ? this.newImageFormat : "",
        fileName: `${this.currentUser.id}.${this.newImageFormat}`
      } as UploadFileStorage;
      if (this.currentUser) {
        let imageFormatFromBase64String = this._utilsService.getBase64StringFileExtension(this.newImagePath);
        this.newImagePath = imageFormatFromBase64String === "jpg"
          ? this._utilsService.removeExifMetadataFromDataUrl(this.newImagePath)
          : this.newImagePath;
        newFile.file = this.newImagePath.split(",")[1];
        this._fileStorageService.UploadImage(newFile).subscribe({
          next: r => {
            if (r && this.currentUser && r.id != undefined) {
              this._userService.updateUserPhoto(this.currentUser?.id, r.id).subscribe({
                next: r => {
                  if (r) {
                    alert(this._localizationService.translate("userprofile_photo_updated"));
                    if (this.currentUser && this.newImagePath) {
                      this.currentUser.photo = this.newImagePath;
                      this._authService.setUser(this.currentUser);
                    }
                    this.newImagePath = undefined;
                    this.newImageFormat = undefined;
                  } else {
                    alert(this._localizationService.translate("error_return_empty"));
                  }
                },
                error: e => {
                  alert(e);
                }
              });
            }
          },
          error: e => {
            alert(e);
          }
        });
      }
    }
  }

  private getProfileImageLink(userPhoto: string) {
    if (environment.isDemo) {
      this.profileImgLink = userPhoto;
    } else {
      // Regular expression to check if string is a valid UUID
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      if (!regexExp.test(userPhoto)) {
        this.profileImgLink = userPhoto;
      } else {
        if (this.currentUser) {
          this._fileStorageService.download(userPhoto).subscribe(
            {
              next: r => {
                this.profileImgLink = `data:image/${r.fileType};base64,${r.file}`;
              },
              error: e => {
                this.profileImgLink = "";
              }
            });
        } else {
          this.profileImgLink = "";
        }
      }
    }
  }

  getCompanyNameByRapidGlobalompanyId(id: string): string {
    var result = "-";
    if (id && id.length > 0 && this.currentUser?.companies && this.currentUser?.companies.length > 0) {
      var company = this.currentUser.companies.find(c => c.id == id);
      if (company) {
        result = company.name;
      }
    }
    return result
  }

  deactivateUser() {
    let deactivateUserDialog: MatDialogRef<UserProfileDeleteComponent>;
    const dialogConfig = {
      data: { user: this.currentUser },
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass: 'details-container'
    };
    deactivateUserDialog = this._addDialog.open(UserProfileDeleteComponent, dialogConfig);
    deactivateUserDialog.addPanelClass('details-container');
    deactivateUserDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.logout().then(async _ => {
          this._router.navigate(['login']);
        });
      }
    });
  }

}