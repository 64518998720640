<div class="dialog-container">      
  <label class="addInductionLabel" id="addInductionLabel">
      {{ ((!isEditMode ? 'rapid_global_add_header' : 'rapid_global_edit_header') | translate) }}
  </label>
  <div class="divider"></div>
  <form [formGroup]="rapidFormGroup">
    <div>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label class="color-city-blue">
          {{'rapid_global_add_enterbusinessname_field' | translate }}
        </mat-label>
        <input matInput 
          data-testid="rapid_global_add_enter_business_name"
          placeholder="{{'rapid_global_add_enterbusinessname_field' | translate }}" 
          formControlName="businessName" required id="input_businessname_add" 
          (mouseleave)="validateRapid()">
        <mat-error *ngIf="hasError(rapidFormGroup, 'businessName')">
          {{getError(rapidFormGroup, 'businessName')}}
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <div class="divider"></div>
  <div class="divider"></div>
  <button type="submit" 
    id="rapid_global_add_edit_submit"
    data-testid="rapid_global_add_edit_submit"
    mat-raised-button 
    (click)="isEditMode ? editRapidGlobal() : addRapidGlobal()" 
    [disabled]="disableSubmitButton()">
    {{'rapid_global_add_submit' | translate }}
  </button>
  <div class="divider"></div>
  <button mat-stroked-button 
    class="color-city-blue btn-border-color" 
    data-testid="rapid_global_add_edit_cancel"
    (click)="dismissDialog()">
    {{'rapid_global_add_cancel' | translate }}
  </button>
</div>