
<form [formGroup]="loginPasswordFormGroup">

    <div id="loginpassword" fxLayout="column">
        <div fxLayout = "row">
            <div fxFlex = "3"></div>
            <div class="logo" fxFlex = "94">
                <img src="/assets/CityOnSite_Logo.png">
            </div>
        </div>
        <div fxLayout = "row">
            <div fxFlex = "100">
                <div fxLayout="column">
                    <div class="font-size-h1 color-city-blue">{{'loginpassword_password' | translate }}</div>
                    <div class="font-size-body-text color-dark-blue">{{'loginpassword_enterpassword' | translate }}</div>
                    <div>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="color-city-blue">{{'loginpassword_enteryourpassword' | translate }}</mat-label>
                            
                            <input matInput placeholder="{{'loginpassword_enteryourpassword' | translate }}" [type]="hide ? 'password' : 'text'" formControlName="userPassword" required id="input_password">
                            <mat-icon *ngIf="!isEdgeBrowser" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            <mat-error *ngIf="hasError(loginPasswordFormGroup, 'userPassword')">{{getError(loginPasswordFormGroup, 'userPassword')}}</mat-error> 
                        </mat-form-field>
                    </div> 
                    <div class="divider"></div>
                    <div>
                        <button mat-raised-button (click)="enterPassword()" id="btn_enterpassword">
                            {{'loginpassword_enter' | translate }}
                        </button>
                    </div>
                    <div class="divider"></div>
                    <div class="forgotpassword">
                        <a mat-flat-button class="url" href="" target="_blank" id="btn_forgotpassword">{{'loginpassword_forgotpassword' | translate }}</a>
                    </div>
                    
                </div>
            </div>
        </div>
        <br />
    
    </div>
    <div class="divider"></div>
    <div class="divider"></div>
    <div class="divider"></div>
    <div class="footer">
        <div><mat-label color-dark-blue>{{'loginpassword_conditiontitle' | translate }}</mat-label></div>
        <a mat-flat-button class="url" href="" target="_blank" id="btn_conditionofuse">{{'loginpassword_condition' | translate }}</a>
    </div>
</form>

