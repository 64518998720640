<div class="dialog-container">
    <h1>{{ 'admin_management_add_edit_user_header' | translate }}</h1>
    <label id="adminManagementAddEditGuideLabel">
      {{ ('lbl_admin_management_add_edit_user_guide' | translate) }}
    </label>
    <div class="divider"></div>
    <form [formGroup]="formGroup" data-testid='admin_management_add_edit_form'>
      <div>
        <mat-form-field *ngIf="isEditMode" appearance="fill" fxFlex="100">
          <mat-label class="color-city-blue">{{ 'admin_management_add_edit_user_email_label' | translate }}</mat-label>
          <input matInput disabled value={{selectedUser!.emailAddress}} id="input_email_address" data-testid="admin_management_add_edit_email_address">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field *ngIf="!isEditMode" appearance="outline" fxFlex="100">
          <mat-label class="color-city-blue">{{ 'admin_management_add_edit_user_email_label' | translate }}</mat-label>
          <input matInput 
              placeholder="{{'admin_management_add_edit_user_enteremail_field' | translate }}" 
              formControlName="emailAddress" 
              required 
              id="admin_management_add_edit_input_email_address"
              data-testid="admin_management_add_edit_input_email_address">
          <mat-error *ngIf="hasError(formGroup, 'emailAddress')">{{getError(formGroup, 'emailAddress')}}</mat-error>
        </mat-form-field>
      </div>
      <h1>{{ 'admin_management_add_edit_user_roles' | translate }}</h1>
      <label id="userManagementAddEditCompanyGuideLabel">
        {{ ('lbl_admin_management_add_edit_user_role_guide' | translate) }}
      </label>
      <cdk-virtual-scroll-viewport itemSize="50" class="roles-viewport" style="max-width: 100%;">
        <div formArrayName="roles" *cdkVirtualFor="let role of rolesFormArray.controls; let i = index" >
          <div class="divider"></div>
          <mat-slide-toggle
            data-testid="admin_management_add_edit_company_item"
            color="primary"
            [formControlName]="i">
            {{ roles[i].name}}
          </mat-slide-toggle>
          <mat-divider></mat-divider>
        </div>
      </cdk-virtual-scroll-viewport>
    </form>
    <div class="divider"></div>
    <div class="divider"></div>
    <button type="submit" 
      id="admin_management_add_edit_submit" 
      data-testid="admin_management_add_edit_submit"
      mat-raised-button 
      (click)="isEditMode ? editUser() : addUser()" 
      [disabled]="disableSubmitButton()">
      {{ 'admin_management_add_edit_user_submit' | translate }}
    </button>
    <div class="divider"></div>
    <button 
      id="admin_management_add_edit_cancel"
      data-testid="admin_management_add_edit_cancel"
      mat-stroked-button class="color-city-blue btn-border-color"    
      (click)="dismissDialog()">
      {{ 'admin_management_add_edit_user_cancel' | translate }}
    </button>   
  </div>
