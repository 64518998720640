<div class="dialog-container">
  <h1>{{ 'user_management_add_edit_user_header' | translate }}</h1>
  <label id="userManagementAddEditGuideLabel">
    {{ ('lbl_user_management_add_edit_user_guide' | translate) }}
  </label>
  <div class="divider"></div>
  <form [formGroup]="formGroup" data-testid='user_management_add_edit_form'>
    <div>
      <mat-form-field *ngIf="isEditMode" appearance="fill" fxFlex="100">
        <mat-label class="color-city-blue">{{ 'user_management_add_edit_user_email_label' | translate }}</mat-label>
        <input matInput disabled value={{currentUser!.emailAddress}} id="input_email_address" data-testid="user_management_add_edit_email_address">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field *ngIf="!isEditMode" appearance="outline" fxFlex="100">
        <mat-label class="color-city-blue">{{ 'user_management_add_edit_user_email_label' | translate }}</mat-label>
        <mat-chip-grid #chipList>
          <mat-chip-row *ngFor="let email of emails" selected [color]="email.valid ? 'primary':'warn'" (removed)="remove(email)">
            {{email.name}}
            <button matChipRemove data-testid="user_management_add_edit_remove_email_address">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input
            data-testid="user_management_add_edit_email_addresses" placeholder="New email..."
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
        </mat-chip-grid>
        <mat-error data-testid='user_management_email_error' *ngIf="chipList.errorState">{{getEmailListError()}}</mat-error>
      </mat-form-field>
    </div>
    <h1>{{ 'user_management_add_edit_user_companies' | translate }}</h1>
    <label id="userManagementAddEditCompanyGuideLabel">
      {{ ('lbl_user_management_add_edit_user_company_guide' | translate) }}
    </label>
    <cdk-virtual-scroll-viewport itemSize="50" class="companies-viewport" style="max-width: 100%;">
      <div formArrayName="companies" *cdkVirtualFor="let company of companiesFormArray.controls; let i = index" >
        <div class="divider"></div>
        <mat-slide-toggle
          data-testid="user_management_add_edit_company_item"
          color="primary"
          [formControlName]="i">
          {{adminUser!.companies[i].name}}
        </mat-slide-toggle>
        <mat-divider></mat-divider>
      </div>

    </cdk-virtual-scroll-viewport>
  </form>
  <div class="divider"></div>
  <div class="divider"></div>
  <button type="submit" 
    id="user_management_add_edit_submit" 
    data-testid="user_management_add_edit_submit"
    mat-raised-button 
    (click)="isEditMode ? editUser() : addUser()" 
    [disabled]="disableSubmitButton()">
    {{ 'user_management_add_edit_user_submit' | translate }}
  </button>
  <div class="divider"></div>
  <button 
    id="user_management_add_edit_cancel"
    data-testid="user_management_add_edit_cancel"
    mat-stroked-button class="color-city-blue btn-border-color"    
    (click)="dismissDialog()">
    {{ 'user_management_add_edit_user_cancel' | translate }}
  </button>   
</div>