import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/components/internationalization/localization.service';
import { QnAQuestionAttachmentModel } from 'src/app/models/inductionqna.model';
import { CityonsiteAttachmentExternalLinkComponent } from '../cityonsite-attachment-external-link/cityonsite-attachment-external-link.component';
import { CityonsiteAttachmentFromProfileComponent } from '../cityonsite-attachment-from-profile/cityonsite-attachment-from-profile.component';
import { DocumentService } from 'src/app/services/document/document.service';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UtilsService } from '@app/services/util/util-service';

@Component({
  selector: 'app-cityonsite-attachments-add-file',
  templateUrl: './cityonsite-attachments-add-file.component.html',
  styleUrls: ['./cityonsite-attachments-add-file.component.scss']
})
export class CityonsiteAttachmentsAddFileComponent implements OnInit {
  questionAttachments : QnAQuestionAttachmentModel[] = [];
  currentUser: User | undefined;
  supportedFormat: string | undefined;

  constructor(
    private _authService: AuthService,
    private _dialogRef: MatDialogRef<CityonsiteAttachmentsAddFileComponent>,
    private _localizationService : LocalizationService,
    private _documentService: DocumentService,
    private _openDialog: MatDialog,
    private _utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this._authService.getUser().then(
        user => this.currentUser = user, 
        _ => console.log("Failed to get user")
      );
    }

  ngOnInit(): void {
    if(this.data.question.questionAttachments !== undefined && this.data.question.questionAttachments !== null){
      this.questionAttachments = this.data.question.questionAttachments;
    }

    this._authService.getFileSettings()
    .then(fs => {
      let imageFormat = fs.supportedImages;            
      let fileFormat = fs.supportedDocuments;
      if (imageFormat) {
        if (fileFormat) {
          this.supportedFormat = this._utilsService.updateFileFormatToAcceptedFormat(imageFormat, fileFormat);
        } else {
          this.supportedFormat = this._utilsService.updateFileFormatToAcceptedFormat(imageFormat);
        }
      } else if (fileFormat) {
        this.supportedFormat = this._utilsService.updateFileFormatToAcceptedFormat(fileFormat);
      }
    });
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }

  importFile(event: any, caption: string){

    if (this.questionAttachments.length >= 10){
      alert(this._localizationService.translate("cityonsiteattachment_addfile_invalid_maxnumber"));
      return;
    }

    if (event.target.files.length == 0) {
      console.log("No file selected!");
      return;
    }    

    let file: File = event.target.files[0];
    if (this.questionAttachments.length > 0){      
      let attachment = this.questionAttachments.find((att : any) => att.fileName.toLowerCase() == file.name.toLowerCase());
      if (attachment){
        alert(this._localizationService.translate("cityonsiteattachment_addfile_invalid_samefile"));
        return;
      }
    }

    let docType;
    switch (caption) {
      case 'Safe Work Method Statements':
        docType = 'SWMS';
        break;
      case 'Provide Required Licenses':
        docType = 'License';
        break;
      case 'Professional Certificates':
        docType = 'Cert'
        break;
      default:
        docType = 'None'
        break;
    }    
    
    let userDocumentUpload = this._documentService.getFileAndUploadType(file);

    if (userDocumentUpload){
      (userDocumentUpload.UploadType === 'img'
        ? this._documentService.uploadUserImage(userDocumentUpload.FileType, docType, file)
        : this._documentService.uploadUserDocument(userDocumentUpload.FileType, docType, file)
      ).subscribe({
        next: (response => {
          if (response) {
            if (this.currentUser){
              this.currentUser.userDocuments.push(response);
              this._authService.setUser(this.currentUser);
            }
            let questionAttachment = {
              activityTaskInstanceJobEventQnAFileId : "",
              fileName : file.name,
              fileByteArray : "",
              data : undefined,
              fileType : file.type,
              htmlTag : "",
              fileGuid : response.id
            }
            this.questionAttachments.push(questionAttachment);
            this.data.question.questionAttachments = this.questionAttachments;
          }
          this._dialogRef.close(this.data);
        })
      });
    }
  }

  openExternalLink(){
    let extLinkDialog: MatDialogRef<CityonsiteAttachmentExternalLinkComponent>;

    const dialogConfig = {
        data: this.data,
        disableClose: true,
        autoFocus: false,
        maxWidth: '100vw !important',
        maxHeight: '100vw !important',
        panelClass:'details-container'
    };

    extLinkDialog = this._openDialog.open(CityonsiteAttachmentExternalLinkComponent, dialogConfig);
    extLinkDialog.afterClosed().subscribe((returnValue) => {
        if (returnValue) {
          this.data = returnValue;
          this._dialogRef.close(this.data);
        }
    });
  }

  openFromProfile(){
    let fromProfileDialog: MatDialogRef<CityonsiteAttachmentFromProfileComponent>;

    const dialogConfig = {
        data: this.data,
        disableClose: true,
        autoFocus: false,
        panelClass:'details-container'
    };

    fromProfileDialog = this._openDialog.open(CityonsiteAttachmentFromProfileComponent, dialogConfig);
    fromProfileDialog.afterClosed().subscribe((returnValue) => {
        if (returnValue) {
          this.data = returnValue;
          this._dialogRef.close(this.data);
        }
    });
  }
}