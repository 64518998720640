<div class="dialog-container">
  <label class="addInductionLabel" id="deleteRapidGlobalIdLabel">
    {{ ('rapid_global_delete_header' | translate) }}
  </label>
  <div>{{rapidGlobalInfo?.companyName}}</div>
  <div class="divider"></div>
  <button type="submit" mat-raised-button 
    id="delete_rapid_global"
    data-testid="rapid_global_delete_submit"
    (click)="deleteRapidGlobal()">
    {{'rapid_global_delete_submit' | translate }}
  </button>
  <div class="divider"></div>
  <button mat-stroked-button class="color-city-blue btn-border-color"
    data-testid="rapid_global_delete_cancel"
    (click)="dismissDialog()">
    {{ 'rapid_global_delete_cancel' | translate }}
  </button>   
</div>