import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Company, User, InviteUser, InviteUserCompany, UpdatedUserCompanies } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { InvitationSource, InvitationType } from 'src/app/shared/enums';
import { LocalizationService } from '../internationalization/localization.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA}  from '@angular/cdk/keycodes';
import { CustomValidators } from 'src/app/shared/custom-validators';

interface Email { name: string, valid: boolean }

@Component({
  selector: 'app-user-management-add-edit',
  templateUrl: './user-management-add-edit.component.html',
  styleUrls: ['./user-management-add-edit.component.scss'],
  animations: [
    trigger('detailUserExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ],
})
export class UserManagementAddEditComponent implements OnInit {
  
  isEditMode : boolean = false;
  formGroup! : UntypedFormGroup;
  adminUser : User | undefined;
  currentUser: User | undefined;
  emails : Email[] = [];
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  
  constructor(
    private _dialogRef: MatDialogRef<UserManagementAddEditComponent>,
    private _localizationService : LocalizationService,
    public _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _userService : UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.adminUser = this.data.adminUser;
    this.currentUser = this.data.user;
    this.isEditMode = this.data.isEditMode;
    
    this.formGroup = this._formBuilder.group({
      companies: new UntypedFormArray([], this.atLeastOneCompanyRequired()),       
    });

    if (this.adminUser && this.adminUser?.companies) {
      let elements = this.adminUser.companies 
      .filter(ac => !(ac.companyType === 'SELF' && ac.source === 'COMSII')) as Company[];

      elements.forEach(element => {
        let i = this.adminUser!.companies.indexOf(element)
        this.adminUser!.companies.splice(i, 1)
      });

      this.adminUser?.companies
      .forEach(comp => {
        let status: boolean = false;
        if (this.currentUser?.companies)
          status = (this.currentUser?.companies as Company[]).some(x => x.referenceNumber === comp.referenceNumber);
        this.companiesFormArray.push(new UntypedFormControl(status));
      });
    }
  }

  ngOnInit(): void {}

  @ViewChild("chipList") chipList: any;
  
  add(event: MatChipInputEvent): void {
    let values = (event.value || '').split(/[\s\r\n\t,;]+/);
    values.forEach(value => {
      if (value) {
        let errors = new UntypedFormControl(value, 
          Validators.compose([CustomValidators.isEmailValid(), Validators.required]))
          .errors;   
        this.emails.push({name: value, valid: (errors == null ? true : false)});
      }
    });    
    this.chipList.errorState = this.hasEmailListError();
    event.chipInput!.clear();   
  }

  remove(email: Email): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
    this.chipList.errorState = this.hasEmailListError();
  }

  hasEmailListError(): boolean {
    let hasError = false;
    if (this.emails.length === 0) hasError = true;
    else if (this.emails.some(x => !x.valid )) hasError = true;
    return hasError;
  }

  getEmailListError(): string {
    if (this.emails.length === 0)
      return this._localizationService.translate('loginuser_email_required')
    else if (this.emails.some(x => !x.valid ))
      return this._localizationService.translate('loginuser_email_invalid');
    return '';
  }

  get companiesFormArray() {
    return this.formGroup.controls['companies'] as UntypedFormArray;
  }

  atLeastOneCompanyRequired(): ValidatorFn {
    return (control: AbstractControl) => {
      const controlArray = control as UntypedFormArray;
      if (controlArray.controls.some(el => el.value === true)) {
        return null;
      } else {
        return {
          atLeastOneCompany: { atLeastOneCompany: false }
        }
      }
    }
  }

  addUser(){    
    let selectedCompanies = [] as InviteUserCompany[];
    this.formGroup.controls['companies'].value
    .forEach((value: boolean, index: number) => {
      if (value === true){
        let selectedCompany = this.adminUser?.companies[index];
        selectedCompanies.push({
          ReferenceNumber: selectedCompany?.referenceNumber,
          Name: selectedCompany?.name,
          Source: selectedCompany?.source
        } as InviteUserCompany)
      }          
    });

    let inviteUser = this.createInviteUser(selectedCompanies);

    this._userService.inviteUser(inviteUser).subscribe({
      next: (_ => this._dialogRef.close()),
      error: (_ => alert(this._localizationService.translate('user_management_add_user_error'))) 
    });
  }

  private createInviteUser(selectedCompanies: InviteUserCompany[]): InviteUser { 
    return {
      InviterEmail: this.adminUser?.emailAddress,
      InvitationSource: InvitationSource.INTERNAL,
      InvitationType: InvitationType.ADD,
      EmailAddress: '',
      Source: "CITYONSITE",
      EmailAddresses: this.emails.map(e => {return e.name}),
      Companies: selectedCompanies
    } as InviteUser;
  }

  editUser(){
    if (this.currentUser){

    let updatedUserCompanies = {
      SelectedCompanies: [],
      UnselectedCompanies: []
    } as UpdatedUserCompanies;

    this.formGroup.controls['companies'].value
      .forEach((value: boolean, index: number) => {
        let adminCompany = this.adminUser?.companies[index];
        if (adminCompany){
          if (value === true)
            updatedUserCompanies.SelectedCompanies.push(adminCompany);
          else updatedUserCompanies.UnselectedCompanies.push(adminCompany);
        }    
      });

      this._userService
      .updateCompanies(
        this.currentUser.id, 
        this.currentUser.emailAddress, 
        this.adminUser!.emailAddress, 
        updatedUserCompanies)
      .subscribe({
        next: (result => this._dialogRef.close(result)),
        error: (_ => alert(this._localizationService.translate('user_management_edit_user_error'))
        ) 
      });
    }    
  }

  disableSubmitButton(){
    return !this.isEditMode && (!this.formGroup.valid || this.hasEmailListError());
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }
}