import { Component, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit {

  @Input('question') question : any;

  @ViewChild('signatureWrapper') signatureWrapper: ElementRef | undefined;
  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;

  signaturePadOptions: NgSignaturePadOptions = { 
    minWidth: 2,
    canvasWidth: window.innerWidth * 0.92,
    canvasHeight: 150,
    canvasBackground: 'white'
  };


  constructor() { }

  ngAfterViewInit(): void {
    if (this.question){
      if (this.question.answer === null || this.question.answer === undefined || this.question.answer === "") 
      {
        this.signaturePad?.clear();
      } else {
        this.signaturePad?.clear();
        this.signaturePad?.fromDataURL(this.question.answer);
      }

      this.setSignaturePad();
    }
  }

  drawComplete(event: MouseEvent | Touch) {
    this.question.answer = this.signaturePad?.toDataURL();
  }

  drawStart(event: MouseEvent | Touch) {
  }

  clearSignature() {
    this.signaturePad?.clear();
    this.question.answer = "";
  }

  setSignaturePad() {
    if (this.signatureWrapper && this.signaturePad) {
      this.signaturePad.getCanvas().width = this.signatureWrapper.nativeElement.clientWidth - 1;
      this.signaturePad.getCanvas().height = this.signatureWrapper.nativeElement.clientHeight - 1;
    }
  }

  resizeSignaturePad() {
    this.setSignaturePad();
    this.clearSignature();
  }
}
