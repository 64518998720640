import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { catchError, Observable, of, tap } from 'rxjs';
import { InductionConfig } from 'src/app/models/inductionconfig.model';
import { StorageKey } from 'src/app/shared/enums';
import { environment } from 'src/environments/environment';
import { BaseService } from '../base/base.service';
import { InductionQnA } from '@app/models/inductionqna.model';

@Injectable({
  providedIn: 'root'
})
export class FormService extends BaseService {
  readonly KEY = StorageKey[StorageKey.FORM];
  readonly PERMITQNA_KEY = StorageKey[StorageKey.PERMITQNA];
  readonly BASE_URL = `${environment.gatewayAPI_V1}/forms/`;
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getByBrandOrSiteId(brandId: string, siteId: string): Observable<InductionConfig | null> {
    
    const url = `${this.BASE_URL}forms/getbybrandorsiteid?brandId=${brandId}&siteId=${siteId}`;
    //const url = `http://localhost:7577/api/v1/forms/getbybrandorsiteid?BrandId=${brandId}&siteId=${siteId}`;
     
    return this.httpClient.get<InductionConfig>(url).pipe(
      tap(_ => _), catchError(this.handleError)
      );
  }

  async getInductionConfig(): Promise<InductionConfig>{
    const item = await Preferences.get({ key: this.KEY });
    var obj;
    if(item.value != null)
      obj = JSON.parse(item.value);
      
    return obj;
  }

  async setInductionConfig(form: InductionConfig) {
      await Preferences.set({
          key: this.KEY,
          value: JSON.stringify(form),
        });
  }

  async removeInductionConfig() {
      await Preferences.remove({ key: StorageKey[this.KEY]});
  }

  async setPermitQnA(inductionQnA: InductionQnA) {
    await Preferences.set({
        key: this.PERMITQNA_KEY,
        value: JSON.stringify(inductionQnA),
      });
  }

  async getPermitQnA(): Promise<InductionQnA>{
    const item = await Preferences.get({ key: this.PERMITQNA_KEY });
    var obj;
    if(item.value != null)
      obj = JSON.parse(item.value);
      
    return obj;
  }

  async removePermitQnA() {
      await Preferences.remove({ key: StorageKey[this.PERMITQNA_KEY]});
  }
  
}
