import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersion } from '@app/models/appversion.model';
import { Version } from '@app/shared/enums';
import { environment } from '@env/environment';
import { Observable, catchError, of, tap } from 'rxjs';
import { BaseService } from '../base/base.service';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class VersionService extends BaseService {
  readonly KEY_LATESTAPPVERSION = Version[Version.LATESTAPPVERSION];
  readonly KEY_ISFORCE = Version[Version.ISFORCE];
  readonly KEY_PREVIOUSVERSIONCHECKTIME = Version[Version.PREVIOUSVERSIONCHECKTIME];
  readonly BASE_URL = `${environment.gatewayAPI_V1}/References/`

  constructor(private httpClient: HttpClient) { 
    super();
  }

  public GetLatestAppVersion(): Observable<AppVersion>{
    const url = `${this.BASE_URL}AppVersion/GetLatestAppVersion`;
    // const url = `https://localhost:44364/api/v1/AppVersion/GetLatestAppVersion`;

    return this.httpClient.get<AppVersion>(url)
      .pipe(
        tap(_ => _),
        catchError(this.handleError));
  }

  public async setVersion(version: string)
  {
    await Preferences.set({
      key: this.KEY_LATESTAPPVERSION,
      value: version
    });
  }

  public async getVersion(): Promise<string>
  {
    const item = await Preferences.get({ key: this.KEY_LATESTAPPVERSION });
    if(item.value != null)
    {
      return item.value
    }

    return '';
  }

  public async setIsForce(isForce: string)
  {
    await Preferences.set({
      key: this.KEY_ISFORCE,
      value: isForce
    });
  }

  public async getIsForce(): Promise<boolean>
  {
    const item = await Preferences.get({ key: this.KEY_ISFORCE });
    if(item.value != null)
    {
      return JSON.parse(item.value);
    }

    return false;
  }

  public async setPreviousVersionCheckTime(previousVersionCheckTime: number)
  {
    await Preferences.set({
      key: this.KEY_PREVIOUSVERSIONCHECKTIME,
      value: JSON.stringify(previousVersionCheckTime)
    });
  }

  public async getPreviousVersionCheckTime(): Promise<number>
  {
    const item = await Preferences.get({ key: this.KEY_PREVIOUSVERSIONCHECKTIME });
    if(item.value != null)
    {
      return Number(item.value);
    }

    return 0;
  }
}
