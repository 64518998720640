import { Component, OnInit, Input } from '@angular/core';
import { InductionQnA } from '../../models/inductionqna.model';

@Component({
  selector: 'app-questionrender',
  templateUrl: './questionrender.component.html',
  styleUrls: ['./questionrender.component.scss']
})
export class QuestionrenderComponent implements OnInit {

  @Input() question : any = '';
  constructor() { 
    
  }

  ngOnInit(): void {

  }

}
