import { Pipe, PipeTransform } from '@angular/core';
import { Site } from '@app/models/site.model';
import { SiteService } from '@app/services/site/site.service';

@Pipe({
  name: 'replacePlaceholder',
  pure: false
})
export class ReplacePlaceholderPipe implements PipeTransform {

  storedSite: Site = {} as Site;

  constructor(private _siteService: SiteService) {
    this.getSite();
  }

  transform(value: any) {

    let returnedValue = this.decodeHtmlEntities(value);

    if (this.storedSite) {
      returnedValue = returnedValue.replace('#onsite<site.code>', this.storedSite.code);
      returnedValue = returnedValue.replace('#onsite<site.id>', this.storedSite.id);
      returnedValue = returnedValue.replace('#onsite<site.name>', this.storedSite.name);
    }

    return returnedValue;
  }

  getSite() {
    this._siteService.getSignedInSite().then(site => {
      this.storedSite = site;
    });
  }

  decodeHtmlEntities(str: string) {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }

}