import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { Module, User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserService } from 'src/app/services/user/user.service';
import { Site } from '../../models/site.model';
import { SiteService } from '../../services/site/site.service';
import { LocalizationService } from '../internationalization/localization.service';
import { ActivatedRoute } from '@angular/router';
import { AppLauncher } from '@capacitor/app-launcher';
import { ClosedownService } from 'src/app/services/closedown/closedown.service';
import { UtilsService } from 'src/app/services/util/util-service';
import { environment } from 'src/environments/environment';
import { ProcessFlow } from 'src/app/models/processflow.model';
import { ConfigurationService } from '@app/services/configuration/configuration.service';

@Component({
  selector: 'app-closedown',
  templateUrl: './closedown.component.html',
  styleUrls: ['./closedown.component.scss']
})
export class ClosedownComponent implements OnInit {
  private unsubscriberBackBtn: Subject<void> = new Subject<void>();
  token: any = null;
  currentUser: User | undefined;
  withJob = false;
  withLink = false;
  site: Site | undefined;
  sites: Site[] | null = [];
  userLat: any = null;
  userLong: any = null;
  siteCode: any = null;
  siteId: any = null;
  jobId: any = null;
  resourceId: any = null;
  subContractorId: any = null;
  linkType: any = null;
  appURLName: string = "aus.cityholdings.cityonsite";

  constructor(private _authService: AuthService,
      private _siteService: SiteService,
      private _router: Router,
      private _localizationService: LocalizationService,
      private _navigationService: NavigationService,
      private _userService: UserService,
      private _activatedRoute: ActivatedRoute,
      private _utilsService: UtilsService,
      private _closedownService: ClosedownService,
      private _configurationService: ConfigurationService) {
      
  }

  async ngOnInit(): Promise<void> {
    this._navigationService.onChange(true, false);

    // Retrieve setting from local settings/API
    let shouldRetrieveFromAPI = await this._configurationService.shouldRetrieveFromAPI();
    let inductionModuleEnable = false;
    if(shouldRetrieveFromAPI){
      await this._configurationService.getAndSetLocalConfigurations(true);
    }
    inductionModuleEnable = await this._configurationService.getInductionModuleEnable();

    if (this._utilsService.isMobileAndTablet() && inductionModuleEnable) {
        this.checkCanOpenApps();
    }
    this.initBrowserBackButton();

      this.linkType = Number(this._activatedRoute.snapshot.paramMap.get('type') ?? 0);
      this.token = this._activatedRoute.snapshot.paramMap.get('token');
      if (this.token) {
          var tokenData = atob(this.token);
          var processflow: ProcessFlow = JSON.parse(tokenData);
          if (processflow.url) {
              processflow.url = btoa(processflow.url);
          } else {
              processflow.url = "";
          }
          processflow.token = this.token;
          processflow.linkType = this.linkType;
          if (!this._closedownService.isValidExpiryLink(processflow.expiry)) {
              alert(this._localizationService.translate("alert_workbench_link_expired"));
          }
          else {
              this._closedownService.setProcessFlow(processflow);
          }
      }

      this._authService.isAuthenticated().then(auth => {
          if (!auth) {
              this._router.navigate(['login']);
          }
          else {
              this._authService.getUser().then(user => {
                this.currentUser = user;
                this.checkSiteSignIn();
                this.loadUserModules();
                if (this.linkType == 2) {
                   this._router.navigate(['signin/true']);
                }
                else {
                   this._router.navigate(['signin']);
                }
              });

          }
      });
  }

  checkCanOpenApps = async () => {
      const { value } = await AppLauncher.canOpenUrl({ url: this.appURLName });
      if (value) {
          await AppLauncher.openUrl({ url: this.appURLName });
      }
  };

  initBrowserBackButton() {
      history.pushState(null, '');
      fromEvent(window, 'popstate').pipe(takeUntil(this.unsubscriberBackBtn)).subscribe((_) => {
          history.pushState(null, '');

          if (confirm(this._localizationService.translate('closedown_dialog_logout'))) {
              this._authService.logout().then(r => {
                  this._router.navigate(['login']);
              });
          }
      });
  }

  loadUserModules() {
      if (this.currentUser?.id) {
          this._userService.getUserModules().subscribe({
              next: (m: Module[]) => {
                  if (m) {
                      this._authService.setUserModule(m);
                  }
              },
              error: (e: string) => {
                  alert(this._localizationService.translate('closedown_error_usermodule') + e);
              }
          });
      }
  }

  // Seems that there are no conditons for this code to be executed, so unit test are not written.
  checkSiteSignIn() {
    this._siteService.getSignedInSite().then(site => {
      if (site && site.code && this.siteCode) {
        if (site.code.toLocaleLowerCase() != this.siteCode.toLocaleLowerCase()) {
          alert(this._localizationService.translate('closedown_signing_out').replace(/{site_name}/g, site.name));
          this._router.navigate(['sitesignout']);
        }
      }
    })
  }
}