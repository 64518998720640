<div class="dialog-container">
  <label class="attachmentFromProfileLabel" id="attachmentFromProfileLabel">
    {{ ('cityonsiteattachment_addfromprofile_lbl_header' | translate).replace("{document}", data.question.caption) }}
  </label>
  <div class="divider"></div>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="select" class="table_header">
      <th mat-header-cell *matHeaderCellDef fxFlex="8" fxLayoutAlign="start center">
        <mat-checkbox (change)="$event ? selectAll() : null"
          data-testid="addfromprofile_chbox_select_all"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" fxFlex="8" fxLayoutAlign="start center">
        <mat-checkbox (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="documentName" class="table_header">
      <th mat-header-cell *matHeaderCellDef  fxFlex="72" fxLayoutAlign="start center">
        {{displayDocumentHeader}}
      </th>
      <td mat-cell class="color-dark-blue" *matCellDef="let document" fxFlex="72" fxLayoutAlign="start center">
        {{document.documentName}}
      </td>
    </ng-container>  
    <ng-container matColumnDef="documentType" class="table_header">
      <th mat-header-cell *matHeaderCellDef  fxFlex="20" fxLayoutAlign="start center">
        {{displayDocumentTypeHeader}}
      </th>
      <td mat-cell class="color-dark-blue" *matCellDef="let document" fxFlex="20" fxLayoutAlign="start center">
        {{document.documentType}}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedDocumentColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedDocumentColumns;"></tr>
  </table>
  <div class="divider"></div>
  <div class="divider"></div>
  <button mat-stroked-button class="color-city-blue btn-border-color"
    data-testid="addfromprofile_btn_addselected"
    (click)="addSelected()" [disabled]="!isSelected()">
    {{ 'cityonsiteattachment_addfromprofile_btn_addselected' | translate }}
  </button>   
  <div class="divider"></div>
  <button mat-stroked-button class="color-city-blue btn-border-color"
    data-testid="addfromprofile_btn_cancel"
    (click)="dismissDialog()">
    {{ 'cityonsiteattachment_addfromprofile_btn_cancel' | translate }}
  </button>
  <div class="divider"></div>
</div>