import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-permit-separator',
  templateUrl: './permit-separator.component.html',
  styleUrls: ['./permit-separator.component.scss']
})
export class PermitSeparatorComponent {
  @Input('question') question : any;
  
  captionStyles() : object {
    return JSON.parse(this.question.CaptionStyles);
  }
}
