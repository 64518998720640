<form [formGroup]="formGroup">
    <div>
        <div fxLayout="row">
            <div fxLayout="column">
                <span class="font-size-h1 color-city-blue">{{ 'register_lbl_one_register' | translate }}</span>
                <span class="font-size-body-text color-dark-blue">{{ 'register_lbl_one_registerinfo' | translate }}</span>
            </div>
        </div>

        <div>

            <div class="divider"></div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="100%">
                   <div>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="color-city-blue">{{'register_lbl_one_email' | translate }}</mat-label>
                            <input matInput placeholder="{{'register_lbl_one_email' | translate }}" formControlName="emailAddress" required id="input_emailaddress">
                            <mat-error *ngIf="hasError(formGroup, 'emailAddress')">{{getError(formGroup, 'emailAddress')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!hasSendCode">
                        <button id="btn_sendverificationcode" mat-raised-button class="sendVerification-btn" (click)="sendCode()" [disabled]="disableCodeButton()">
                            {{'register_btn_one_code' | translate }} 
                        </button>
                    </div>
                    <div *ngIf="hasSendCode">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="color-city-blue">{{'register_lbl_one_code' | translate }}</mat-label>
                            <input matInput placeholder="{{'register_lbl_one_code' | translate }}" formControlName="code" required id="input_code">
                            <mat-error *ngIf="hasError(formGroup, 'code')">{{getError(formGroup, 'code')}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="color-city-blue">{{'register_lbl_one_firstname' | translate }}</mat-label>
                            <input matInput placeholder="{{'register_lbl_one_firstname' | translate }}" formControlName="firstname" required id="input_firstname">
                            <mat-error *ngIf="hasError(formGroup, 'firstname')">{{getError(formGroup, 'firstname')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="color-city-blue">{{'register_lbl_one_lastname' | translate }}</mat-label>
                            <input matInput placeholder="{{'register_lbl_one_lastname' | translate }}" formControlName="lastname" required id="input_lastname">
                            <mat-error *ngIf="hasError(formGroup, 'lastname')">{{getError(formGroup, 'lastname')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="color-city-blue">{{'register_lbl_one_mobile' | translate }}</mat-label>
                            <input matInput placeholder="{{'register_lbl_one_mobile' | translate }}" formControlName="mobile" required id="input_mobile">
                            <mat-error *ngIf="hasError(formGroup, 'mobile')">{{getError(formGroup, 'mobile')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="color-city-blue">{{'register_lbl_one_password' | translate }}</mat-label>
                            <input matInput type="password" placeholder="{{'register_lbl_one_password' | translate }}" formControlName="password" required id="input_password">
                            <mat-error *ngIf="hasError(formGroup, 'password')">{{getError(formGroup, 'password')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="color-city-blue">{{'register_lbl_one_confirmpassword' | translate }}</mat-label>
                            <input matInput type="password" placeholder="{{'register_lbl_one_confirmpassword' | translate }}" formControlName="confirmpassword" required id="input_confirmpassword">
                            <mat-error *ngIf="hasError(formGroup, 'confirmpassword')">{{getError(formGroup, 'confirmpassword')}}</mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxFlex="100%" fxLayoutGap="1em">
                        <button id="btn_continue" mat-raised-button (click)="continueOnClick()" [disabled]="disableContinueButton()">
                            {{'register_btn_one_continue' | translate }} 
                        </button>
                       
                        <button id="btn_cancel" mat-raised-button (click)="cancelOnClick()" >
                            {{'register_btn_one_cancel' | translate }} 
                        </button>
                    </div>
                </div>
            
            </div>
        </div>
    </div>

    <div class="divider"></div>
    <div class="divider"></div>
    <div class="divider"></div>
   
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center">
            <span class="font-size-body-text color-dark-blue">{{ 'register_lbl_one_agree' | translate }}</span>
            <a href="www.google.com">{{ 'register_lbl_one_conditionofuse' | translate }}</a>
        </div>
    </div>
   
</form>
