<div>
  <div fxLayout="row">
      <div fxLayout="column">
          <span class="font-size-h1 color-city-blue">{{'register_step3_header' | translate }}</span>
          <span class="font-size-body-text color-dark-blue">{{'register_step3_details' | translate }}</span>
      </div>
  </div>
  <div class="divider"></div>

  <app-user-documents-list></app-user-documents-list>

</div>
  
<div class="close-button">
   <button type="submit" id="register3_continue" mat-raised-button (click)="continueRegister()">
      {{'register_continue' | translate }}
   </button>
</div>   