<div fxLayout="row" fxLayoutAlign="space-between end">
  <h5>{{question.question.caption}} <mat-icon>help_outline</mat-icon>
    <mat-label *ngIf="question.question.isRequired" class="color-city-red"> *</mat-label>
  </h5>
  <button id="btn_attachfile" data-testid="btn_attachfile" mat-fab color="primary" (click)="attachFile()">
    <mat-icon>note_add</mat-icon>
  </button>
</div>
<div class="divider"></div>
<mat-divider class="table-divider-header"></mat-divider>
<div>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="fileName" class="table_header">
      <th mat-header-cell *matHeaderCellDef fxFlex="70" fxLayoutAlign="start center"> {{displayDocumentHeader}} </th>
      <td mat-cell class="font-size-headline color-dark-blue" *matCellDef="let document" fxFlex="70"
        fxLayoutAlign="start center"> {{document.fileName}} </td>
    </ng-container>
    <ng-container matColumnDef="rowbuttons">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions" fxFlex="30" fxLayoutAlign="end center">&nbsp;</th>
      <td mat-cell *matCellDef="let document;" fxFlex="30" fxLayoutAlign="end center">
        <mat-icon color="primary" *ngIf="document.fileType !== 'EXTERNAL'" data-testid="btn_downloadfile"
          (click)="download(document)">
          visibility
        </mat-icon>
        <mat-icon class="display-grey-icon color-city-neutral" *ngIf="document.fileType === 'EXTERNAL'">
          visibility
        </mat-icon>
        <mat-icon color="primary" data-testid="btn_deletefile" (click)="delete(document)">
          delete
        </mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedDocumentColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedDocumentColumns;"></tr>
  </table>
</div>