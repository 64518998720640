import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SiteService } from 'src/app/services/site/site.service';
import { SiteApiService } from 'src/app/services/site/site.api.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { UtilsService } from 'src/app/services/util/util-service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { LocalizationService } from '../internationalization/localization.service';
import { NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { Module, User } from 'src/app/models/user.model';
import { FormService } from 'src/app/services/form/form.service';
import { JobService } from 'src/app/services/job/job.service';
import { Site } from 'src/app/models/site.model';
import { ClosedownService } from '@app/services/closedown/closedown.service';
import { BarcodeScannerService } from '@app/services/barcodescanner/barcode-scanner.service';
import { Browser } from '@capacitor/browser';
import { GeolocationService } from '@app/services/geolocation/geolocation.service';
import { UserService } from '@app/services/user/user.service';

@Component({
  selector: 'app-qr-sign-in',
  templateUrl: './qr-sign-in.component.html',
  styleUrls: ['./qr-sign-in.component.scss']
})

export class QrSignInComponent implements OnInit, AfterViewInit, OnDestroy {
  scanActive: boolean = false;
  isScanning: boolean = false;
  isMobileNative: boolean = false;
  isMobileDevice: boolean | undefined;
  user!: User;
  userLat: any = null;
  userLong: any = null;
  public webQRScanner: any;
  SUB_CONTRACTOR: string = 'SUBCON';
  locationMessage: string = '';
  siteId: string | undefined;
  showSpinner: boolean = false;

  public config: ScannerQRCodeConfig = {
    constraints: { 
      video: {
        width: window.innerWidth
      }
    }
  };

  constructor(private _router: Router,
    private _siteService: SiteService,
    private _siteApiService: SiteApiService,
    private _loader: LoaderService,
    private _utilsService: UtilsService,
    private _authService: AuthService,
    private _navigationService: NavigationService,
    private _localizationService: LocalizationService,
    private _formService: FormService,
    private _qrcode: NgxScannerQrcodeService,
    private _closedownService: ClosedownService,
    private _jobService: JobService,
    private _barcodeScannerService: BarcodeScannerService,
    private _geolocationService: GeolocationService,
    private _userService: UserService
  ) {

    this._navigationService.onChange(false, false);
    this.isMobileNative = this._utilsService.isMobileAndTablet();
    this.isMobileDevice = this._utilsService.isMobileDevice();

  }

  ngOnInit(): void {
    this.initialize();
  }

  ngAfterViewInit(): void {
  }

  async initialize(): Promise<void> {
    try {
      await this.initializeUser();
      await this.initializeToken();
      await this.getGeoLocation();
    } catch (error) {
      this.handleError(error);
    }
  }

  private async initializeUser(): Promise<void> {
    this.siteId = await this._siteService.getQrSiteId();
    if (this.siteId) {
      this.showSpinner = true;
    }
    this.user = await this._authService.getUser();
    await this.loadUserModules();
    await this._closedownService.setCurrentUser(this.user);
  }

  private async initializeToken(): Promise<void> {
    const token = await this._authService.getTokenFromStorage();
    this._closedownService.setBearerToken(token);
  }

  private handleError(error: any): void {
    console.error('Initialization failed:', error);
    this._router.navigate(['login']);
  }

  async startScanner() {

    this.isScanning = true;
    this.scanActive = true;
    document.body.style.background = "transparent";
    const result = await this._barcodeScannerService.scanBarcode(0);
    if (result !== undefined) {
      this.qrScanned(result);
    }else {
      alert(this._localizationService.translate("qrsignin_dialog_no_site_found"));
    }
  }

  private getNearbySiteBySiteId(lat: number, long: number, siteId: string) {
    this.showSpinner = true;
    this._siteApiService.getNearbySiteBySiteId(lat, long, siteId)
      .subscribe({
        next: (site) => {
          if (site) {
            this._siteService.setSignedInSite(site).then(res => {
              if (site.isInductionRequired) {
                this.getSiteInductions(site);
              } else {
                this.getUserJobs(site);
              }
            });
          }
        },
        error: (reject) => {
          alert(this._localizationService.translate("qrsignin_dialog_no_site_found"));
          this.reloadScanner();
          this.showSpinner = false;
        }
      });
  }

  getUserJobs(site: Site) {
    let userCompanies: string[] = [];
    if (this.user) {
      if (this.user.companies) {
        this.user.companies.forEach(company => {
          if (company.companyType.toUpperCase() != this.SUB_CONTRACTOR) {
            userCompanies.push(company.referenceNumber);
          }
        });
      }

      this._jobService.getJobsByCompanyRefNumber(site.id).subscribe({
        next: jobs => {
          this._closedownService.setUserJobs(jobs);
          if (site.isInductionRequired) {
            if (jobs && jobs.length > 0) {
              this._router.navigate(['induction'], {
                queryParams: {
                  inductionSequence: 1
                }
              });
            } else {
              this._router.navigate(['signedinvisitor']);
            }
          }
          else {
            if (jobs && jobs.length > 0) {
              this._closedownService.getProcessFlow().then(processFlow => {
                this._loader.show();
                processFlow.siteCode = site?.code;
                this._closedownService.setProcessFlow(processFlow).then(cs => {
                  this._closedownService.directToClosedown(false, undefined, this._closedownService.getSignInBackUrl());
                  this.reloadWhenBrowserClose();
                }).catch(e => { this._loader.hide(); });
              }).catch(e => { this._loader.hide(); });
            } else {
              alert(this._localizationService.translate("sitesignin_nocity_jobs"));
            }
          }
        },
        error: e => {
          alert(this._localizationService.translate("job_dialog_failed"));
          this._siteService.removeSignedInSite();
          this._router.navigate(['signin']);
        }
      });
    }
    this.showSpinner = false;
  }

  getSiteInductions(site: Site) {
    this._formService.getByBrandOrSiteId(site.brand.id, site.id).subscribe({
      next: (form) => {
        if (form) {
          this._formService.setInductionConfig(form).then(r => {
            this.getUserJobs(site);
          });
        }
        else {
          alert(this._localizationService.translate("induction_dialog_siteinduction_notexist"));
          this._siteService.removeSignedInSite();
          this._router.navigate(['signin']);
        }
      },
      error: e => {
        alert(this._localizationService.translate("induction_dialog_siteinduction_notexist"));
        this._siteService.removeSignedInSite();
        this._router.navigate(['signin']);
      }
    });
  }

  reloadScanner() {
    if (this._utilsService.isMobileAndTablet()) {
      this.startScanner();
    } else {
      if (this.webQRScanner) {
        this.webQRScanner['stop']().subscribe();
        this.scanActive = false;
      }
    }
  }

  ngOnDestroy() {
    this.scanActive = false;

    if (this._utilsService.isMobileAndTablet()) {
    } else {
      if (this.webQRScanner) {
        this.webQRScanner['stop']().subscribe();
      }
    }
  }

  public qrWebScanned(scannedObj: any){
    if(scannedObj){

    }else{
      alert(this._localizationService.translate("qrsignin_dialog_no_site_found"));
        this.showSpinner = false;
    }
  }

  public qrScanned(url: any) {

    if (url && this.isScanning) {
      this.showSpinner = true;
      this.scanActive = false;
      this.isScanning = false;

      const pathArray = url.split("/");
      const appPath = pathArray.pop();

      var siteId = appPath;
      if(siteId){
        this.getNearbySiteBySiteId(this.userLat, this.userLong, siteId);
      }else{
        alert(this._localizationService.translate("qrsignin_dialog_no_site_found"));
        this.showSpinner = false;
      }
    }
    else {
      this._router.navigate(['signin']);
    }
  }

  private async getGeoLocation() {

    let latestLocation = this._geolocationService.getLatestLocation();
    if (latestLocation === null) {
      this._loader.show();
      latestLocation = await this._geolocationService.setLatestLocation();
      this._loader.hide();
    }

    if (latestLocation) {
      this.userLat = latestLocation.coords.latitude;
      this.userLong = latestLocation.coords.longitude;

      if (this.isMobileDevice) {
        if (this.siteId) {
          this.getNearbySiteBySiteId(this.userLat, this.userLong, this.siteId);
        }
        else {
          if (this.isMobileNative) {
            this.startScanner();
          }
        }
      } else {
        this.showSpinner = false;
      }

      this._siteService.removeQRSiteId();

    } else {
      this.locationMessage = this._localizationService.translate("qrsignin_device_location_turn_off");
    }
  }

  public handle(action: any, fn: string): void {
    this.webQRScanner = action;
    this.webQRScanner.start();
    this.scanActive = !this.scanActive;
    this.isScanning = true;
  }

  onError(e: any) {
    alert(e);
  }

  reloadWhenBrowserClose() {
    if (this._utilsService.isMobileAndTablet()) {
      Browser.addListener('browserFinished', () => {
        Browser.removeAllListeners().then(s => { });
        location.replace('signin');
      });
    }
  }

  back() {
    this._router.navigate(['signin']);
  }

  private async loadUserModules() {
    this._userService.getUserModules().subscribe({
      next: (m: Module[]) => {
        if (m) {
          this._authService.setUserModule(m);
        }
      },
      error: (e: string) => {
        alert(this._localizationService.translate('closedown_error_usermodule') + e);
      }
    });
  }

}
