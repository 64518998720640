<div class="dialog-container">
    <label class="addDocumentLabel" id="deleteRapidGlobalIdLabel">
      {{ ('user_documents_remove_header' | translate) }}
    </label>
    <div>{{userDocument?.documentType}} #{{userDocument?.documentName}}</div>
    <label class="addDocumentLabel" id="deleteRapidGlobalIdLabel">
      {{ ('user_documents_remove_info' | translate) }}
    </label>
    <div class="divider"></div>
    <button type="submit" mat-raised-button
      id="user_documents_delete_submit"
      data-testid="user_documents_delete_submit"
      (click)="deleteUserDocument()">
      {{'user_documents_remove_submit' | translate }}
    </button>
    <div class="divider"></div>
    <button mat-stroked-button class="color-city-blue btn-border-color"
      data-testid="user_documents_delete_cancel" 
      (click)="dismissDialog()">
      {{ 'user_documents_addextlink_btn_cancel' | translate }}
    </button>   
</div>