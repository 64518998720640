import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from '@app/components/internationalization/localization.service';
import { VisitorType } from '@app/models/visitortype.model';
import { VisitReason } from '@app/models/visitreason.model';
import { ReferenceService } from '@app/services/reference/reference.service';

@Component({
  selector: 'app-reason-for-visit-add-edit',
  templateUrl: './reason-for-visit-add-edit.component.html',
  styleUrls: ['./reason-for-visit-add-edit.component.scss']
})
export class ReasonForVisitAddEditComponent {

  public formGroup!: UntypedFormGroup;
  allVisitReasons: VisitReason[] = [];
  visitreason: VisitReason | undefined;
  visitorType: VisitorType | undefined;
  addEditPopupHeader: string = "";
  isEditMode: boolean = false;

  originalFormGroup: any = {
    reasonDescription: ''
  }

  constructor(private _localizationService: LocalizationService,
    private _referenceService: ReferenceService,
    private _dialogRef: MatDialogRef<ReasonForVisitAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      reason: VisitReason | undefined;
      type: VisitorType | undefined;
      reasonList: VisitReason[];
    },
    private _formBuilder: UntypedFormBuilder
  ) {
    this.formGroup = this.initFormGroup();
  }

  ngOnInit(): void {
    this.visitreason = this.data.reason;
    this.visitorType = this.data.type;
    this.allVisitReasons = this.data.reasonList;
    this.isAddOrEdit();
    this.populateFormWIthVisitReason();
  }

  private initFormGroup(): UntypedFormGroup {
    const formGroup = this._formBuilder.group({
      description: ['', [Validators.required, Validators.maxLength(250)]]
    });
    return formGroup;
  }

  private populateFormWIthVisitReason() {
    if (this.visitreason) {
      this.formGroup.patchValue({
        description: this.visitreason.description,
      });
      this.originalFormGroup.reasonDescription = this.visitreason.description;
    }
  }

  isAddOrEdit() {
    if (this.visitreason) {
      this.isEditMode = true;
    }
  }

  addVisitReason(visitReason: VisitReason) {
    this._referenceService.addVisitReason(visitReason).subscribe({
      next: (vr) => {
        if (vr != null) {
          this.dismissDialog(vr);
        }
      },
      error: (error) => {
        console.error(this._localizationService.translate("visitor_type_management_reason_for_visit_addEdit_snackbar_error_adding_visit_reason"), error);
      }
    })
  }

  updateVisitReason(visitReason: VisitReason) {
    this._referenceService.updateVisitReason(visitReason).subscribe({
      next: (vr) => {
        if (vr != null) {
          this.dismissDialog(vr);
        }
      },
      error: (error) => {
        console.error(this._localizationService.translate("visitor_type_management_reason_for_visit_addEdit_snackbar_error_updating_visit_reason"), error);
      }
    })
  }

  isVisitReasonExisted(visitReason: VisitReason): boolean {
    const isExisted = this.allVisitReasons.some((vs) => {
      return vs.visitorTypeId === visitReason.visitorTypeId &&
        vs.description.toLowerCase() === visitReason.description.toLowerCase().trim();
    });

    if (isExisted) {
      alert(this._localizationService.translate("visitor_type_management_reason_for_visit_addEdit_snackbar_error_visit_reason_existed"));
    }

    return isExisted;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = form.controls[controlName].hasError('maxlength');
    return validationOutput;
  }

  saveOnClick() {
    if (this.formGroup.valid && this.visitorType && this.visitorType.id && this.visitorType.id !== '') {
      if (this.isEditMode && this.visitreason) {
        const updatedVisitReason: VisitReason = {
          id: this.visitreason.id,
          visitorTypeId: this.visitorType.id!,
          isActive: this.visitorType.isActive,
          description: this.formGroup.get('description')?.value,
          languages: this.visitreason.languages !== null ? this.visitreason.languages : undefined,
        }
        const isExisted = this.isVisitReasonExisted(updatedVisitReason);
        if (!isExisted) {
          this.updateVisitReason(updatedVisitReason);
        }
      } else {
        const newVisitReason: VisitReason = {
          id: undefined,
          visitorTypeId: this.visitorType.id!,
          isActive: true,
          description: this.formGroup.get('description')?.value,
          languages: undefined
        };
        const isExisted = this.isVisitReasonExisted(newVisitReason);
        if (!isExisted) {
          this.addVisitReason(newVisitReason);
        }
      }
    }
  }

  disableSaveOnClick(): boolean {
    const currentDescription = this.formGroup.get('description')?.value;

    if (currentDescription === this.originalFormGroup.reasonDescription) {
      return true;
    }

    return this.formGroup.invalid;
  }

  dismissDialog(result?: VisitReason) {
    this._dialogRef.close(result);
  }

}
