<div class="main-class">
    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-h1 color-city-blue">{{ welcome() }}</span>
            <span class="font-size-body-text color-dark-blue">{{ welcomeDesc() }}</span>
        </div>
    </div>
    <div class="divider"></div>
    <div *ngIf="!inductionModuleEnable && !isExternalUser() && !byPass()">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="100%">
                <button mat-raised-button (click)="workBenchClick()" id="btn_workbench" [disabled]="workBenchClicked">
                    {{ 'signin_btn_workbench' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="inductionModuleEnable">
        <div *ngIf="isResourceAdminUserAndBrowser() && isResourceAdminUserAndBrowser() !== undefined">
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="100%">
                    <button mat-raised-button (click)="workBenchClick()" id="btn_workbench" [disabled]="workBenchClicked">
                        {{ 'signin_btn_workbench' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="!isResourceAdminUserAndBrowser() && isResourceAdminUserAndBrowser() !== undefined">
            <div *ngIf="!withFastLink && withFastLink !== undefined">
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="100%">
                        <button mat-raised-button (click)="qrCodeSignInOnClick()" id="btn_qrcodesignin">
                            <mat-icon>qr_code_scnanner</mat-icon> &nbsp;&nbsp;&nbsp;
                            {{ 'signin_lbl_sign_in_qr_code' | translate }}
                        </button>
                    </div>
                </div>
                <div class="divider"></div>
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="100%">
                        <button mat-raised-button (click)="siteSignInOnClick()" id="btn_sitesignin">
                            <mat-icon>wifi</mat-icon> &nbsp;&nbsp;&nbsp;
                            {{ 'signin_lbl_sign_in_gps' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="withFastLink && withFastLink !== undefined">
                <div *ngIf="fastLinkNearByMessage == ''">
                    <div fxLayout="row">
                        <div fxLayout="column" fxFlex="100%">
                            <button mat-raised-button (click)="ContinueClick()" id="btn_continue"
                                [disabled]="!currentSite">
                                {{ 'signin_btn_continue' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="fastLinkNearByMessage != ''">
                    <div fxLayout="row">
                        <div fxLayout="column" fxFlex="100%">
                            <div class="alert alert-danger">
                                <p>
                                    {{fastLinkNearByMessage}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="divider"></div>
  
          <div *ngIf="showLoadingJobs" class="loadingJobsSpinner" fxLayout="column" fxLayoutAlign="{{ loadingJobsAlignment()}}">
            <div>
                <span class="font-size-body-text color-dark-blue">{{ loadingJobs() }}</span>
            </div>
            <div class="divider"></div>
              <div>
                <mat-progress-spinner class="spinner" mode="indeterminate">
                </mat-progress-spinner>
              </div>
              <div class="divider"></div>
              <div class="divider"></div>
          </div>
          
          <div *ngIf="withJob && modulesExist">
                <a class="color-city-blue" id="btn_remotefix" (click)="RemoteFixClick()">{{'signin_btn_remotefix' |
                    translate }}</a>
            </div>
        </div>
        <div class="divider"></div>
        <div
            *ngIf="jobs.length > 0 && !isResourceAdminUserAndBrowser() && isResourceAdminUserAndBrowser() !== undefined"
            [ngClass]="jobListClass()"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="500"
            (scrolled)="onScroll()"
            [scrollWindow]="false">
            <div *ngFor="let job of jobsToDisplay">
                <div fxLayout="column" fxFlex="100">
                    <div fxLayout="row" fxFlex="100">
                        <div fxFlex="100" >
                            <mat-expansion-panel class="expansion-panel">
                                <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'"
                                    class="expansion-header">
                                    <mat-panel-title class="name" fxFlex="100">
                                        <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100">
                                            <div class="color-city-blue display-bold">
                                                {{job.site.name}}
                                            </div>
                                            <div *ngIf="job.subSite != null" class="color-city-blue">
                                                {{job.subSite}}
                                            </div>
                                            <div class="joblabel color-city-neutral">
                                                #{{job.referenceNumber}}
                                            </div>
                                            <div class="faultservicelabel color-dark-blue">
                                                {{job.faultServiceName}}
                                            </div>

                                            <div *ngIf="job.eta != null" fxLayout="row" fxFlex="100">
                                                <div class="job-icon">
                                                    <mat-icon class="color-city-blue">today</mat-icon>
                                                </div>
                                                <div *ngIf="isReactiveJob(job)" class="color-city-blue eta-lbl-font"
                                                    >
                                                    ETA {{job.eta | date:'dd-MM-yyyy hh:mm a' }}
                                                </div>
                                                <div *ngIf="isPPMJob(job)" class="color-city-blue eta-lbl-font"
                                                    >
                                                    Due On {{job.eta | date:'dd-MM-yyyy' }}
                                                </div>
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div fxLayout="column" fxFlex="100">
                                    <div class="expandedheaderlabel color-city-neutral">
                                        {{'common_lbl_department' | translate}}
                                    </div>
                                    <div class="expandedlabel color-dark-blue">
                                        {{job.departmentName}}
                                    </div>
                                    <div class="expandedheaderlabel color-city-neutral">
                                        {{'common_lbl_notes' | translate}}
                                    </div>
                                    <div class="expandedlabel color-dark-blue">
                                        {{job.notes}}
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <div class="divider"></div>
                        </div>

                    </div>
                    <mat-divider></mat-divider>
                </div>

            </div>
        </div>
    </div>
</div>