import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppNavigationModel } from 'src/app/models/appnavigation.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  messageSubject = new Subject<AppNavigationModel>();
  constructor() { }

  onChange(enableMenu?: boolean, 
    enableBackButton?: boolean,
    remainHeaderPadding? :boolean){
    var model = new AppNavigationModel();
    
    model.enableMenu = enableMenu ? enableMenu : false;
    model.enableBackButton = enableBackButton ? enableBackButton : false;
    model.remainHeaderPadding = remainHeaderPadding ? remainHeaderPadding : false;

    this.messageSubject.next(model);
  }
}
