<div class="dialog-container">
    <label class="attachmentAddFileLabel" id="attachmentAddFileLabel">{{ ('cityonsiteattachment_addfile_lbl_header' | translate).replace("{document}", data.question.caption) }}</label>
    <div class="divider"></div>

    <div fxLayout="column" class="button-container">
        <input #fileUpload type="file" 
            data-testid="cityonsiteattachment_input"
            (change)="importFile($event, data.question.caption)" class="fileUpload" accept="{{supportedFormat}}">
        <button mat-stroked-button  class="color-city-blue" (click)="fileUpload.click()">
            <mat-icon>add_photo_alternate</mat-icon>
            <span class="span-text">{{ 'cityonsiteattachment_addfile_btn_addnew' | translate }}</span>  
        </button>    
    </div>

    <div class="divider"></div>

    <div fxLayout="column" class="button-container">
        <button mat-stroked-button class="color-city-blue"
            data-testid="cityonsiteattachment_openFromProfile"
            (click)="openFromProfile()">
            <mat-icon>swap_horiz</mat-icon>
            <span class="span-text">{{ 'cityonsiteattachment_addfile_btn_addfromprofile' | translate }}</span>  
        </button>    
    </div>

    <div class="divider"></div>

    <div fxLayout="column" class="button-container">
        <button mat-stroked-button  class="color-city-blue" 
            data-testid="cityonsiteattachment_openExternalLink"
            (click)="openExternalLink()">
            <mat-icon>open_in_new</mat-icon>
            <span class="span-text">{{ 'cityonsiteattachment_addfile_btn_externallink' | translate }}</span>  
        </button>    
    </div>

    <div class="divider"></div>
    <div class="divider"></div>
    <div class="divider"></div>
    <button mat-stroked-button class="color-city-blue btn-border-color"
        data-testid="cityonsiteattachment_add_cancel"
        (click)="dismissDialog()">
        {{ 'cityonsiteattachment_addfile_btn_cancel' | translate }}
    </button>   
</div>