import { Injectable } from '@angular/core';
import { CapacitorBarcodeScanner } from '@capacitor/barcode-scanner';


@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerService {
 
  public async scanBarcode(val?: number) {
      try {
        const result = await CapacitorBarcodeScanner.scanBarcode({
          hint : val || 17,
          cameraDirection: 1
        });
        return result.ScanResult; 
      }
      catch (e) {
        return null;
      }
  }
 
}

