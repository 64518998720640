import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/custom-validators';
import { LocalizationService } from '../../internationalization/localization.service';
import { SiteService } from '@app/services/site/site.service';
import { ConfigurationService } from '@app/services/configuration/configuration.service';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent implements OnInit {
  public radioButtonFormGroup!: UntypedFormGroup;
  @Input('question') question : any;
  public hideField = false;
  
  constructor(
    private _formBuilder: UntypedFormBuilder, 
    private _localizationService : LocalizationService,
    private _siteService : SiteService,
    private _configurationService: ConfigurationService
  ) {}

  ngOnInit(): void {
    this._configurationService.getPermitKey().then((permitKey) => {

      if(this.question.question.caption == permitKey[0]){
        this._siteService.getSignedInSite().then((site) => {
          if (!site.isPermitRequired) {
            this.hideField = true;
          }else{
            this.radioButtonFormGroup = this.initFormGroup();
          }
        });

      }else{
        this.radioButtonFormGroup = this.initFormGroup();
      }
    
    });
  }

  private initFormGroup(): UntypedFormGroup {
    const radioForm = this._formBuilder.group({
      radioButtonGroup: new UntypedFormControl(undefined, [CustomValidators.isRequired(this.question.question.isRequired)])
    });
    return radioForm;
  }

  onOptionSelected(option: any): void {
    this.question.answer = option.value;
  }
}