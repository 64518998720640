<div class="dialog-container">
    <label class="deleteVisitReasonsPopupHeader" id="deleteVisitReasonsPopupHeader">
        {{ deletePopupHeader }}
    </label>
    <div class="divider"></div>
    <button type="submit" mat-raised-button id="removeVisitReasons_btn" data-testid="remove_visit_reasons_btn"
        (click)="deleteVisitReasons()">
        {{'visitor_type_management_reason_for_visit_delete_popup_remove_btn' | translate }}
    </button>
    <div class="divider"></div>
    <button mat-stroked-button class="color-city-blue btn-border-color" data-testid="cancel_visitor_type_deletion_tbn"
        (click)="dismissDialog()">
        {{ 'visitor_type_management_reason_for_visit_delete_popup_cancel_btn' | translate }}
    </button>
</div>