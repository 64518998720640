import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalizationService } from '../internationalization/localization.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserService } from '@app/services/user/user.service';
import { User } from '@app/models/user.model';
import { AuthService } from '@app/services/auth/auth.service';
import { UtilsService } from '@app/services/util/util-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile-delete',
  templateUrl: './user-profile-delete.component.html',
  styleUrls: ['./user-profile-delete.component.scss']
})
export class UserProfileDeleteComponent implements OnInit {
  public deleteUserFormGroup! : UntypedFormGroup;
  public currentUser : User | undefined;
  
  constructor(private _dialogRef: MatDialogRef<UserProfileDeleteComponent>,
    private _localizationService : LocalizationService,
    private _formBuilder: UntypedFormBuilder,
    private _authService : AuthService,
    private _utilsService : UtilsService,
    private _router : Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.currentUser =  this.data.user;
    this.deleteUserFormGroup = this.initFormGroup();
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }

  private initFormGroup(): UntypedFormGroup {
    const deleteUserForm = this._formBuilder.group({
      emailAddress: new UntypedFormControl()
    });
    return deleteUserForm;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'emailAddress':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('user_profile_delete_input_email_required');
        } 
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }
  
  disableSubmitButton(){
    const emailAddress = this.deleteUserFormGroup.get('emailAddress')?.value;
    if (emailAddress){
      return !(this.currentUser?.emailAddress.toLowerCase() === emailAddress.toLowerCase());
    }
    else{
      return true;
    }
  }

  async deactivateUser(){
    if (this.currentUser){
      if (this._utilsService.isMobileAndTablet()){
        this._authService.deactivateUser(this.currentUser).then( () => {
          this._router.navigate(['logout/true']);
        })
        .catch(()=>{});
      }
      else {
        await this._authService.deactivateUser(this.currentUser);
      }
    }
  }
}
