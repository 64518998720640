import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Version } from '@app/shared/enums';
import { environment } from '@env/environment';
import { UtilsService } from '../util/util-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BrowserService } from '../browser/browser.service';
import { VersionService } from './version.service';
import { LocalizationService } from '@app/components/internationalization/localization.service';
import { ConfigurationService } from '../configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class VersionGuard  {
  readonly KEY_LATESTAPPVERSION = Version[Version.LATESTAPPVERSION];
  readonly KEY_ISFORCE = Version[Version.ISFORCE];
  readonly KEY_PREVIOUSVERSIONCHECKTIME = Version[Version.PREVIOUSVERSIONCHECKTIME];
  previousVersionCheckTime = Date.now();
  currentTime = Date.now();
  latestAppVersion: string = '';
  over24Hours = false;

  constructor(
    private _utilsService: UtilsService,
    private _deviceService: DeviceDetectorService,
    private _browserService: BrowserService,
    private _versionService: VersionService,
    private _localizationService: LocalizationService,
    private _configurationService: ConfigurationService
  ){
  }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    // Only perform AppVersion checking for native mobile app
    if(!this._utilsService.isMobileAndTablet())
    {
      return true;
    }

    await this.updatePreviousVersionCheckTime();
    this.over24Hours = this.compareLastUpdateTime();
    if(this.over24Hours)
    {
      await this.getLatestAppVersion();
    }
    
    return true;
  }

  deviceOS(){
    return this._deviceService.getDeviceInfo().os.toLowerCase();
  }

  async installApple() {
    this._browserService.openUrl(await this._configurationService.getApplePlayURL());
  }
  async installGoogle() {
    this._browserService.openUrl(await this._configurationService.getGooglePlayUrl());
  }

  async updatePreviousVersionCheckTime()
  {
    await this._versionService.getPreviousVersionCheckTime()
          .then(result => {
            this.previousVersionCheckTime = result;
          });

    this.currentTime = Date.now();
    if(this.previousVersionCheckTime == null)
    {
      this.previousVersionCheckTime = 0;
    }

    // Update previous check time in local storage to current time
    await this._versionService.setPreviousVersionCheckTime(this.currentTime);
  }

  /**
   * @description
   * Sets the over24Hours flag to true if the difference between the current time and previous version check time is over 24 hours.
   */
  compareLastUpdateTime(): boolean
  {
    // Retrieve latest AppVersion from API if >= 24 hours difference from last version check
    if(((this.currentTime / (60 * 60 * 1000)) - (this.previousVersionCheckTime) / (60 * 60 * 1000)) >= environment.appVersionCheckInterval)
    {
      return true;
    }

    return false;
  }

  /**
   * @description
   * Retrieves the latest app version from the VersionService and saves the info to local storage.
   */
  async getLatestAppVersion()
  {
    (this._versionService.GetLatestAppVersion()).subscribe({
      next: async result => {
        // Save retrieved Version info to local storage
        await this._versionService.setVersion(result.version);
        await this._versionService.setIsForce(JSON.stringify(result.isForce));
        
        this.latestAppVersion = result.version;

        await this.promptAppUpdate();
      }
    });
  }

  /**
   * @description
   * Compares the current app version to the latest app version from the database and prompts for an update if necessary.
   */
  async promptAppUpdate()
  {
    let isForce : boolean = true;
    let deviceOS = this.deviceOS();

    await this._versionService.getIsForce()
          .then(result => isForce = result);

    var appVersionSplitted = environment.appVersion.split(".", 3);
    var latestVersionSplitted = this.latestAppVersion.split(".", 3);
    if(parseInt(appVersionSplitted[0]) > parseInt(latestVersionSplitted[0]) || 
       (parseInt(appVersionSplitted[0]) == parseInt(latestVersionSplitted[0]) && parseInt(appVersionSplitted[1]) > parseInt(latestVersionSplitted[1])) ||
       (parseInt(appVersionSplitted[0]) == parseInt(latestVersionSplitted[0]) && parseInt(appVersionSplitted[1]) == parseInt(latestVersionSplitted[1]) && parseInt(appVersionSplitted[2]) >= parseInt(latestVersionSplitted[2])))
    {
      return;
    }
    else if(isForce)
    {
      switch(deviceOS)
      {
        case 'android':{
          this.installGoogle();
          break;
        }
        case 'ios':{
          this.installApple();
          break;
        }
      }
    }
    else
    {
      switch(deviceOS)
      {
        case 'android':{
          confirm(this._localizationService.translate("versionguard_update_prompt_android"));
          break;
        }
        case 'ios':{
          confirm(this._localizationService.translate("versionguard_update_prompt_ios"));
          break;
        }
      }
    }
  }
}