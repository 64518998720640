import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../internationalization/localization.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private _authServices: AuthService,
    private _activatedRoute: ActivatedRoute,
    private _localizationService: LocalizationService
  ){}

  ngOnInit(): void {
    const deletedUser: boolean = this._activatedRoute.snapshot.paramMap.get('deleted')?.toLowerCase() == 'true';
     if (deletedUser) {
      alert(this._localizationService.translate('logout_profile_deleted_successfully'));
    };
    this._authServices.logout()
      .then(async _ => {});
 
  }
  
}