import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiplechoice',
  templateUrl: './multiplechoice.component.html',
  styleUrls: ['./multiplechoice.component.scss']
})
export class MultiplechoiceComponent implements OnInit {

  @Input('question') question : any;
  indexSelected : boolean[] = [];

  constructor(){}

  ngOnInit(): void {
    var answer : string[] = [];
    if (this.question){
      if (this.question.answer === null || this.question.answer === undefined) {
        answer = [];
      } else {
        answer = JSON.parse(this.question.answer);
      }
      if (answer !== undefined) {
        this.question.question.additionalPropertiesParsed.Options.forEach((option: any) => {
          if (answer.indexOf(option) > -1) {
            this.indexSelected.push(true);
          } else {
            this.indexSelected.push(false);
          }         
        });
      }
    }
  }
  
  setAnswer(event: any) {
    var answer = [];
    if (this.question.answer === null || this.question.answer === undefined) {
      this.question.answer = {};
    } else {
      answer = JSON.parse(this.question.answer);
    }

    var valueFound = answer.indexOf(event.source.value);
    if (event.checked) {
      if (valueFound == -1) {
          answer.push(event.source.value);
      }
    } else {
      if (valueFound > -1) {
        answer.splice(valueFound, 1);
      }
    }
    this.question.answer = JSON.stringify(answer);
  }

  //exists(): boolean {
    //return true;
    // let returnValue = false;
    // console.log(event);
    // if (this.question.answer !== undefined || this.question.answer !== "") 
    // {
    //   var answer = JSON.parse(this.question.answer);
    //   if (answer === null || answer === undefined || Array.isArray(answer)) answer = [];
    //   returnValue =  answer.indexOf(event.source.value) > -1;
    // }
    // return returnValue;
  //}

}