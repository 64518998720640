<div>
    <div fxLayout="row">
        <div fxLayout="column">
            <span class="font-size-h1 color-city-blue">{{'register_step2_about' | translate }}</span>
            <span class="font-size-body-text color-dark-blue">{{'register_step2_details' | translate }}</span>
        </div>
    </div>
    <div class="divider"></div>
    
    <div class="form-list" *ngIf="rapidGlobalModuleEnable">
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <h5> {{ ('register_company_header' | translate)}} <mat-icon>help_outline</mat-icon>
          </h5>
          <button id="btn_addrapidglobal" mat-fab (click)="addRapidGlobal()" data-testid="register2_addRapidGlobal">
            <mat-icon>note_add</mat-icon>
          </button>
        </div>
        <div class="divider"></div>
        <mat-divider class="table-divider-header"></mat-divider>
    
        <div>
          <table mat-table [dataSource]="rapidGlobalInfos" multiTemplateDataRows>
    
            <ng-container matColumnDef="companyName" >
              <th mat-header-cell *matHeaderCellDef  fxFlex="82" fxLayoutAlign="start center"> {{ ('register_step2_company_table_header' | translate)}} </th>
              <td mat-cell class="color-dark-blue" *matCellDef="let company" fxFlex="82" fxLayoutAlign="start center">  {{company.companyName}} </td>
            </ng-container>
   
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef fxFlex="18" fxLayoutAlign="center center">  </th>
              <td mat-cell *matCellDef="let company" fxFlex="18" fxLayoutAlign="center center"> 
                <mat-icon color="primary" *ngIf="!company.companyId" (click)="editRapidGlobal(company)">edit</mat-icon>
                <mat-icon color="primary"  *ngIf="!company.companyId" (click)="deleteRapidGlobal(company)">delete</mat-icon>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedRapidGlobalColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedRapidGlobalColumns;"
                class="element-row">
            </tr>

          </table>
        </div>
    
      </div>

      <div class="divider"></div> 

      <div class="form-list" *ngIf="profileInductionModuleEnable">
        <div fxLayout="row" fxLayoutAlign="space-between end">
          <h5> {{ ('register_siteinduction_header' | translate)}} <mat-icon>help_outline</mat-icon>
          </h5>
          <button id="btn_addInduction" mat-fab color="primary" (click)="addSiteInduction()" data-testid="register2_addSiteInduction">
            <mat-icon>note_add</mat-icon>
          </button>
        </div>
        <div class="divider"></div>
        <mat-divider class="table-divider-header"></mat-divider>
    

        <div>
          <table mat-table [dataSource]="siteInductionInfos" multiTemplateDataRows>
    
            <ng-container matColumnDef="brandName" >
              <th mat-header-cell *matHeaderCellDef  fxFlex="82" fxLayoutAlign="start center"> {{ ('register_step2_partner_table_header' | translate)}} </th>
              <td mat-cell class="color-dark-blue" *matCellDef="let siteinduction" fxFlex="82" fxLayoutAlign="start center">  {{siteinduction.brandName}} </td>
            </ng-container>
   
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef fxFlex="18" fxLayoutAlign="center center"> {{ ('register_step2_status_table_header' | translate)}} </th>
              <td mat-cell *matCellDef="let siteinduction" fxFlex="18" fxLayoutAlign="center center"> 
                <mat-icon class="color-city-red" *ngIf="getStatus(siteinduction) === 'inactive'">do_not_disturb_alt</mat-icon>
                <mat-icon class="color-city-green" *ngIf="getStatus(siteinduction) === 'active'">task_alt</mat-icon>
                <mat-icon class="color-city-yellow" *ngIf="getStatus(siteinduction) === 'error'">error_outline</mat-icon>
              </td>
            </ng-container>
    
            <ng-container matColumnDef="expandedInductionDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedSiteInductionColumns.length" >
                <div class="element-detail"
                     [@detailInductionExpand]="element == expandedInductionElement ? 'expanded' : 'collapsed'">
                  <div fxLayout="column" fxFlex="100" >
                      <div fxLayout="row">
                          <div fxFlex = "82" class="expandedheaderlabel color-city-neutral" fxLayoutAlign="start center">
                            {{('register_step2_siteinductionid' | translate)}} {{element.inductionId}}
                          </div>
                          <div fxFlex = "18" fxLayoutAlign="center center">
                            <mat-icon color="primary" (click)="editSiteInduction(element)">edit</mat-icon>
                            <mat-icon color="primary" (click)="deleteSiteInduction(element)">delete</mat-icon>
                          </div>
                      </div>

                      <div class="divider"></div>
                  </div>  

                </div>
              </td>
            </ng-container> 
          
            <tr mat-header-row *matHeaderRowDef="displayedSiteInductionColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedSiteInductionColumns;"
                class="element-row"
                [class.expanded-row]="expandedInductionElement === element"
                (click)="expandedInductionElement = expandedInductionElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedInductionDetail']" class="detail-row"></tr> 

          </table>
        </div>
  
      </div> 
    
</div>
<div class="divider"></div> 
<div class="close-button">
   <button type="submit" id="register2_continue" mat-raised-button (click)="continueRegister()">
      {{'register_continue' | translate }}
   </button>
</div>
  
  