import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-permit-label',
  templateUrl: './permit-label.component.html',
  styleUrls: ['./permit-label.component.scss']
})
export class PermitLabelComponent implements OnInit {

  @Input('question') question: any;

  constructor() { }

  ngOnInit(): void {
  }

  captionStyles(): object {
    return JSON.parse(this.question.CaptionStyles);
  }
}
