<div class="dialog-container">
  <label class="attachmentExtLinkLabel" id="attachmentExtLinkLabel">{{ ('cityonsiteattachment_addextlink_lbl_header' | translate).replace("{document}", data.question.caption) }}</label>
  <div class="divider"></div>
  <form [formGroup]="extLinkFormGroup">
    <div>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label class="color-city-blue">{{'cityonsiteattachment_addextlink_entername_field' | translate }}</mat-label>
        <input matInput 
          placeholder="{{'cityonsiteattachment_addextlink_entername_field' | translate }}" 
          formControlName="name" 
          required 
          data-testid="input_name" 
          id="input_name">
        <mat-error *ngIf="hasError(extLinkFormGroup, 'name')">{{getError(extLinkFormGroup, 'name')}}</mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" fxFlex="100">
        <mat-label class="color-city-blue">{{'cityonsiteattachment_addextlink_enterextlink_field' | translate }}</mat-label>
        <input matInput 
          placeholder="{{'cityonsiteattachment_addextlink_enterextlink_field' | translate }}" 
          formControlName="externalLink" 
          required 
          data-testid="input_externalLink" 
          id="input_externalLink">
        <mat-error *ngIf="hasError(extLinkFormGroup, 'externalLink')">{{getError(extLinkFormGroup, 'externalLink')}}</mat-error>
      </mat-form-field>
    </div>
  </form>
  <div class="divider"></div>
  <div class="divider"></div>
  <button mat-stroked-button class="color-city-blue btn-border-color"
    data-testid="addextlink_btn_add"
    (click)="addlink()" 
    [disabled]="disableAddLinkButton()">
    {{ 'cityonsiteattachment_addextlink_btn_addlink' | translate }}
  </button>   
  <div class="divider"></div>
  <button mat-stroked-button class="color-city-blue btn-border-color"
    data-testid="addextlink_btn_cancel"
    (click)="dismissDialog()">
    {{ 'cityonsiteattachment_addextlink_btn_cancel' | translate }}
  </button>
</div>