import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserService } from '@app/services/browser/browser.service';
import { UtilsService } from '@app/services/util/util-service';
import { environment } from '@env/environment';
import { ConfigurationService } from '@app/services/configuration/configuration.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public siteId: string | undefined;
  creatingATechnicianUserProfileGuide = ``;
  userManagementGuide = ``;
  subcontractorVisitsToColesDCsGuide = ``;

  constructor(private _utilsService: UtilsService,
    private _browserService: BrowserService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _configurationService: ConfigurationService
  ) { this.siteId = this._activatedRoute.snapshot.paramMap.get('siteId')?.toLocaleLowerCase(); }
  
  ngOnInit(): void {
    this._configurationService.getTechnicianUserProfileGuide().then(t => {
      this.creatingATechnicianUserProfileGuide = t;
    });
    this._configurationService.getUserManagementGuide().then(t => {
      this.userManagementGuide = t;
    });
    this._configurationService.getSubcontractorVisitsToColesDCsGuide().then(t => {
      this.subcontractorVisitsToColesDCsGuide = t;
    });

    var isWeb = this.isWebBrowser();
    if(!isWeb){
     this.proceedToLoginUserInBrowser();
    }
  }


  proceedToLoginUserInBrowser() {
    this._router.navigate([`login/user/${this.siteId}`]);
  }

  isWebBrowser(): boolean {
    return this._utilsService.isWebBrowser();
  }

  async installApple() {
    this._browserService.openUrl(await this._configurationService.getApplePlayURL());
  }

  async installGoogle() {
    this._browserService.openUrl(await this._configurationService.getGooglePlayUrl());
  }

}
