import { Call } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Module, User } from '../../models/user.model';
import { LocalizationService } from 'src/app/components/internationalization/localization.service';
import { AppPlatform, CompanyType, FastLinkType, StorageKey, UserType } from 'src/app/shared/enums';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../util/util-service';
import { Browser } from '@capacitor/browser';
import { Job } from '../../models/job.model';
import { UserService } from 'src/app/services/user/user.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';
import { ProcessTokenRequest } from 'src/app/models/processtokenrequest.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base/base.service';
import { ProcessFlow } from '@app/models/processflow.model';
import { Preferences } from '@capacitor/preferences';
import { BrowserService } from '../browser/browser.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { AppLauncher } from '@capacitor/app-launcher';
import { LoaderService } from '../loader/loader.service';

@Injectable({
    providedIn: 'root'
})

//If we have more module, this will have to change to moduleRedirectService rather than closedown service
export class ClosedownService extends BaseService {

    public workBench: string = 'WORKBENCH';
    public myJobs: string = 'MYJOBS'

    readonly BASE_URL = `${environment.gatewayAPI_V1}/`;
    readonly PROCESSFLOWKEY = StorageKey[StorageKey.PROCESSFLOW];
    readonly LINKTYPEKEY = StorageKey[StorageKey.LINKTYPE];
    readonly FIRSTREDIRECTKEY = StorageKey[StorageKey.FIRSTREDIRECT];
    //readonly BASE_URL = `https://localhost:7062/v1/gateway/`;
    private siteId: number = 0;
    private resourceIds: string[] = [];
    private currentUser: User | undefined;
    private withFastLink = false;
    private bearerToken: string | undefined;
    private jobs: Job[] = [];
    private isExternalUser = false;
    private isResourceAdmin: boolean | undefined = undefined;
    private byPass = false;
    constructor(private _authService: AuthService,
        private _localizationService: LocalizationService,
        private _utilsService: UtilsService,
        private httpClient: HttpClient,
        private _browserService: BrowserService,
        private _loader: LoaderService,
        private _configurationService: ConfigurationService) {
        super();
    }

    public getSiteId(): number {
        return this.siteId;
    }

    public setSiteId(siteId: number) {
        this.siteId = siteId;
    }

    public setWithFastLink(withFastLink: boolean) {
        this.withFastLink = withFastLink;
    }

    public getWithFastLink() {
        return this.withFastLink;
    }

    public setCurrentUser(user: User): Promise<void> {

        this.currentUser = user;
        this.loadUserModules();
        return new Promise(r => r());
    }
    public setUserJobs(jobs: Job[]) {
        this.jobs = jobs;
    }

    public getIsExternalUser() {
        return this.isExternalUser;
    }

    public getIsResourceAdminUser() {
        return this.isResourceAdmin;
    }

    public getResourceIDs() {
        return this.resourceIds;
    }
    public setBearerToken(bearerToken: string) {
        this.bearerToken = bearerToken;
    }
    public getBearerToken() {
        return this.bearerToken;
    }

    public getWorkBench() {
        return this.workBench;
    }

    public setByPass(byPass: boolean) {
        this.byPass = byPass;
    }

    public getByPass() {
        return this.byPass;
    }

    public isValidExpiryLink(expiry?:string): boolean {
        if (expiry) {
            let activationDate = new Date();
            let expiryDatetime = new Date(expiry);
            if (activationDate > expiryDatetime) {
                return false;
            }
            return true;
        }
        return false;
    }

    async directToClosedown(remoteFix: boolean, resourceId?: string, overrideBackUrl:string | undefined = undefined) {
      let module = (await this._authService.getUserModule())?.filter(m => m.productName.toUpperCase() === this.workBench)[0];
      this._loader.show();
      if(module){
        let redirectUrl = module.productUrl; //+ `?processToken=${this.bearerToken}&remoteFix=${remoteFix}`;
        //let redirectUrl = `https://localhost:44328/Account/GetProcessAzureB2CTokenAsync?processToken=${this.bearerToken}&remoteFix=${remoteFix}`;
        //let redirectUrl = `https://localhost:44328/Account/GetProcessAzureB2CTokenAsync`;
        var backUrl = "";
        let bearerToken: string = this.bearerToken ?? "";
        this.resourceIds = [];
        var processFlow = await this.getProcessFlow();
        var returnProcess = processFlow.url;
        var siteCode = processFlow.siteCode;
        var token = processFlow.token;
        var linkType = processFlow.linkType;
  
        // Retrieve setting from local settings/API
        let shouldRetrieveFromAPI = await this._configurationService.shouldRetrieveFromAPI();
        let inductionModuleEnable = false;
        if(shouldRetrieveFromAPI){
          await this._configurationService.getAndSetLocalConfigurations(true);
        }
        inductionModuleEnable = await this._configurationService.getInductionModuleEnable();
      
        if (this.currentUser) {
            if (!this._utilsService.isMobileAndTablet()) {
              if(overrideBackUrl){
                  backUrl = overrideBackUrl;
              }else{
                  if (!inductionModuleEnable || remoteFix) {
                      backUrl = this.getSignInBackUrl();
                  }
                  else {
                      // to be route to induction completed page
                      backUrl = window.location.protocol + "//" + environment.appDomain + "/redirect/siteinductioncompleted";
                  }
              }
            }
            var processTokenRequest: ProcessTokenRequest = {
                backUrl: backUrl,
                withFastLink: this.withFastLink,
                inductionModuleEnable: inductionModuleEnable,
                linkType: Number(linkType),
                loginRedirect: "",
                processToken: bearerToken,
                remoteFix: remoteFix,
                returnProcess: returnProcess,
                siteCode: siteCode,
                token: token,
                resourceId: resourceId,
                isMobileApp: this._utilsService.isMobileAndTablet()
            }
            this.getWorkBenchProcessToken(processTokenRequest).subscribe({
                next: (cityOnsiteAuth) => {
                    this._loader.show();
                    var token = cityOnsiteAuth;
                    redirectUrl = redirectUrl + '?token=' + token;
                    this.resetProcessFlow(remoteFix).then(r => {
                      this.withFastLink = false;
                      if (this._utilsService.isWebBrowser()) {
                          this._browserService.openExternalhref(redirectUrl);
                      } else {
                          this._loader.hide();
                          this._browserService.open({ url: redirectUrl });
                      }
                    });
                }, error: (err) => {
                    alert(this._localizationService.translate("error_workbench_token"));
                    this._loader.hide();
                }
            });
        }
        else {
            alert(this._localizationService.translate("signin_user_notexist"));
        }
      }
  }

    async loadUserModules() {
        // Retrieve setting from local settings/API
        let shouldRetrieveFromAPI = await this._configurationService.shouldRetrieveFromAPI();
        let inductionModuleEnable = false;
        if(shouldRetrieveFromAPI){
            await this._configurationService.getAndSetLocalConfigurations(true);
        }
        inductionModuleEnable = await this._configurationService.getInductionModuleEnable();

        if (this.currentUser?.id) {
            let m = await this._authService.getUserModule();
            if (m) {
                if (this.currentUser) {
                    if (this.currentUser.companies.length > 0) {
                        this.isExternalUser = true;
                        this.currentUser.companies.forEach(company => {
                            if (company.companyType.toUpperCase() != CompanyType.SUBCON) {
                                this.isExternalUser = false;
                                if (company.companyType.toUpperCase() == CompanyType.SELF){
                                    this.isResourceAdmin = true;
                                }
                            }
                        });
                    }
                    else {
                        this.isExternalUser = true;
                    }
                }
                if (this.isResourceAdmin === undefined)
                    this.isResourceAdmin = false;
                if (!inductionModuleEnable) {
                    if (this.withFastLink || (this.byPass && !this.isExternalUser)) {
                        this.directToClosedown(false);
                    }
                }
                else {
                    if (this.byPass && this.isResourceAdmin && !this._utilsService.isMobileAndTablet()){
                        this.directToClosedown(false);
                    }
                }
            }
        }

        return new Promise(p => p(true));
    }

    getWorkBenchProcessToken(processTokenRequest: ProcessTokenRequest): Observable<any> {
        //ar headers = new HttpHeaders().set('Content-Type', 'text/plain; charser=utf-8');
        const url = `${this.BASE_URL}getworkbenchprocesstoken`;
        return this.httpClient.post<any>(url, processTokenRequest, { responseType: 'text' as 'json' }).pipe(
            catchError(this.handleError));
      
    }

    async setProcessFlow(processFlow: ProcessFlow){
        await Preferences.set({
          key: StorageKey[this.PROCESSFLOWKEY],
          value: JSON.stringify(processFlow)
        });
    }

    public async getProcessFlow() : Promise<ProcessFlow>{
        const item = await Preferences.get({ key: this.PROCESSFLOWKEY});
        var obj : ProcessFlow = {
            url: '',
            resource: '',
            siteCode :'',
            siteId: '',
            jobId: '',
            calloutId: '',
            jobEventId: '',
            subContResourceId: '',
            expiry: '',
            linkType: 0,
            token: ''
        };
        if(item.value){
          obj = JSON.parse(item.value);
        }      
        return obj;
    }

    async removeProcessFlow() {
        await Preferences.remove({ key: StorageKey[this.PROCESSFLOWKEY]});
    }

    public IsClosedownRedirectPermitted(): boolean
    {
        if(this.withFastLink || this.jobs?.length > 0)
        {
            return true;
        }
        return false;
    }

    async setInductionFirstRedirect(redirect: string){
        await Preferences.set({
          key: StorageKey[this.FIRSTREDIRECTKEY],
          value: redirect
        });
    }

    public async getInductionFirstRedirect() : Promise<string>{
        const item = await Preferences.get({ key: this.FIRSTREDIRECTKEY});
        if(item.value){
          return item.value;
        }      
        return '';
    }

    async removeInductionFirstRedirect() {
        await Preferences.remove({ key: StorageKey[this.FIRSTREDIRECTKEY]});
    }

    async resetProcessFlow(resetSiteCode: boolean = false){
        var processFlow = await this.getProcessFlow();
        processFlow.linkType = 0;
        processFlow.jobId = "";
        processFlow.resource = "";
        processFlow.url ="";
        processFlow.calloutId = "";
        processFlow.expiry = "";
        processFlow.jobEventId = "";
        processFlow.siteId = "";
        processFlow.subContResourceId = "";
        processFlow.token = "";

        if(resetSiteCode){
            processFlow.siteCode = "";
        }

        await this.setProcessFlow(processFlow);
    }

    getSignInBackUrl():string{
        return window.location.protocol + "//" + environment.appDomain + "/redirect/signin";
    }

    appPlatformRedirect(module: Module, overrideProductUrl:string=""){
        var productUrl = module.productUrl;

        if(overrideProductUrl.length > 0){
            productUrl = overrideProductUrl;
        }

        if(module.appPlatform == AppPlatform.MOBILEAPP){
            AppLauncher.openUrl({ url: productUrl });
        }else if(module.appPlatform == AppPlatform.WEB){
            if (this._utilsService.isWebBrowser()) {
                this._browserService.openExternalhref(productUrl);
            } else {
                this._browserService.open({ url: productUrl });
            }
        }
    }
}
