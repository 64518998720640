<ng-container>
    <div [ngSwitch]="question.question.questionType.name.trim().toLowerCase()" id="switch_question">
       <div *ngSwitchCase="'information'"><app-information [question]="question"></app-information></div>
       <div *ngSwitchCase="'radiobutton'"><app-radiobutton [question]="question"></app-radiobutton></div>
       <div *ngSwitchCase="'multiplechoice'"><app-multiplechoice [question]="question"></app-multiplechoice></div>
       <div *ngSwitchCase="'signature'"><app-signature [question]="question"></app-signature></div>
       <div *ngSwitchCase="'label'"><app-label [question]="question"></app-label></div>
       <div *ngSwitchCase="'cityonsiteattachments'"><app-cityonsite-attachments [question]="question"></app-cityonsite-attachments></div>
       <div *ngSwitchCase="'cityonsiteprofile'"><app-cityonsiteprofile [question]="question"></app-cityonsiteprofile></div>
       <div *ngSwitchCase="'text'"><app-text [question]="question"></app-text></div>
    </div>
    <div class="divider"></div>
</ng-container>