<div class="main">
    <div id="login" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="row">
            <div fxFlex="3"></div>
            <div class="logo" fxFlex="94">
                <img src="/assets/CityOnSite_Logo.png">
            </div>
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex="100">
            <div fxLayout="column">
                <div fxLayout="column">
                    <div class="font-size-h1">{{ 'welcome_page_welcome_question' | translate }}</div>
                    <p>{{ 'welcome_page_welcome_message' | translate }}</p>
                </div>
                <div fxLayout="column">
                    <div class="font-size-h1">{{ 'welcome_page_subcontractor' | translate }}</div>
                    <p>{{ 'welcome_page_need_to_be_invited' | translate }}<br>
                        {{ 'welcome_page_follow_the_links' | translate }}<br>
                        {{ 'welcome_page_follow_instructions' | translate }}<br>
                        <a [href]="creatingATechnicianUserProfileGuide" target="_blank" class="guide"> {{
                            'welcome_creating_a_technician_user_profile_pdf' | translate }}</a>
                        <br>
                        <a [href]="subcontractorVisitsToColesDCsGuide" target="_blank" class="guide"> {{
                            'welcome_subcontractor_visits_to_coles_dcs_pdf' | translate }}</a>
                    </p>
                </div>
                <div fxLayout="column">
                    <div class="font-size-h1">{{ 'welcome_page_other_visitors' | translate }}</div>
                    <p>{{ 'welcome_page_install_the_app' | translate }} <br>
                        <!-- {{ 'welcome_page_follow_instructions' | translate }}<br>
                        <a [href]="userManagementGuide" target="_blank" class="guide">
                        {{ 'welcome_creating_a_user_profile_pdf' | translate }}</a> -->
                    </p>
                </div>
                <div fxLayout="row" class="better_on_app">
                    <div class="font-size-h1">{{ 'welcome_page_better_with_app' | translate }}</div>
                </div>
                <div *ngIf="isWebBrowser()" fxLayout="row" class="app_store_badge">
                    <div fxLayout="col">
                        <img alt='Download on the App Store' style="height: 50px;padding-top: 9px;"
                            src='/assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
                            (click)="installApple()">
                    </div>

                    <div fxLayout="col">
                        <img alt='Get it on the Play Store' style="height: 60px; padding-top: -12px; "
                            src='/assets/google-play-badge.png' (click)="installGoogle()">
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <button type="submit" mat-raised-button class="proceedToBrowser-btn" id="btn_proceedtobrowser" data-testid="btn_proceed_to_browser"
                        (click)="proceedToLoginUserInBrowser()">
                        {{ 'welcome_page_proceed_on_browser_button' | translate }}
                    </button>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" class="centered-text">
                    <p>{{ 'welcome_page_setting_up_organisation_location' | translate }}</p>
                    <a href="https://www.cityonsite.com" target="_blank">{{ 'welcome_page_cityonsite_visit_url' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>